import classNames from 'classnames';
import { ToastContainer, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CrossIcon } from '../icons';
import { textStyles } from '../style/text-styles';

const contextTypes: Record<TypeOptions, string> = {
  success: classNames('bg-system-back-success text-system-front-success-text'),
  error: classNames('bg-system-back-error text-system-front-error-text'),
  info: classNames('bg-system-back-info text-system-front-info-text'),
  warning: classNames('bg-system-back-warning text-system-front-warning-text'),
  default: classNames('bg-system-back-default text-system-front-default-text'),
};

export const TyToastContainer = () => {
  return (
    <ToastContainer
      toastClassName={(context) =>
        classNames(
          'flex items-center justify-between px-3 py-4',
          'rounded-lg',
          textStyles.pBody2Rg,
          contextTypes[context?.type ?? 'default']
        )
      }
      position="top-right"
      autoClose={3000}
      closeButton={(props) => (
        <CrossIcon className="w-3.5 h-3.5 stroke-base-front-1" onClick={() => props.closeToast()} />
      )}
      hideProgressBar
    />
  );
};
