import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { useFormState } from 'informed';

import { useUser } from '../../../../../apiClient/ApiContext';
import SignatureForm from '../../../../../components/blob/SignatureForm';
import StampForm from '../../../../../components/blob/StampForm';
import TyAutoTrimInput from '../../../../../components/inputs/TyAutoTrimInput';
import TyInput from '../../../../../components/inputs/TyInput';
import TyMarkdownEditor from '../../../../../components/inputs/TyMarkdownEditor';
import { TySelect } from '../../../../../components/inputs/TySelect';
import validateZipCode from '../../../../../components/inputs/Validators/zipCode';
import { CompanyData } from '../../../../../types/TymbeApi';
import { CountryOptionType } from '../../../../../types/utils';
import { Roles } from '../../../../../utils/enums';
import { blockInvalidCharactersForZip, formatOnPasteForZip } from '../../../../../utils/zipCode';
import CompanyContactPersonForm, { CompanyContactPersonFormValues } from '../../../components/CompanyContactPersonForm';
import { countryOptions } from '../../../components/CompanyForm';

type BranchofficeFormProps = {
  branchoffice?: BranchofficeData;
  company: CompanyData;
};

export interface BranchofficeFormValues extends BranchofficeData, CompanyContactPersonFormValues {
  countryOption: CountryOptionType;
  stampBlob: Blob | null,
  signatureBlob: Blob | null,
}

const BranchofficeForm = ({ company, branchoffice = undefined }: BranchofficeFormProps) => {
  const formState = useFormState<BranchofficeFormValues>();

  const isSuperadmin = useUser().hasRoles([
    Roles.SUPER_ADMIN,
  ]);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <h3>Základní údaje</h3>
        <TyInput
          name="name"
          label="Název"
          required
          disabled={!isSuperadmin && company?.is_readonly}
        />
        <TyInput
          name="display_name"
          label="Alias / Název pro brigádníky"
        />
        <TyInput
          name="address.addressline1"
          label="Ulice a číslo"
          required
          disabled={!isSuperadmin && company?.is_readonly}
        />
        <div className="flex flex-row gap-2 flex-wrap">
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="address.locality"
            label="Město"
            required
            disabled={!isSuperadmin && company?.is_readonly}
          />
          <TyAutoTrimInput
            className="w-full md:flex-1"
            name="address.zip"
            type="text"
            label="PSČ"
            validate={(value) =>
              validateZipCode(
                value as string,
                formState.values.countryOption?.value,
              )}
            onPaste={formatOnPasteForZip}
            onKeyDown={blockInvalidCharactersForZip}
          />
        </div>
        <TySelect
          name="countryOption"
          label="Země"
          initialValue={countryOptions[0]}
          options={countryOptions}
          isDisabled
        />
        <TyMarkdownEditor
          name="instruction"
          label="Pokyny"
          initialValue={branchoffice?.instruction}
        />
      </div>
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <CompanyContactPersonForm company={company} initialContactPerson={branchoffice?.contactPerson} />
        <SignatureForm signature={branchoffice?.signature} />
        <StampForm stamp={branchoffice?.stamp} />
      </div>
    </div>
  );
};

export default BranchofficeForm;
