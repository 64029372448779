import { useMutation, useQueryClient } from 'react-query';

import apiClient from '../../../../../apiClient';
import { defaultOnError } from '../../../../../apiClient/utils';
import Button from '../../../../../components/buttons/Button';
import { TrashIcon } from '../../../../../components/icons';
import Spinner from '../../../../../components/Spinner';

type DeleteUtilityProps = {
  id: number;
};

const DeletePersonUtility = ({ id }: DeleteUtilityProps) => {
  const queryClient = useQueryClient();
  const deleteUtility = useMutation({
    mutationFn: async (utilityId: number) => apiClient.service('person-utility').remove(utilityId),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['person-utility', data.person_id]);
    },
    onError: defaultOnError,
  });
  return (
    <>
      <Button className="border-error" onClick={() => deleteUtility.mutate(id)} disabled={deleteUtility.isLoading}>
        {!deleteUtility.isLoading
          ? <TrashIcon className="w-4 h-4" />
          : <Spinner show className="w-4 h-4 text-error" />}
      </Button>
    </>
  );
};

export default DeletePersonUtility;
