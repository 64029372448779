import { Paginated, Query } from '@feathersjs/feathers';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import ExpandableShiftRow from './components/ExpandableShiftRow';
import { columnHeaders } from './utils/shift.utils';
import feathersClient from '../../apiClient';
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableSortHeader,
  useTablePagination,
} from '../../components/Table';
import defaultPageSizeOptions from '../../components/Table/table.utils';
import { PageTitle } from '../../components/texts';
// Advance filter -->
import TyAdvancedSearchAdminShift from '../../components/TyAdvancedSearch/AdminShift';
import apiTransformationsAdminShift from '../../components/TyAdvancedSearch/AdminShift/apiTransformationsAdminShift';
import TyInteractiveLabels from '../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../components/TyAdvancedSearch/Libs/enums';
// Advance filter <--
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { ApplicationData } from '../../types/TymbeApi';

const pageTitle = 'Směny';

const AdminShiftsPage = () => {
  const [{ tyAdvanceSearch }, setUrlParamsHandler] = useURLParamsHandler();

  const [sortField, setSortField] = useState(columnHeaders[1].value);
  const [advanceSearch, setAdvanceSearch] = useState(tyAdvanceSearch);

  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(-1);
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const [query, setQuery] = useState<Query>({});

  const { data } = useQuery(
    ['admin-shift', query],
    async ({ queryKey }): Promise<Paginated<ApplicationData>> => (feathersClient
      .service('application')
      .find({ query: queryKey[1] as Query })) as Promise<Paginated<ApplicationData>>,
  );

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  useEffect(() => {
    let q = {
      // NOTE: sorting is not gonna work without the line below
      $select: [
        'shift:company.name',
        'shift:branchoffice',
        'shift.name',
        'shift.start_time',
        'shift.external_id',
        'person.last_name',
        'application.payment_base',
        'application.credits',
        'application.invitation',
        'application.id',
      ],
      $eager: '[person, shift.[company, branchoffice.parent]]',
      $joinRelation: '[person, shift.[company, branchoffice]]',
      $skip: paginationStart,
      $limit: paginationPageSize,
      $sort: { [sortField]: sortAscendingOrder },
    };
    if (advanceSearch) {
      setUrlParamsHandler(advanceSearch);
      q = {
        ...q,
        ...apiBuilder(
          advanceSearch,
          apiTransformationsAdminShift,
        ),
      };
    }
    setQuery(q);
  }, [advanceSearch, paginationPageSize, paginationStart, sortAscendingOrder, sortField]);

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <>
          <div className="items-center flex justify-between">
            <PageTitle>{pageTitle}</PageTitle>
          </div>
          <div className="bg-bg">
            <div className="px-2.5 text-center space-y-5">
              <div className="flex justify-end items-center mt-6 p-2">
                <TyAdvancedSearchAdminShift
                  pageTitle="Směny"
                  setInitialValues={setAdvanceSearch}
                  initialValues={advanceSearch}
                />
              </div>
              <TyInteractiveLabels
                labelsValues={labelsValues}
                setInitialValues={setAdvanceSearch}
                initialValues={advanceSearch}
              />
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  {columnHeaders.map((col) => (
                    <TableSortHeader
                      key={columnHeaders.indexOf(col).toString()}
                      field={col.sortable ? col.value : undefined}
                      activeField={sortField}
                      onSort={setSort}
                    >
                      {col.label}
                    </TableSortHeader>
                  ))}
                </TableRow>
              </TableHead>
              <tbody>
                {data?.data.map(
                  (application) => (
                    <ExpandableShiftRow
                      key={data.data.indexOf(application).toString()}
                      application={application}
                    />
                  ),
                )}
              </tbody>
            </Table>
          </div>
          <TablePagination
            {...setPageParams()}
            rowsCount={data?.total}
          />
        </>
      </Wrapper>
    </Container>
  );
};

export default AdminShiftsPage;
