import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { ContactType } from '../../utils/enums';

const CompanyNameReport = () => {
  const history = useLocation();
  const { nameReportData, nameReportShift } = history.state;
  return (
    <div style={{ padding: '0 50px' }}>
      <h1 style={{ textAlign: 'center' }}> {nameReportShift.name}</h1>
      <h2 style={{ color: 'black', textAlign: 'center', fontSize: 14 }}>
        {' '}
        {moment(nameReportShift?.start_time).format('DD.MM.YYYY HH:mm')}
        {' '}
        -
        {' '}
        {moment(nameReportShift?.end_time).format('HH:mm')}
      </h2>
      <h5 className="text-right mb-1">Datum tisku: {moment().format('DD.MM.YYYY HH:mm')}</h5>
      <hr />
      <br />
      <table className="ty-print-table" border="1px solid black">
        <thead>
          <tr>
            <th>ID Tymber</th>
            <th>Příjmení</th>
            <th>Jméno</th>
            <th>Datum narození</th>
            <th>Mobil</th>
          </tr>
        </thead>
        <tbody>
          {nameReportData && nameReportData?.map((row) => (
            <tr style={{ borderBottom: '1px solid grey', height: '25px' }} key={row?.id}>
              <td>{row?.person?.id}</td>
              <td>{row?.person?.last_name}</td>
              <td>{row?.person?.first_name}</td>
              <td>{moment(row?.person?.personData?.birthdate).format('DD.MM.YYYY')}</td>
              <td>
                {row?.person?.contact
                  ?.find((x) => x.type === ContactType.MOBILE_PHONE)?.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default CompanyNameReport;
