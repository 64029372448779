import Tooltip from 'rc-tooltip';
import { useMutation } from 'react-query';

import feathersClient from '../../apiClient';
import { ErrorAlert, SuccessAlert } from '../alerts';
import { PrimaryButton } from '../buttons';
import { MailIcon } from '../icons';
import { LoginData } from '../../types/TymbeApi';
import { ActionTypes } from '../../utils/enums';

type OwnProps = {
  login? : LoginData
};

const SendVerifySignupButton = ({ login }: OwnProps) => {
  const { mutateAsync: sendEmail } = useMutation(
    ['sendVerificationEmail'],
    (email: string) =>
      feathersClient.service('auth-management')
        .create({
          action: ActionTypes.SEND_VERIFY_SIGNUP,
          email,
        }),
  );

  const handleSendEmail = async () => {
    if (login?.username) {
      try {
        await sendEmail(login.username);
        SuccessAlert('Email odeslán');
      } catch {
        ErrorAlert('Email nebylo možné odeslat');
      }
    }
  };

  return (
    <>
      {!login?.is_verified && login?.username && (
        <Tooltip overlay="Odeslat registrační email">
          <PrimaryButton
            className="ty-icon-button ty-icon-button-toggle bg-primary"
            type="link"
            icon={<MailIcon />}
            onClick={async () => handleSendEmail()}
          />
        </Tooltip>
      )}
    </>
  );
};

export default SendVerifySignupButton;
