import { useFormState, useFormApi } from 'informed';
import { useEffect } from 'react';

import TyInput from '../../../../components/inputs/TyInput';
import validatePhoneNumber from '../../../../components/inputs/Validators/phoneNumber';
import { LoginData } from '../../../../types/TymbeApi';
import { addPlusToPhone } from '../../../../utils/phoneNumber';

export interface ActivateUserFormValues extends LoginData {
  re_password: string,
  phone?: string,
  position?: string,
}

type ActivateUserFormProps = {
  user: LoginData
};

const ActivateUserForm = ({ user }: ActivateUserFormProps) => {
  const formState = useFormState<ActivateUserFormValues>();
  const formApi = useFormApi<ActivateUserFormValues>();

  const validatePasswordFormat = (val: string) => {
    if (!val || val.length < 6) {
      return 'Minimální délka hesla je 6 znaků';
    }
    return undefined;
  };

  const validatePasswordMatch = (repswd: string) => (
    repswd !== formState.values.password ? 'Hesla se neshodují' : undefined
  );

  useEffect(() => {
    if (!user) return;
    formApi.setValues({
      ...user,
      phone: addPlusToPhone(user?.person?.contact?.find((cntct) => cntct.type === 'mobile_phone')?.value),
      position: user?.person?.company?.[0]?.title,
    });
  }, [formApi, user]);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <div className="flex flex-row gap-2 flex-wrap">
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.first_name"
            label="Jméno"
            required
            disabled
          />
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.last_name"
            label="Příjmení"
            required
            disabled
          />
        </div>
        <TyInput
          name="username"
          type="email"
          label="Email"
          disabled
          required
        />
        <TyInput
          name="phone"
          type="phone"
          label="Telefon"
          disabled
        />
        <>
          <TyInput
            name="password"
            type="password"
            label="Heslo"
            validate={validatePasswordFormat}
            validateOn="change"
            required
          />
          <TyInput
            name="re_password"
            type="password"
            label="Heslo znovu"
            validate={validatePasswordMatch}
            validateOn="blur-submit"
            required
          />
        </>
        <TyInput
          name="position"
          type="text"
          label="Pozice"
          disabled
        />
      </div>
    </div>
  );
};

export default ActivateUserForm;
