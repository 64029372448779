import { PersonUtilityData } from '@tymbe/schema/person-utility.interface';
import { UtilityData } from '@tymbe/schema/utility.interface';
import { FormState } from 'informed';
import { useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';

import PersonUtilityForm from './PersonUtilityForm';
import apiClient from '../../../../../apiClient';
import { defaultOnError } from '../../../../../apiClient/utils';
import { IconButton } from '../../../../../components/buttons';
import Button from '../../../../../components/buttons/Button';
import { CrossIcon } from '../../../../../components/icons';

type AddUtilityProps = {
  className?: string;
  personId: number | string;
};

const AddPersonUtility = ({ className, personId }: AddUtilityProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const addPersonUtility = useMutation({
    mutationFn: async (data: Partial<UtilityData>) => apiClient.service('person-utility').create(data),
    onSuccess: async () => {
      setShowModal(false);
      await queryClient.invalidateQueries('person-utility');
    },
    onError: defaultOnError,
  });

  const onSubmit = ({ modified }: FormState<PersonUtilityData>) => {
    const {
      utility,
      company,
      person,
      ...data
    } = modified;

    data.utility_id = utility?.id ?? data.utility_id;
    data.company_id = company?.id ?? data.company_id;
    data.person_id = person?.id ?? data.person_id;

    return addPersonUtility.mutateAsync(data);
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} className={className}>Přidat pomůcku</Button>
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        contentLabel="My dialog"
        className="relative bg-bg p-7 translate-1/2 rounded-lg m-auto min-w-[450px]"
        overlayClassName="bg-overlay fixed top-0 left-0 right-0 bottom-0 flex flex-col overflow-auto p-5 z-modal"
      >
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5 }}
          onClick={() => setShowModal(false)}
        >
          <CrossIcon />
        </IconButton>
        <PersonUtilityForm
          title="Nová pomůcka"
          onSubmit={onSubmit}
          personId={personId}
        >
          <div className="flex gap-4">
            <Button onClick={() => setShowModal(false)}>Zavřít</Button>
            <div className="grow" />
            <Button type="submit" disabled={addPersonUtility.isLoading}>Uložit</Button>
          </div>
        </PersonUtilityForm>
      </Modal>
    </>
  );
};

export default AddPersonUtility;
