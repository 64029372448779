import {
  FieldProps,
  RadioGroupProps as InformedRadioGroupProps,
  useField,
} from 'informed';
import { useMemo } from 'react';
import RadioGroupContext from '@tymbe/components/inputs/radio/RadioGroupContext';
import { TyInputError } from '../InputError';
import classNames from 'classnames';

export type RadioGroupProps = FieldProps<
  Omit<InformedRadioGroupProps, 'options'>,
  string | undefined,
  Record<string, unknown>
>;

export const TyRadioGroup = ({
  required = true,
  className,
  ...props
}: RadioGroupProps) => {
  const { fieldApi, fieldState, userProps } = useField<
    RadioGroupProps,
    string | undefined
  >(props);
  const { id, children } = userProps;
  const { showError, error } = fieldState;

  const groupContext = useMemo(
    () => ({
      radioGroupApi: fieldApi,
      radioGroupState: fieldState,
      ...props,
      id,
    }),
    [fieldApi, fieldState, id, props]
  );

  return (
    <RadioGroupContext.Provider value={groupContext}>
      <fieldset
        aria-describedby={`${id}-error`}
        className={classNames(
          className,
          !!(showError && error) && 'border-special-outline-error'
        )}
      >
        <>
          {showError && error && (
            <legend>
              <TyInputError name={props.name} />
            </legend>
          )}
          {children}
        </>
      </fieldset>
    </RadioGroupContext.Provider>
  );
};
