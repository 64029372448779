// Add "czech-" before values to keep them unique across all countries

export enum CzechConstantType {
  MINIMUM_WAGE = "czech-minimum-wage",
  ROCNI_PRACOVNI_HODINY = "czech-rocni-pracovni-hodiny",
  MINIMUM_PAY_SUPPLEMENT_HOLIDAY = "czech-minimum-pay-supplement-holiday",
  MINIMUM_PAY_SUPPLEMENT_SATURDAY = "czech-minimum-pay-supplement-saturday",
  MINIMUM_PAY_SUPPLEMENT_SUNDAY = "czech-minimum-pay-supplement-sunday",
  MINIMUM_PAY_SUPPLEMENT_NIGHT = "czech-minimum-pay-supplement-night",
  DPC_LIMIT = "czech-dpc-limit",
  DPC_ADVANCE_LIMIT = 'czech-dpc-advance-limit',
  DPP_LIMIT = "czech-dpp-limit",
  SLEVA_NA_POPLATINKA = "czech-sleva-na-poplatnika",
  SLEVA_NA_STUDENTA = "czech-sleva-na-studenta",
  SLEVA_INVALIDNI_DUCHOD = "czech-sleva-invalidni-duchod",
  SLEVA_INVALIDNI_DUCHOD_III = "czech-sleva-invalidni-duchod-iii",
  SLEVA_ZTPP = "czech-sleva-ztpp",
  NORMATIVNI_NAKLADY_NA_BYDLENI = "czech-normativni-naklady-na-bydleni",
  ZIVOTNI_MINIMUM = "czech-zivotni-minimum",
  NEZABAVITELNA_CASTKA = "czech-nezabavitelna-castka",
  ZAKLADNI_SOCIALNI_POJISTENI = "czech-zakladni-socialni-pojisteni",
  ZAKLADNI_ZDRAVOTNI_POJISTENI = "czech-zakladni-zdravotni-pojisteni",
  ZDRAVOTNI_POJISTENI_PLACENE_ZAMESTNAVATELEM = "czech-zdravotni-pojisteni-placene-zamestnavatelem",
  ZALOHOVA_DAN = "czech-zalohova-dan",
  SRAZKOVA_DAN = "czech-srazkova-dan",
  CISTA_MZDA_PRO_ZADRZENI_ALOKACE = "czech-cista-mzda-pro-zadrzeni-alokace",
}
