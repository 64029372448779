import UserOutlined from '@ant-design/icons/UserOutlined';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { ApplicationState } from '@tymbe/schema/enums';
import { PersonData } from '@tymbe/schema/person.interface';
import { Col, Row } from 'antd';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

import { FrogIcon } from '../../../components/icons';
import { darkBlue, darkGreen } from '../../../utils/colors';
import renderApplicationStateButton from '../utils/OrderTableExpandedRow.utils';

interface OrderTableExpandedRowProps {
  application: ApplicationData,
  onStateChanged: () => void,
}

const renderPersonName = ({ id, first_name, last_name }: PersonData) => `${id} - ${first_name} ${last_name}`;

const OrderTableExpandedRow = ({ application, onStateChanged }: OrderTableExpandedRowProps) => (
  <Col span={24} key={application.id} style={{ padding: '7px 0' }}>
    <Row>
      <Col
        className="flex items-center"
        span={8}
      >
        <div className="ty-poppins-11 ty-blue mr-3">
          <UserOutlined color={darkBlue} />
        </div>
        <div className="ty-poppins-12 flex items-center gap-2">
          <Link
            target="_blank"
            to={{
              pathname: `/user/${application.person?.id}`,
              // FIXME: Works properly, this needs to be re-typed or fixed in some way or another
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              tymberProps: { user: { ...application.person } },
            }}
            className="ty-poppins-12"
          >
            {application.person ? renderPersonName(application.person) : 'unknown user'}
            {application.invitation
            && application.state
            && application.state !== ApplicationState.REJECTED
            && application.state !== ApplicationState.SYSTEM_CANCELED ? ' (pozvánka)' : null}
          </Link>
          {application.isCompanyJunior && (
            <Tooltip overlay="Nováček">
              <span>
                <FrogIcon iconcolor={darkGreen} />
              </span>
            </Tooltip>
          )}
        </div>
      </Col>
      <Col offset={1} span={2} style={{ display: 'flex' }}>
        {!application ? null : renderApplicationStateButton({
          application,
          refetchData: onStateChanged,
        })}
      </Col>
    </Row>
  </Col>
);

export default OrderTableExpandedRow;
