import { useMutation } from 'react-query';

import { SecondaryButton } from './index';
import feathersClient from '../../apiClient';
import { ApplicationState } from '../../types/TymbeApi';

export interface CancelInvitationButtonProps {
  applicationId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const CancelInvitationButton = ({ applicationId, onSuccess, onError, ...props }: CancelInvitationButtonProps) => {
  const { mutateAsync: cancelInvitation } = useMutation(
    ['application', { id: applicationId }],
    async () => {
      await feathersClient.service('application').patch(applicationId, { state: ApplicationState.SYSTEM_CANCELED });
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2 text-right">
      <SecondaryButton onClick={cancelInvitation} {...props}>
        Zrušit pozvánku
      </SecondaryButton>
    </div>
  );
};

export default CancelInvitationButton;
