import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import Modal from 'react-modal';

import { darkBlue } from '../../../utils/colors';
import { UploadIcon } from '../../icons';
import ImageCrop, { ImageCropProps } from '../../ImageCrop/ImageCrop';
import { UploadDocumentMetadata } from '../types';

interface UploadImageProps extends Omit<ImageCropProps, 'onChange'> {
  accept?: string,
  name: string,
  uploadLabel?: string,
  modalTitle: string,
  isPreviewHidden?: boolean,
  onChange: (blob: Blob, metadata: { name: string, size: number, type: string }) => void,
  crop?: boolean,
}

const UploadImage = ({
  id = nanoid(),
  accept = 'image/*',
  crop = true,
  name,
  allowRotate,
  allowScale,
  aspectHeight,
  aspectWidth,
  maxUploadImageHeight,
  maxUploadImageWidth,
  onChange,
  uploadLabel = 'Nahrát soubor...',
  modalTitle,
  isPreviewHidden = false,
}: UploadImageProps) => {
  const [image, setImage] = useState('');
  const [imageBlob, setImageBlob] = useState<Blob>();
  const [imageMetadata, setImageMetadata] = useState<UploadDocumentMetadata>();
  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => {
    setShowModal(true);
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  const onClose = () => {
    onHideModal();
    setImage('');
  };

  const onCancel = () => {
    onClose();
  };

  const onOk = () => {
    if (imageBlob && imageMetadata) {
      onChange(imageBlob, imageMetadata);
    }
    onClose();
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.addEventListener('load', async () => {
        if (file) {
          setImageMetadata({
            name: file.name,
            type: file.type,
            size: file.size,
          });
        }
        const img = (reader.result && reader.result.toString()) || '';
        setImage(img);

        if (file.type === 'application/pdf') {
          setImageBlob(file);
        } else if (!crop && reader.result) {
          const blob = await fetch(img).then((res) => res.blob());
          setImageBlob(blob);
        }
      });
      reader.readAsDataURL(file);
      onShowModal();
    }
  };

  return (
    <>
      <label htmlFor={id} className="d-flex">
        <div className="ty-button-secondary ty-upload-button d-flex">
          <UploadIcon iconcolor={darkBlue} />
          <span>{uploadLabel}</span>
        </div>
      </label>
      <input
        id={id}
        type="file"
        name={name}
        accept={accept}
        style={{
          visibility: 'hidden',
          ...(isPreviewHidden ? { display: 'none' } : {}),
        }}
        onClick={(e) => {
          e.currentTarget.value = '';
        }}
        onChange={onSelectFile}
      />

      {image && (
        <Modal
          isOpen={showModal}
          onRequestClose={onHideModal}
          contentLabel="My dialog"
          className="ty-uploaded-image-modal"
          overlayClassName="ty-uploaded-image-modal-overlay"
        >
          {modalTitle && <h4 className="ty-h4 flex justify-center">{modalTitle}</h4>}
          <h4 className="ty-h4 flex justify-center">{imageMetadata?.name ?? ''}</h4>
          {crop && (
            <div className={imageMetadata?.type === 'application/pdf' ? 'hidden' : ''}>
              <ImageCrop
                id={id}
                image={image}
                onChange={setImageBlob}
                allowRotate={allowRotate}
                allowScale={allowScale}
                aspectWidth={aspectWidth}
                aspectHeight={aspectHeight}
                maxUploadImageWidth={maxUploadImageWidth}
                maxUploadImageHeight={maxUploadImageHeight}
              />
            </div>
          )}
          {!isPreviewHidden && !crop && image && imageMetadata?.type !== 'application/pdf' && (
            <img
              src={image}
              alt={imageMetadata?.name ?? ''}
            />
          )}

          {/* <div className="d-flex justify-content-between"> */}
          <div className="flex justify-center mt-3">
            <button type="button" className="ty-button-secondary mr-4" onClick={onCancel}>Zrušit</button>
            <button type="button" className="ty-button-primary" onClick={onOk}>Potvrdit</button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UploadImage;
