import { Params, Query } from '@feathersjs/feathers';
import { PersonAccountStateData } from '@tymbe/schema/src/person-account-state.interface';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import feathersClient from '../../apiClient';
import AdminTable from '../../components/AdminTable';
import SearchBox from '../../components/search/SearchBox';
import { PageTitle } from '../../components/texts';
// Advance search -->
import TyInteractiveLabels from '../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../components/TyAdvancedSearch/Libs/enums';
import { FormValues } from '../../components/TyAdvancedSearch/Libs/transform';
import TyAdvancedSearch from '../../components/TyAdvancedSearch/Tymber';
import apiTransformations from '../../components/TyAdvancedSearch/Tymber/apiTransformationsTymber';
// Advance search <--
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import AccountState from '../../components/AccountState/AccountState';
import { PersonContactData, PersonData } from '../../types/TymbeApi';
import { ContactType, Gender, GenderDisplay } from '../../utils/enums';

const DEFAULT_SORT = {
  id: -1,
};

const TymberListPage = () => {
  const pageTitle = 'Seznam tymberů';
  const [showSearch, setShowSearch] = useState(false);
  const [{ allSearchParams, tyAdvanceSearch }, setUrlParamsHandler] = useURLParamsHandler();
  const [searchParams, setSearchParams] = useState<Query | null>();
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_SORT);
  const [advanceSearch, setAdvanceSearch] = useState<object | undefined>(tyAdvanceSearch);
  const [advanceQuery, setAdvanceQuery] = useState<Query>();

  const currentPage = allSearchParams.page || 1;
  const pageSize = allSearchParams.pageSize || 10;

  const onTableChange = (_pagination: unknown, _filters: unknown, sorter: SorterResult<PersonData>) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'id':
          setSort({ 'person.id': sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  const onSearch = (value: string) => {
    if (value === '') {
      setUrlParamsHandler({ search: '' });
      return setSearchParams(null);
    }
    const query: Params = {
      $eager: '[login.[role], contact, personData]',
      $joinRelation: '[login.[role]]',
      $leftJoinRelation: '[contact, personData]',
      $any: {
        'login:role.slug': 'tymber',
      },
      $modify: { quickFilter: (value as string).split(' ') },
    };

    setUrlParamsHandler({ page: 1, search: value });
    return setSearchParams(query);
  };
  const formattedPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/^00/, '+');

  const columns = [
    {
      title: 'ID Tymber',
      dataIndex: 'id',
      align: 'left',
      render: (id: number) => (
        <Link
          target="_blank"
          to={{
            pathname: `/user/${id}`,
          }}
          className="ty-poppins-12"
        >
          {id}
        </Link>
      ),
      width: 100,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Stav účtu',
      dataIndex: ['accountState'],
      align: 'left',
      width: 100,
      render: (row: PersonAccountStateData) => <AccountState accountState={row} />,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Jméno',
      dataIndex: ['first_name'],
      align: 'left',
      render: (_id: number, row: PersonData) => `${row.first_name} ${row.last_name}`,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Pohlavi',
      dataIndex: ['personData', 'gender'],
      align: 'left',
      render: (gender: Gender) => GenderDisplay[gender] || '-',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Věk',
      dataIndex: ['personData', 'birthdate'],
      align: 'left',
      render: (date: string) => moment().diff(date, 'years') || '-',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Email/Login',
      dataIndex: ['login', 'username'],
      align: 'left',
      render: (item: string) => item || '-',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Mobil',
      dataIndex: 'unfilled',
      align: 'left',
      render: (item: string, row: PersonData) => {
        const phoneNumber = row.contact?.find(
          (x: PersonContactData) => x.type === ContactType.MOBILE_PHONE,
        )?.value;
        return phoneNumber ? formattedPhoneNumber(phoneNumber) : '-';
      },
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  const { data, isLoading } = useQuery(
    ['person', advanceQuery],
    async ({ queryKey }) => feathersClient.service('person').find({
      query: {
        ...queryKey[1] as Query,
        $limitToRelatedCompanies: true,
      },
    }),
    { refetchOnWindowFocus: false },
  );

  const onPaginationChange = (page: number, newPageSize: number) => {
    setUrlParamsHandler({ page });
    setUrlParamsHandler({ pageSize: newPageSize });
  };

  useEffect(() => {
    let q: Params = {
      ...searchParams,
      $eager: '[login.[role], contact, accountState, personData]',
      $joinRelation: '[login.[role], accountState]',
      $leftJoinRelation: '[contact, personData]',
      $skip: pageSize * (currentPage - 1),
      $limit: pageSize,
      $sort: sort,
      $any: {
        'login:role.slug': 'tymber',
      },
    };

    if (advanceSearch) {
      setUrlParamsHandler(advanceSearch);
      q = {
        ...q,
        ...apiBuilder(
          advanceSearch,
          apiTransformations,
        ),
      };
    }

    if (searchParams?.$modify && q.$modify) {
      q.$modify.quickFilter = searchParams.$modify.quickFilter;
    }

    setAdvanceQuery(q);
  }, [advanceSearch, currentPage, pageSize, searchParams, sort]);

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <PageTitle>{pageTitle}</PageTitle>
        <div className="bg-bg flex flex-row items-center justify-end">
          <div className="flex flex-row items-center justify-end m-2 space-x-2">
            <SearchBox
              name="tymberSearch"
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              onParamsSearch={onSearch}
              loading={isLoading}
              placeholder="Jméno, ID, Mobil nebo Email Tymbera"
            />
            <TyAdvancedSearch
              pageTitle="Filtrování Tymberů"
              setInitialValues={setAdvanceSearch}
              initialValues={advanceSearch}
            />
          </div>
        </div>
        <div className="flex bg-bg">
          <div className="flex space-x-2 m-2">
            <TyInteractiveLabels
              labelsValues={labelsValues}
              setInitialValues={setAdvanceSearch}
              initialValues={advanceSearch as FormValues}
            />
          </div>
        </div>
        <AdminTable
          rowKey={(row: PersonData) => row.id}
          columns={columns}
          data={data || { total: 0, data: [], skip: 0, limit: 10 }}
          isLoading={isLoading}
          currentPage={currentPage}
          onPaginationChange={onPaginationChange}
          onChange={onTableChange}
          defaultPageSize={pageSize}
        />
      </Wrapper>
    </Container>
  );
};

export default TymberListPage;
