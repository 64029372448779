import { Query } from '@feathersjs/feathers';
import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { CompanyData } from '@tymbe/schema/company.interface';
import { Form, FormProps, InformedProps, FormApi } from 'informed';
import { MomentInput } from 'moment';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';

import { useUser } from '../../../../../apiClient/ApiContext';
import { SecondaryButton, SubmitButton } from '../../../../../components/buttons';
import TyAsyncSelect from '../../../../../components/inputs/TyAsyncSelect';
import TyDateInput from '../../../../../components/inputs/TyDateInput';
import TyInput from '../../../../../components/inputs/TyInput';
import TySelectBranchoffice from '../../../../../components/inputs/TySelectBranchoffice';
import TySelectCompany from '../../../../../components/inputs/TySelectCompany';
import factoryLoadOptions from '../../../../../components/TyAdvancedSearch/Libs/factoryLoadOptions';
import { BlobWithMetadata } from '../../../../../components/upload/types';
import UploadDocumentFile from '../../../../../components/upload/UploadDocumentFile/UploadDocumentFile';
import { Roles } from '../../../../../utils/enums';

export interface NewDocumentFormValues {
  person_id: number,
  document_type: { label: string, value: string },
  company: CompanyData,
  branchoffice: BranchofficeData,
  name: string | null,
  valid_from: string | null,
  valid_to: string | null,
  template_data: any | null,
  did: string | null,
  files: Blob | null,
  reviewed_at: string | null,
  reviewed_by: number | null,
  approved: boolean | null,
  uploadedFiles: BlobWithMetadata[],

  fileBlob: Blob[] | null,
}

type DocumentFormProps = {
  document?: Partial<NewDocumentFormValues>,
  onCancel: () => void,
  disabled?: boolean,
} & InformedProps<FormProps, Partial<NewDocumentFormValues>>;

const loadNewDocumentOptions = factoryLoadOptions(
  'document-type',
  ({ search, options }) => {
    const query: Query = {
      $eager: 'company',
      'document_type.name': { $ilike: `%${search}%` },
      'document_type.is_signable': false,
      $skip: options.length,
    };
    return { query };
  },
  (v: { name: string, id: number, company?: { name: string, id: number } }) => ({ label: v.company ? `[${v.id}] [${v.company.id}] [${v.company.name}] ${v.name}` : `[${v.id}] ${v.name}`, value: v.id }),
);

const DocumentForm = ({ document, onSubmit, onCancel, disabled, ...rest }: DocumentFormProps) => {
  const user = useUser();
  const [uploadedFiles, setUploadedFiles] = useState<BlobWithMetadata[]>([]);
  const apiRef = useRef<FormApi<Partial<NewDocumentFormValues>>>();

  const getIsDisabledValidFromDate = (inputDate: MomentInput) => {
    let isDateDisabled = false;
    const date = moment(inputDate);
    if (user.hasRoles([Roles.SUPER_ADMIN])) return isDateDisabled;

    const fifthOfThisMonth = moment().set('date', 1).add(4, 'days');

    if (user.hasRoles([Roles.TYMBE_ADMIN]) && moment().isSameOrBefore(fifthOfThisMonth, 'day')) {
      isDateDisabled = date.isBefore(moment().subtract(2, 'month').endOf('month'));
    } else {
      isDateDisabled = date.isBefore(moment().subtract(1, 'month').endOf('month'));
    }
    return isDateDisabled;
  };

  useEffect(() => {
    apiRef.current?.setValue('uploadedFiles', uploadedFiles);
  }, [uploadedFiles]);

  return (
    <Form {...rest} onSubmit={onSubmit} initialValues={document} formApiRef={apiRef}>
      <div className="grid md:grid-cols-2">
        <div>
          <div>
            <h3>Typ dokumentu</h3>
            <TyAsyncSelect
              loadOptions={loadNewDocumentOptions}
              name="document_type"
              label="Typ dokumentu"
              isClearable
              required
              isDisabled
            />
          </div>
          <div>
            <h3>Firma</h3>
            <TySelectCompany label="Firma" name="company" isDisabled />
          </div>
          <div>
            <h3>Provozovna/Oddělení</h3>
            <TySelectBranchoffice label="Provozovna/Oddělení" name="branchoffice" isDisabled />
          </div>
          <div>
            <h3>Název</h3>
            <TyInput label="Název" name="name" disabled />
          </div>
        </div>
        <div>
          <div>
            <h3>Platnost od</h3>
            <TyDateInput
              label="Platnost od"
              name="valid_from"
              disabledDate={getIsDisabledValidFromDate}
              validate={(date) => (getIsDisabledValidFromDate(date) ? 'Nevalidní vstup' : undefined)}
              disabled
            />
          </div>
          <div>
            <h3>Platnost do</h3>
            <TyDateInput
              label="Platnost do"
              name="valid_to"
              className="absolute"
              validate={(date) => (getIsDisabledValidFromDate(date) ? 'Nevalidní vstup' : undefined)}
              disabled
            />
          </div>

          <div>
            <h3>Číslo dokumentu</h3>
            <TyInput label="Číslo dokumentu" name="did" />
          </div>
          <div>
            <h3>Proměnné smlouvy</h3>
            <TyInput
              hidden={!user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN])}
              label="Proměnné smlouvy"
              name="template_data"
              mask={(value: string) => {
                if (value && typeof value === 'object') {
                  return JSON.stringify(value);
                }
                return value;
              }}
              validate={(value?: string) => {
                if (!value) return undefined;
                try {
                  JSON.parse(value);
                  return undefined;
                } catch (e) {
                  return `Neplatná JSON struktura: ${e}`;
                }
              }}
              disabled
            />
          </div>
        </div>
        <div hidden>
          <h3>Soubory</h3>
          <UploadDocumentFile setUploadedFiles={setUploadedFiles} uploadedFiles={uploadedFiles} />
        </div>
      </div>
      <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" disabled={disabled} />
      <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={onCancel} />
    </Form>
  );
};

export default DocumentForm;
