import { useMutation } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { SecondaryButton } from '../../../../../components/buttons';

export interface DeleteAttendanceProps {
  attendanceId: number;
  deleteSurveyAnswers?: boolean;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const DeleteAttendance = ({ attendanceId, deleteSurveyAnswers, onSuccess, onError }: DeleteAttendanceProps) => {
  const { mutateAsync: deleteAttendance } = useMutation(
    ['deleteAttendance'],
    async () => {
      if (deleteSurveyAnswers) {
        await feathersClient.service('survey-answer').remove(null, { query: { attendance_id: attendanceId } })
          .catch((errorResp) => {
            if (errorResp?.code === 404) return;
            throw errorResp;
          });
      }
      await feathersClient.service('attendance').remove(attendanceId);
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2">
      <SecondaryButton onClick={deleteAttendance}>
        Smazat docházku
      </SecondaryButton>
    </div>
  );
};

export default DeleteAttendance;
