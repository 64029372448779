import Modal from 'react-modal';
import classNames from 'classnames';
import { CrossIcon } from '../icons/symbols/CrossIcon';
import { textStyles } from '../style/text-styles';

const ImageModal = ({
  document,
  isOpen,
  onRequestClose,
}: Modal.Props & {
  document?: Blob;
}) => (
  <Modal
    isOpen={isOpen}
    closeTimeoutMS={300}
    shouldCloseOnOverlayClick
    ariaHideApp={false}
    className={classNames(
      'fixed',
      'bg-base-back-inverse',
      'drop-shadow-lg',
      'left-[40%]',
      'top-[50%]',
      '-translate-x-1/2',
      '-translate-y-1/2',
      'max-w-screen-md',
    )}
    overlayClassName={{
      base: classNames(
        'bg-base-back-overlay-1 fixed inset-0 z-modal',
        'transition-all duration-500 ease-in-out',
        'opacity-0',
        'translate-x-28',
      ),
      afterOpen: classNames(
        isOpen ? 'opacity-100' : 'opacity-0',
        isOpen ? '!translate-x-40' : 'translate-x-28',
      ),
      beforeClose: 'opacity-0 translate-x-28',
    }}
  >
    <div
      className={classNames(
        textStyles.h4,
        'flex justify-self-end content-center bg-base-front-inverse p-6',
      )}
    >
      <CrossIcon
        onClick={onRequestClose}
        className="cursor-pointer text-system-front-default-icon"
      />
    </div>
    {!document ? undefined : (
      // This is so the image cannot be downloaded as usual image, still, there should be additional
      // protection, using <div> with backgroundImage might also not be a bad idea
      <img
        src={URL.createObjectURL(document)}
        alt="Dokument"
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        onContextMenu={(e) => e.preventDefault()}
      />
    )}
  </Modal>
);

export default ImageModal;
