import { AttendanceData } from '../../../../../types/TymbeApi';

export interface PersonLiabilityDetailsProps {
  attendance: AttendanceData;
}

const PersonLiabilityDetails = ({ attendance }: PersonLiabilityDetailsProps) => (
  <div>{attendance?.personLiability?.description ? (
    <div className="flex">
      <div className="text-secondary font-semibold text-sm leading-5 w-40">
        <div>
          Manko poznámka:
        </div>
      </div>
      <div className="ml-10 w-72">
        <div className="pb-1">
          {attendance?.personLiability?.description}
        </div>
      </div>
    </div>
  ) : null}
  </div>

);

export default PersonLiabilityDetails;
