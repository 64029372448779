import { useFormApi } from 'informed';
import { useEffect, useState } from 'react';

import { checkFileSize } from './blob.utils';
import feathersClient from '../../apiClient';
import getBucketServiceParams from '../../utils/getBucketServiceParams';
import { ErrorAlert } from '../alerts';
import UploadedImage from '../upload/UploadedImage/UploadedImage';
import UploadSign from '../UploadSign/UploadSign';

export interface StampFormValues {
  signatureBlob: Blob | null,
}

type SignatureFormProps = {
  signature?: string | null;
};

const SignatureForm = ({ signature }: SignatureFormProps) => {
  const formApi = useFormApi<StampFormValues>();
  const [signatureBlob, setSignatureBlob] = useState<Blob | null>(null);
  const blobService = feathersClient.service('bucket');

  useEffect(() => {
    if (!signature) return undefined;
    const controller = new AbortController();
    blobService.get(signature, getBucketServiceParams(controller))
      .then(setSignatureBlob);

    return () => controller.abort();
  }, [blobService, signature]);

  const onSetSignatureBlob = (blob: Blob) => {
    if (checkFileSize(blob)) {
      setSignatureBlob(blob);
      formApi.setValue('signatureBlob', blob);
    } else {
      ErrorAlert('Soubor musí být menší než 5MB!');
    }
  };

  return (
    <div id="signature-form">
      <h4> Podpis </h4>
      <UploadSign
        uploadLabel="Vložit podpis"
        onChange={onSetSignatureBlob}
      />

      {signatureBlob && (
        <UploadedImage
          alt="Podpis - obrázek"
          image={signatureBlob}
          removeImage={() => {
            formApi.setValue('signatureBlob', null);
            setSignatureBlob(null);
          }}
        />
      )}
    </div>
  );
};

export default SignatureForm;
