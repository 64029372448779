import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert } from '../../../../../components/alerts';
import { SecondaryButton } from '../../../../../components/buttons';
import { TrashIcon } from '../../../../../components/icons';
import AlertModal from '../../../../../components/modals/AlertModal';

export interface DeleteBlockProps {
  blockId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const DeleteBlock = ({ blockId, onSuccess }: DeleteBlockProps) => {
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: deleteBlock } = useMutation(
    ['delete-company-blocked-user'],
    async (id) => {
      await feathersClient.service('company-blocked-user').remove(id);
    },
    {
      onSuccess,
    },
  );

  const onSubmit = async () => {
    try {
      await deleteBlock(blockId);
      setShowModal(false);
    } catch {
      ErrorAlert('Při mazání blokace došlo k chybě');
    }
  };
  return (
    <>
      <div className="p-1">
        <SecondaryButton
          className="ty-icon-button hover:border-error/50 border-error"
          type="button"
          icon={<TrashIcon />}
          onClick={() => setShowModal(true)}
        />
      </div>
      <AlertModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        title="Pokoušíte se odebrat blokaci"
        message="Opravdu chcete smazat tuto blokaci?"
        onYes={onSubmit}
      />
    </>
  );
};

export default DeleteBlock;
