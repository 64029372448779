import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SubmitButton } from '../../../../../components/buttons';
import { getValuesForPatch } from '../../../../../components/forms/form-util';
import Card from '../../../../../components/Layout/Card';
import SendVerifySignupButton from '../../../../../components/SendVerifySignupButton/SendVerifySignupButton';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import { RequestBody } from '../../../../../types/ReactQuery';
import { LoginData, PersonContactData } from '../../../../../types/TymbeApi';
import { ContactType } from '../../../../../utils/enums';
import { addZerosToPhone } from '../../../../../utils/phoneNumber';
import UserForm, { UserFormValues } from '../components/UserForm';

const EditUser = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();
  const { companyId, loginId } = useParams();

  const { mutateAsync: patchLogin } = useMutation(
    ['CreateLogin'],
    (req: RequestBody<LoginData>): Promise<LoginData> =>
      feathersClient.service('login').patch(req.id, req.body),
  );

  const { data: login } = useQuery(['FetchLoginDetail', loginId], async (): Promise<LoginData> =>
    feathersClient.service('login').get(
      loginId!,
      {
        query: {
          $eager: '[person.[company, branchoffice, contact], role]',
        },
      },
    ), { enabled: !!loginId });

  if (!loginId) {
    return <Navigate to="/shift" />;
  }

  const personPhone = login?.person?.contact?.find(
    (contact: PersonContactData) => contact.type === ContactType.MOBILE_PHONE,
  );

  const onSubmit = async (formState: FormState<UserFormValues>) => {
    if (!loginId) return;
    setFormDisabled(true);
    const values = getValuesForPatch(formState);

    const personContact: Partial<PersonContactData>[] = login?.person?.contact || [];

    if (values.phone !== undefined) {
      if (personPhone) {
        personPhone.value = addZerosToPhone(values.phone) || null;
      } else {
        personContact?.push({
          person_id: login?.person_id,
          name: 'mobil',
          type: ContactType.MOBILE_PHONE,
          value: addZerosToPhone(values.phone) || null,
        });
      }
    }

    let branchoffice;
    if (values.assigned_branchoffices) {
      branchoffice = values.assigned_branchoffices.map((x) => (
        {
          id: x.id,
          title: values.position,
        }
      ));
    }

    let company;
    if (values.position !== undefined) {
      company = [
        {
          id: Number(companyId),
          title: values.position,
        },
      ];
    }

    let person;
    if (values.person !== undefined) {
      person = {
        ...values.person,
        id: login?.person_id,
        company,
        branchoffice,
        contact: personContact.length !== 0 ? personContact : undefined,
      };
    }

    try {
      await patchLogin({
        id: loginId,
        body: {
          role: values.role?.[0],
          person,
        } as LoginData,
      });
      SuccessAlert('Uživatel byl úspěšně upraven');
    } catch {
      ErrorAlert('Při pokusu o upravení uživatele nastala chyba');
    }

    setFormDisabled(false);
    history(`/company/${companyId}/user`);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div className="flex">
          <h1> Upravení uživatele </h1>
          <SendVerifySignupButton login={login} />
        </div>
        <Card>
          <Form<UserFormValues> name="person-form" onSubmit={onSubmit}>
            <UserForm user={login} companyId={Number(companyId)} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditUser;
