import { PersonData } from '@tymbe/schema/person.interface';
import { Gender } from '@tymbe/schema/enums';
import moment from 'moment';
import {
  DividerCircleIcon,
  FemaleIcon,
  MaleIcon,
} from '../../../../components/icons';
import { UserAvatar } from '../../../../components/Avatar/UserAvatar';
import AccountState from '../../../../components/AccountState/AccountState';
import { TyLinkCard } from '@tymbe/ty-components/layout';
import { Paragraph } from '@tymbe/ty-components/text';

type Props = {
  person?: PersonData;
};

export const PersonColumn = ({ person }: Props) => {
  return (
    <TyLinkCard
      to={{
        pathname: `/user/${person?.id}/shifts`,
      }}
      target="_blank"
      className="!shadow-none px-2 py-2 ml-1 [&>div]:flex [&>div]:items-center "
    >
      <UserAvatar person={person} />
      <div className="mx-1" />
      <div>
        <div className="flex items-center gap-1">
          <Paragraph variant="body2Rg" className="text-base-front-1 truncate max-w-[8vw]">
            {person?.first_name} {person?.last_name}
          </Paragraph>
          <div className="h-4 w-4">
            <AccountState accountState={person?.accountState} />
          </div>
        </div>
        <Paragraph variant="captionRg" className="text-base-front-1 flex">
          {person?.personData?.gender === Gender.MALE ? (
            <MaleIcon />
          ) : (
            <FemaleIcon />
          )}
          {moment().diff(person?.personData?.birthdate, 'years')}
          <DividerCircleIcon className="mx-1" />
          {person?.id}
        </Paragraph>
      </div>
    </TyLinkCard>
  );
};
