import { getBlocks, isBlocked } from '@tymbe/utils/company-blocked-user';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../../components/buttons';
import ModalShell from '../../../../../components/modals/ModalShell';
import { ApplicationData, ShiftData } from '../../../../../types/TymbeApi';
import { ApplicationStateDisplay } from '../../../../../utils/enums';
import { NoFreeManShiftBehavior, invite } from '../utils/ShiftInvite';

interface ResendInvitationButtonProps {
  application: ApplicationData & { shift: ShiftData };
}

const ResendInvitationButton = ({
  application,
  ...props
}: ResendInvitationButtonProps) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const resendInvitationRequest = async (canCreateManShift: NoFreeManShiftBehavior) => {
    try {
      const {
        noFreeManShift,
        applicationExists,
        application: inviteApplication,
      } = await invite(application.person_id, application.shift.id, canCreateManShift);

      if (noFreeManShift) {
        setShowModal(true);
        return;
      }
      if (!applicationExists) {
        SuccessAlert('Pozvánka byla úspěšně odeslána');
      } else {
        SuccessAlert(`Pozvánka/přihláška již existuje a je ve stavu: ${application.state === null ? '-' : ApplicationStateDisplay[application.state]}`);
      }

      queryClient.invalidateQueries(['application', application.id]);
    } catch (error) {
      if (error instanceof Error) {
        ErrorAlert(error.message);
      }
    }
  };

  const { mutateAsync: getPerson } = useMutation(
    ['getPerson'],
    async (id: number) => feathersClient.service('person').get(id, {
      query: {
        $eager: '[blockedCompany]',
      },
    }),
  );

  const checkIfBlocked = async () => {
    const company = {
      id: application.shift.company_id,
      branchoffice: application.shift.branchoffice?.parent_id,
      department: application.shift.branchoffice?.id,
    };
    const person = await getPerson(application.person_id);
    if (!person) ErrorAlert('Vyskytla se chyba.');
    const blocked = isBlocked(getBlocks(person.blockedCompany, company));
    return blocked;
  };

  const reinvite = async () => {
    const blocked = await checkIfBlocked();
    if (blocked) {
      ErrorAlert('Pozvánku nelze znovu poslat z důvodu blokace.');
    } else {
      resendInvitationRequest(NoFreeManShiftBehavior.Return);
    }
  };

  const addManShift = async () => {
    resendInvitationRequest(NoFreeManShiftBehavior.CreateNew);
    setShowModal(false);
  };

  return (
    <>
      <div className="p-2">
        <SecondaryButton onClick={reinvite} {...props}>
          Znovu pozvat
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <h3 className="ty-h3 text-align-start">
          Pro směnu s ID: {application.id} nebyla nalezena žádná volná místa.
        </h3>
        <h4>Chcete objednávku navýšit?</h4>
        <div className="flex gap-x-1 justify-end items-end">
          <button
            type="button"
            className="ty-button-secondary"
            onClick={() => setShowModal(false)}
          >
            Zavřít
          </button>
          <SubmitButton
            className="ty-button-primary mt-5"
            buttontext="Navýšit"
            onClick={addManShift}
          />
        </div>
      </ModalShell>
    </>
  );
};

export default ResendInvitationButton;
