import { BlockReasons } from '@tymbe/schema/enums';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SecondaryButton } from '../../../../../components/buttons';
import { PlusOutlinedIcon } from '../../../../../components/icons';
import NewBlock, { CreateNewBlockFormProps } from '../../../../blocks/NewBlock';

const NewBlockButton = () => {
  const qClient = useQueryClient();
  const { personId } = useParams();

  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (data: CreateNewBlockFormProps) => {
    const newBlockedPerson = {
      company_id: data.company.id,
      person_id: data.person_id?.value || Number(personId),
      note: data.note || null,
      branchoffice_id: data.department?.id || data.branchoffice?.id || null,
      reason: data.reason.value.reason,
      severity: Number(data.reason.value.severity),
    };
    if (newBlockedPerson.reason === BlockReasons.OTHER && !newBlockedPerson.note) {
      ErrorAlert('V případě důvodu "Jiné" je potřeba vyplnit poznámku blokace!');
      return;
    }

    try {
      await feathersClient.service('company-blocked-user').create(
        newBlockedPerson,
        { query: { $cancelApplications: true, $freeManShifts: data.freeManShifts, $notify: true } },
      );
      setShowModal(false);
      qClient.invalidateQueries(['company-blocked-user']);
      qClient.invalidateQueries(['person']);
      SuccessAlert('Nová blokace vytvořena');
    } catch {
      ErrorAlert('Při vytváření blokace došlo k chybě');
    }
  };

  return (
    <>
      <div className="pb-3 flex justify-end">
        <SecondaryButton
          className="flex flex-row justify-center align-center gap-2"
          onClick={() => setShowModal(true)}
        >
          <PlusOutlinedIcon />
          <span> Nová blokace </span>
        </SecondaryButton>
      </div>
      <NewBlock
        onSubmit={({ values }) => onSubmit(values)}
        showModal={showModal}
        setShowModal={setShowModal}
        hideTymberSelect
      />
    </>
  );
};

export default NewBlockButton;
