import { Moment } from 'moment';
import { useQuery } from 'react-query';

import feathersClient from '../../../../../apiClient';
import Card from '../../../../../components/Layout/Card';
import { InlineSpinner } from '../../../../../utils/spinner';

interface CreditCalculationProps {
  personId: string;
  from: Moment;
  to: Moment;
}

const CreditsCalculation = ({ personId, from, to }: CreditCalculationProps) => {
  const query = {
    from: from.toISOString(),
    to: to.toISOString(),
  };

  const { data: credits, isLoading } = useQuery(
    ['credit-calculation', query],
    async () =>
      feathersClient
        .service('credit-calculation')
        .get(personId, { query }),
  );

  return (
    <Card>
      {(isLoading || !credits)
        ? <InlineSpinner />
        : (
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div>
              <table className="w-auto border-spacing-y-1.5 border-spacing-x-5 border-separate [&_*]:text-right">
                <tbody>
                  <tr>
                    <th>Celkový nárok </th>
                    <td>{credits.total} Kč</td>
                  </tr>
                  <tr>
                    <th>Získané</th>
                    <td>{credits.claimed} Kč</td>
                  </tr>
                  <tr>
                    <th>Čeká na zpracování</th>
                    <td>{credits.pending_processing} Kč</td>
                  </tr>
                  <tr>
                    <th>Vyplaceno</th>
                    <td>{credits.paid} Kč</td>
                  </tr>
                  <tr>
                    <th>Zatím nezískané</th>
                    <td>{credits.unclaimed} Kč</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
    </Card>
  );
};

export default CreditsCalculation;
