import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import Card from '../../../../components/Layout/Card';
import SendVerifySignupButton from '../../../../components/SendVerifySignupButton/SendVerifySignupButton';
import { PersonContactData } from '../../../../types/TymbeApi';
import { ContactType } from '../../../../utils/enums';

const UserContactList = () => {
  const { personId } = useParams();

  const { data: person } = useQuery(['person-contact', personId], async () =>
    feathersClient.service('person').get(personId!, {
      query: {
        $eager: '[contact, login]',
      },
    }), { enabled: !!personId });

  if (!personId) {
    return <Navigate to="/shift" />;
  }

  const personPhone = person?.contact?.find(
    (contact: PersonContactData) => contact.type === ContactType.MOBILE_PHONE,
  );

  return (
    <Card>
      <div className="flex items-center gap-5">
        <h5 className="ty-h5">email:</h5>
        {person?.login?.username} <SendVerifySignupButton login={person?.login} />
      </div>
      <div className="flex items-center gap-5">
        <h5 className="ty-h5">mobil: </h5> {personPhone?.value}
      </div>
    </Card>
  );
};

export default UserContactList;
