import { Service } from '@feathersjs/feathers';
import { DocumentType } from '@tymbe/schema/enums';
import { FormState } from 'informed';
import moment from 'moment';

import feathersClient from '../../../../../apiClient';
import { getValuesForPatch } from '../../../../../components/forms/form-util';
import { DocumentTypeData, DocumentTypeEnum, isPaginated } from '../../../../../types/TymbeApi';
import { DocumentTypeFormContainerFields }
  from '../components/DocumentTypeFormContainer';

const documentTypeService: Service<DocumentTypeData> = feathersClient.service('document-type');

const getDefaultTemplate = async () => {
  const query = {
    type: DocumentTypeEnum.CONTRACT_DPP_TEMPLATE,
    published_at: {
      $lt: moment().toISOString(),
    },
  };

  const response = await documentTypeService.find({ query });
  const template = isPaginated(response) ? response.data[0] : (response as DocumentTypeData[])?.[0];

  if (!template || !template.template_id) {
    throw new Error('Nastala chyba. Nepodařilo se nalézt vzorovou šablonu');
  }

  return template;
};

// eslint-disable-next-line import/prefer-default-export
export const createDocumentTypeWithTemplate = async (
  companyId: number,
  formState: FormState<DocumentTypeFormContainerFields>,
  documentTypeId?: number,
) => {
  const modifiedAndNulled = getValuesForPatch(formState);

  const branchOfficeId = modifiedAndNulled?.assigned_branchoffice?.id
    ? Number(modifiedAndNulled.assigned_branchoffice.id)
    : null;
  const type = modifiedAndNulled?.type?.value || DocumentType.CONTRACT_DPP;
  const publishedAt = modifiedAndNulled?.is_published?.value ? new Date().toISOString() : null;
  const documentTemplate = !documentTypeId ? await getDefaultTemplate() : undefined;

  let templateId;
  if (modifiedAndNulled.editorJS) {
    templateId = null;
  } else if (!documentTypeId) {
    templateId = documentTemplate?.template_id;
  }

  const requestBody: Partial<DocumentTypeData> = {
    name: modifiedAndNulled.name !== undefined ? modifiedAndNulled.name : undefined,
    display_name: modifiedAndNulled.display_name !== undefined ? modifiedAndNulled.display_name : undefined,
    branchoffice_id: modifiedAndNulled?.assigned_branchoffice !== undefined ? branchOfficeId : undefined,
    type: modifiedAndNulled.type !== undefined || !documentTypeId ? type : undefined,
    category: modifiedAndNulled.category !== undefined ? modifiedAndNulled.category?.value || null : undefined,
    validity_start_at: modifiedAndNulled.validity_start_at !== undefined
      ? modifiedAndNulled.validity_start_at?.value || null
      : undefined,
    validity_end_at: modifiedAndNulled.validity_end_at !== undefined
      ? modifiedAndNulled.validity_end_at?.value || null
      : undefined,
    validity_duration: modifiedAndNulled.validity_duration !== undefined
      ? modifiedAndNulled.validity_duration
      : undefined,
    validity_duration_unit: modifiedAndNulled.validity_duration_unit !== undefined
      ? modifiedAndNulled.validity_duration_unit?.value || null
      : undefined,
    published_at: modifiedAndNulled?.is_published !== undefined ? publishedAt : undefined,
  };

  const requestParams = modifiedAndNulled.editorJS ? { query: { $editorJS: true } } : undefined;

  if (!documentTypeId) {
    requestBody.company_id = companyId;
    requestBody.is_signable = true;
    requestBody.template_id = templateId;

    await documentTypeService
      .create(requestBody, requestParams)
      .catch(() => {
        throw new Error('Při pokusu o vytvoření dokumentu nastala nečekaná chyba');
      });
  } else {
    await documentTypeService
      .patch(documentTypeId, requestBody)
      .catch(() => {
        throw new Error('Nastala chyba při pokusu o upravení dokumentu');
      });
  }
};
