import { Paginated, Query } from '@feathersjs/feathers';
import { PersonNoteData } from '@tymbe/schema/person-note.interface';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import NewNoteButton from './components/NewNoteButton';
import NoteInfo from './components/NoteInfo';
import feathersClient from '../../../../apiClient';
import Card from '../../../../components/Layout/Card';
import { TablePagination, useTablePagination } from '../../../../components/Table';

const NotesContainer = () => {
  const { personId } = useParams();
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const [query, setQuery] = useState<Query>({
    'person_note.person_id': personId,
    $eager: '[createdBy]',
    $sort: { pinned: -1, created_at: -1 },
  });

  const { data: personNote } = useQuery(['person-note', query], async () =>
    feathersClient.service('person-note').find({
      query,
    }) as Promise<Paginated<PersonNoteData>>, { enabled: !!personId });

  useEffect(() => {
    const q = {
      ...query,
      $skip: paginationStart,
      $limit: paginationPageSize,
    };
    setQuery(q);
    // query should not be in the dep. array cos infinity loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationPageSize, paginationStart]);

  return (
    <Card>
      <NewNoteButton />
      <div>
        {personNote?.data.map((note: PersonNoteData) => (
          <div key={note.id} className="pb-3">
            <NoteInfo note={note} />
          </div>
        ))}
      </div>
      <TablePagination
        {...setPageParams()}
        rowsCount={personNote?.total}
      />
    </Card>
  );
};

export default NotesContainer;
