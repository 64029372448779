import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert } from '../../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../../components/buttons';
import { TySelect } from '../../../../../components/inputs/TySelect';
import ModalShell from '../../../../../components/modals/ModalShell';
import { RequestBody } from '../../../../../types/ReactQuery';
import { AttendanceData } from '../../../../../types/TymbeApi';
import { DeepPartial } from '../../../../../types/utils';
import { AttendanceResolution, AttendanceResolutionDisplay } from '../../../../../utils/enums';

export interface AttendanceFormValues {
  resolution: { label: string, value: AttendanceResolution }
}

const resolutionOptions = [
  {
    label: AttendanceResolutionDisplay.admin_excused_absence,
    value: AttendanceResolution.ADMIN_EXCUSED_ABSENCE,
  },
  {
    label: AttendanceResolutionDisplay.breach_of_discipline,
    value: AttendanceResolution.BREACH_OF_DISCIPLINE,
  },
  {
    label: AttendanceResolutionDisplay.cancel,
    value: AttendanceResolution.CANCEL,
  },
  {
    label: AttendanceResolutionDisplay.excused_absence,
    value: AttendanceResolution.EXCUSED_ABSENCE,
  },
  {
    label: AttendanceResolutionDisplay.ok,
    value: AttendanceResolution.OK,
  },
  {
    label: AttendanceResolutionDisplay.unexcused_absence,
    value: AttendanceResolution.UNEXCUSED_ABSENCE,
  },
];

export interface EditAttendanceResolutionProps {
  attendanceId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const EditAttendanceResolution = ({ attendanceId, onSuccess }: EditAttendanceResolutionProps) => {
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: editAttendanceResolution } = useMutation(
    ['editAttendanceResolution'],
    async (req: RequestBody<DeepPartial<AttendanceData>>): Promise<void> => {
      await feathersClient.service('attendance').patch(req.id, req.body);
    },
    {
      onSuccess,
    },
  );

  const onSubmit = async ({ values }: FormState<Partial<AttendanceFormValues>>) => {
    const attendanceFormData = {
      resolution: values.resolution?.value,
    } as DeepPartial<AttendanceData>;

    try {
      await editAttendanceResolution({ id: attendanceId, body: attendanceFormData });
      setShowModal(false);
    } catch {
      ErrorAlert('Při změně docházky došlo k chybě');
    }
  };
  return (
    <>
      <div className="p-2">
        <SecondaryButton onClick={() => setShowModal(true)}>
          Změnit stav docházky
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Změnit stav docházky
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">
            <TySelect
              id="resolution"
              className=""
              name="resolution"
              label="Stav"
              options={resolutionOptions}
              required
            />
          </div>
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditAttendanceResolution;
