import { Query } from '@feathersjs/feathers';
import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { SalaryAdvanceRestrictionStatus } from '@tymbe/schema/enums';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { Moment } from 'moment-timezone';
import { Fragment } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '../../../../../apiClient';
import Card from '../../../../../components/Layout/Card';
import { priceString, revertNumber } from '../../../../../utils/salary';
import { InlineSpinner } from '../../../../../utils/spinner';

interface SalaryCalculationProps {
  companyId: number;
  personId: string;
  from: Moment;
  to: Moment;
  salaryType?: CzechSalaryType;
}

const defaultSupplementValues = {
  [PaySupplementType.Holiday]: 0,
  [PaySupplementType.Night]: 0,
  [PaySupplementType.Saturday]: 0,
  [PaySupplementType.Sunday]: 0,
};

const supplementNames: Record<PaySupplementType, string> = {
  [PaySupplementType.Holiday]: 'Svátky',
  [PaySupplementType.Night]: 'Noční',
  [PaySupplementType.Saturday]: 'Sobota',
  [PaySupplementType.Sunday]: 'Neděle',
};

const salaryTypeNames: Record<CzechSalaryType, string> = {
  [CzechSalaryType.DPP]: 'DPP',
  [CzechSalaryType.DPC_HPP]: 'DPČ+HPP',
};

const emptyPayout = {
  types: [] as CzechSalaryType[],
  paidAmount: 0,
  processingAmount: 0,
  payableAmount: 0,
  blockedAmount: 0,
  maxPayableAmount: null as number | null,
  foreclosureType: null as SalaryAdvanceRestrictionStatus | null,
};

const combinePayouts = (payouts: PayoutInfoData[]) => payouts.reduce((combined, payout) => ({
  types: combined.types.concat([payout.type]),
  paidAmount: combined.paidAmount + payout.paidAmount,
  processingAmount: combined.processingAmount + payout.processingAmount,
  payableAmount: combined.payableAmount + payout.payableAmount,
  blockedAmount: combined.blockedAmount + payout.blockedAmount,
  maxPayableAmount: combined.maxPayableAmount ?? payout.maxPayableAmount, // they should never differ though
  foreclosureType: payout.foreclosureType,
}), emptyPayout);

const SalaryCalculation = ({ personId, companyId, from, to, salaryType }: SalaryCalculationProps) => {
  const salaryQuery: Query = {
    companyIds: [companyId],
    from: from.toISOString(),
    to: to.toISOString(),
  };
  if (salaryType) {
    salaryQuery.salaryType = salaryType;
  }
  const { data: salary, isLoading: salaryLoading } = useQuery(
    ['salary-calculation', salaryQuery],
    () => feathersClient
      .service('salary-calculation')
      .get(personId, { query: salaryQuery }),
  );

  const payoutQuery: Query = {
    person_id: personId,
    company_id: companyId,
    date_time: from.toISOString(),
    with_limit: true,
  };
  if (salaryType) {
    payoutQuery.salary_type = salaryType;
  }
  const { data: payouts, isLoading: payoutLoading } = useQuery(
    ['payout-info', payoutQuery],
    () => feathersClient
      .service('payout-info')
      .find({ query: payoutQuery }) as Promise<PayoutInfoData[]>,
  );

  const groupedSupplements = salary?.salary_by_legislation.paySupplements
    .reduce<Record<PaySupplementType, number>>((acc, supplement) => {
    acc[supplement.paySupplement.type] += supplement.totalGrossSalary;
    return acc;
  }, { ...defaultSupplementValues }) ?? defaultSupplementValues;
  const supplementSum = Object.values(groupedSupplements).reduce((acc, val) => acc + val, 0);

  const payout = payouts ? combinePayouts(payouts) : emptyPayout;
  const typeNames = (types: CzechSalaryType[]) => types.map((type) => salaryTypeNames[type]).join(', ') || 'n/a';

  return (
    <Card>
      {(salaryLoading || payoutLoading || !salary || !payouts)
        ? <InlineSpinner />
        : (
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div>
              <h2>Zálohy/mzdy <small>({typeNames(salary.salary_by_calculation.salary_types)})</small></h2>
              <table className="w-auto border-spacing-y-1.5 border-spacing-x-5 border-separate [&_*]:text-right">
                <tbody>
                  <tr>
                    <th>Potvrzený čas</th>
                    <td>
                      {salary.salary_by_calculation.confirmed_time
                        + salary.salary_by_calculation.confirmed_overtime}
                      h
                    </td>
                  </tr>
                  <tr>
                    <th>Hrubá odměna</th>
                    <td>{priceString(salary.salary_by_calculation.gross_salary)}</td>
                  </tr>
                  <tr>
                    <th>Zálohy</th>
                    <td>{priceString(salary.salary_by_calculation.net_salary)}</td>
                  </tr>
                  <tr>
                    <th>Zažádáno</th>
                    <td>{priceString(salary.salary_by_calculation.requested)}</td>
                  </tr>
                  <tr>
                    <th>Čeká na zpracování</th>
                    <td>{priceString(salary.salary_by_calculation.pending_processing)}</td>
                  </tr>
                  <tr>
                    <th>Vyplaceno</th>
                    <td>{priceString(salary.salary_by_calculation.paid)}</td>
                  </tr>
                  <tr>
                    <th>Zatím nezažádáno</th>
                    {payout.foreclosureType
                      ? (
                        <td>
                          {payout.payableAmount > 0
                            ? priceString(payout.payableAmount)
                            : priceString(0)}
                        </td>
                      ) : (
                        <td>
                          {salary.salary_by_calculation.pending_request > 0
                            ? priceString(salary.salary_by_calculation.pending_request)
                            : priceString(0)}
                        </td>
                      )}
                  </tr>
                  {payout.foreclosureType
                    ? salary.salary_by_calculation.paid > payout.maxPayableAmount! && (
                      <tr>
                        <th>Přeplaceno na zálohách</th>
                        <td>
                          {priceString(salary.salary_by_calculation.paid - payout.maxPayableAmount!)}
                        </td>
                      </tr>
                    )
                    : salary.salary_by_calculation.paid > salary.salary_by_calculation.net_salary && (
                      <tr>
                        <th>Přeplaceno na zálohách</th>
                        <td>
                          {priceString(salary.salary_by_calculation.paid - salary.salary_by_calculation.net_salary)}
                        </td>
                      </tr>
                    )}
                  {payout.foreclosureType && (
                    <tr>
                      <th>Zablokováno exekucí</th>
                      <td>
                        {priceString(payout.blockedAmount)}&nbsp;
                        (limit {priceString(payout.maxPayableAmount!)})
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div>
              <h2>Tymber výpis <small>({typeNames(salary.salary_by_legislation.types)})</small></h2>
              <table className="w-auto border-spacing-y-1.5 border-spacing-x-5 border-separate [&_*]:text-right">
                <tbody>
                  <tr>
                    <th className="ty-table-narrow-col">
                      Hrubá odměna
                    </th>
                    <td className="whitespace-nowrap min-w-fit">
                      {priceString(salary.salary_by_legislation.totalGrossSalary)}
                    </td>
                  </tr>
                  {supplementSum > 0 && (
                    <>
                      <tr>
                        <th className="ty-table-narrow-col">
                          Základní
                        </th>
                        <td className="whitespace-nowrap min-w-fit">
                          {priceString(salary.salary_by_calculation.gross_salary - supplementSum)}
                        </td>
                      </tr>
                      <tr>
                        <th className="ty-table-narrow-col">
                          Příplatky
                        </th>
                        <td className="whitespace-nowrap min-w-fit">
                          {priceString(supplementSum)}
                        </td>
                      </tr>
                      {Object.entries(groupedSupplements).map(([type, amount]) => (
                        amount > 0 && (
                          <tr key={type} className="text-secondary-200">
                            <th className="ty-table-narrow-col">
                              {supplementNames[type as PaySupplementType]}
                            </th>
                            <td className="whitespace-nowrap min-w-fit">
                              {priceString(amount)}
                            </td>
                          </tr>
                        )
                      ))}
                    </>
                  )}
                  {salary.salary_by_legislation.taxes.map((tax) => (
                    <Fragment key={tax.name}>
                      <tr>
                        <th className="ty-table-narrow-col">
                          {tax.name}
                        </th>
                        <td className="whitespace-nowrap min-w-fit">
                          {priceString(revertNumber(tax.finalAmount))}
                        </td>
                      </tr>
                      <tr className="text-secondary-200">
                        <th className="ty-table-narrow-col">
                          Základní
                        </th>
                        <td className="whitespace-nowrap min-w-fit">
                          {priceString(revertNumber(tax.baseAmount))}
                        </td>
                      </tr>
                      {salary.salary_by_legislation.taxAdjustments
                        .filter((taxAdjustment) => taxAdjustment.appliedToTaxType === tax.type)
                        .map((taxAdjustment) => (
                          <tr className="text-secondary-200" key={taxAdjustment.name}>
                            <th className="ty-table-narrow-col">
                              {taxAdjustment.name}
                            </th>
                            <td className="whitespace-nowrap min-w-fit">
                              {priceString(revertNumber(taxAdjustment.totalAmount))}
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ))}
                  <tr>
                    <th className="ty-table-narrow-col">
                      Čistá odměna
                    </th>
                    <td className="whitespace-nowrap min-w-fit">
                      {priceString(salary.salary_by_legislation.totalNetSalary)}
                    </td>
                  </tr>
                  {salary.salary_by_legislation.totalNetSalary !== salary.salary_by_legislation.totalNetSalaryAdvance
                  && (
                    <>
                      <tr>
                        <th className="ty-table-narrow-col">
                          Předběžná alokace odvodů
                        </th>
                        <td className="whitedspace-nowrap min-w-fit">
                          {priceString(salary.salary_by_legislation.totalNetSalaryAdvance - salary.salary_by_legislation.totalNetSalary)}
                        </td>
                      </tr>
                      <tr>
                        <th className="ty-table-narrow-col">
                          Dostupná záloha
                        </th>
                        <td className="whitedspace-nowrap min-w-fit">
                          {priceString(salary.salary_by_legislation.totalNetSalaryAdvance)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </Card>
  );
};

export default SalaryCalculation;
