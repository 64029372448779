import { OutputData } from '@editorjs/editorjs';
import { CountryCodes, EU_EEA_SWISS_COUNTRY_CODES } from '@tymbe/schema/enums';

import { CountryCodesAndNames } from '../components/inputs/TySelectCountry/TySelectCountry.utils';

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export const isOutputData = (val: unknown): val is OutputData =>
  val !== null
  && val !== undefined
  && typeof val === 'object' && 'version' in val && 'time' in val && 'blocks' in val && Array.isArray(val.blocks);

export const euEeaSwitzCountryCodeNames = () => {
  const arrayOfAllCountries = CountryCodesAndNames();
  return Object.fromEntries(Object.entries(arrayOfAllCountries).filter(
    ([code]) => EU_EEA_SWISS_COUNTRY_CODES.includes(code as CountryCodes),
  ));
};

export interface CountryOptionType {
  label: string,
  value: CountryCodes,
}

export const enum ShiftState {
  PAYMENT_TRANSACTION,
  PAYMENT_REQUEST,
  SURVEY,
  ATTENDANCE_OK,
  ATTENDANCE_CANCEL,
  ATTENDANCE_BREACH_OF_DISCIPLINE,
  ATTENDANCE_ADMIN_EXCUSED_ABSENCE,
  ATTENDANCE_EXCUSED_ABSENCE,
  ATTENDANCE_UNEXCUSED_ABSENCE,
  SHIFT_HAPPENED,
  APPLICATION_CONFIRMED,
  APPLICATION_REJECTED,
  APPLICATION_CANCELED_EARLY,
  APPLICATION_CANCELED,
  APPLICATION_CANCELED_LATE,
  APPLICATION_SYSTEM_CANCELED,
  INVITATION_EXPIRED,
  INVITATION,
  DEFAULT,
}
