import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import ActivateUserForm, { ActivateUserFormValues } from './components/ActivateUserForm';
import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import Card from '../../../components/Layout/Card';
import LocaleSwitcher from '../../../components/LocaleSwitcher';
import Wrapper from '../../../components/wrapper';

const ActivateCompanyAccount = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const intl = useIntl();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();

  const { data: activateableUser } = useQuery(
    ['activateable', token],
    async () => {
      if (!token) return Promise.reject();
      const result = await feathersClient.service('auth-management').create({
        action: 'getUserByToken',
        token,
      });
      return result;
    },
  );

  const onSubmit = async ({ values }: FormState<ActivateUserFormValues>) => {
    setFormDisabled(true);

    try {
      await feathersClient.service('auth-management').create({
        action: 'verifySignup',
        token,
        password: values.password,
      });
      SuccessAlert(intl.formatMessage({ defaultMessage: 'Váš účet byl úspešne aktivován.' }));
      await feathersClient.authenticate({
        strategy: 'local',
        username: activateableUser.username,
        password: values.password,
      });
      history('/shift');
    } catch {
      ErrorAlert(intl.formatMessage({ defaultMessage: 'Váš účet se nepodařilo aktivovat.' }));
    }

    setFormDisabled(false);
  };

  return (
    <Wrapper padding="0px" margin="0px 22px 18px 31px">
      {!activateableUser
        ? (
          <>
            <h2>{intl.formatMessage({ defaultMessage: 'Tenhle účet sme jsme již aktivovali!' })}</h2>
            <Link to="/signin">
              {intl.formatMessage({ defaultMessage: 'Přihlásit se' })}
            </Link>
          </>
        )
        : (
          <>
            <h1>{intl.formatMessage({ defaultMessage: 'Vítej v aplikaci Tymbe' })}</h1>
            <h2>{intl.formatMessage({ defaultMessage: 'Před prvním přihlášením si nastav nové heslo' })}</h2>
            <Card className="justify-center align-middle">
              <Form<ActivateUserFormValues> name="person-form" onSubmit={onSubmit} className="justify-center">
                <ActivateUserForm user={activateableUser} />
                <SubmitButton
                  disabled={formDisabled}
                  style={{ marginTop: '18px' }}
                  buttontext={intl.formatMessage({ defaultMessage: 'Uložit' })}
                />
              </Form>
            </Card>
          </>
        )}
      <LocaleSwitcher />
    </Wrapper>

  );
};

export default ActivateCompanyAccount;
