import { Form, FormState } from 'informed';
import moment from 'moment';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { SubmitButton } from '../../../../components/buttons';
import TyCheckbox from '../../../../components/inputs/TyCheckbox';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { PersonBanData } from '../../../../types/TymbeApi';
import UserBanForm from '../components/UserBanForm';

interface PersonBanDataForm extends PersonBanData {
  freeManShifts: boolean;
}

const CreatePersonBan = () => {
  const { personId } = useParams();
  const { person } = useUser();
  const navigate = useNavigate();

  const [formDisabled, setFormDisabled] = useState(false);

  if (!personId) {
    return <Navigate to="/users" />;
  }

  const onSubmit = async ({ values }: FormState<PersonBanDataForm>) => {
    const personBanData = {
      note: values.note,
      start_time: moment(values.start_time || undefined).startOf('day').toISOString(),
      end_time: moment(values.end_time || null).endOf('day').toISOString(),
      person_id: Number(personId),
      created_by: Number(person.id),
    } as PersonBanData;
    setFormDisabled(true);
    try {
      await feathersClient.service('person-ban').create(
        personBanData,
        { query: { $cancelApplications: true, $freeManShifts: values.freeManShifts } },
      );
      SuccessAlert('Nový ban byl úspěšně udělen');
    } catch (err) {
      ErrorAlert('Při pokusu o udělení banu nastala chyba');
      setFormDisabled(false);
      return navigate(`/user/${personId}/bans`);
    }

    setFormDisabled(false);
    return navigate(`/user/${personId}/bans`);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Nový ban </h1>
        <Card>
          <Form<PersonBanDataForm>
            className="flex flex-col gap-5 w-1/2"
            name="company-form"
            onSubmit={onSubmit}
            validateOn="change-submit"
          >
            <UserBanForm />
            <TyCheckbox
              id="freeManShifts"
              name="freeManShifts"
              label="Nahradit uvolněná místa volnou kapacitou"
              defaultValue
            />
            <SubmitButton disabled={formDisabled} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default CreatePersonBan;
