import { Roles } from '@tymbe/schema/enums';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AdminRoute from './adminRoute';
import CompanyAdminRoute from './companyAdminRoute';
import CompanyRoute from './companyRoute';
import ProtectedRoute from './protectedRoute';
import Protect from '../apiClient/Protect';
import TemporaryAllocationList from '../components/tabs/TemporaryAllocationList';
import PageWrapper from '../components/wrapper/PageWrapper';
import NotFound from '../layouts/pages/NotFound';
import Profile from '../pages/account/index.page';
import AdminShiftsPage from '../pages/admin-shift/index.page';
import AdminAdministrationAbsencePage from '../pages/administration/absence';
import { CanceledLateTab } from '../pages/administration/absence/CanceledLate.tab';
import { ProcessingAbsencesTab } from '../pages/administration/absence/ProcessingAbsences.tab';
import { RequestedClarificationsTab } from '../pages/administration/absence/RequestedClarifications.tab';
import { ResolvedAbsencesTab } from '../pages/administration/absence/ResolvedAbsences.tab';
import { UnexcusedAbsencesTab } from '../pages/administration/absence/UnexcusedAbsences.tab';
import DocumentsPage from '../pages/administration/documents/DocumentsPage';
import AdminForeclosuresPage from '../pages/administration/foreclosures';
import EditInternalUser from '../pages/administration/internal-users/@userId/index.page';
import CreateInternalUser from '../pages/administration/internal-users/create/index.page';
import InternalUsersPage from '../pages/administration/internal-users/index.page';
import EmailDetail from '../pages/administration/mail/@mailId/index.page';
import FailedEmails from '../pages/administration/mail/failed/index.page';
import MailPage from '../pages/administration/mail/index.page';
import QueuedEmails from '../pages/administration/mail/queued/index.page';
import SentEmails from '../pages/administration/mail/sent/index.page';
import MpsvTablePage from '../pages/administration/mpsv/index.page';
import AdminAdministrationPaymentConfirmationPage from '../pages/administration/payment/index.page';
import AdminAdministrationPositionsPage from '../pages/administration/position';
import AdminAdministrationReportingPage from '../pages/administration/reporting';
import AdminAdministrationMankaPage from '../pages/administration/shortages';
import UtilityPage from '../pages/administration/utility/index.page';
import AttendancePage from '../pages/attendance/index.page';
import SignUpConfirmation from '../pages/auth/email-sent/SignUpConfirmation';
import Signin from '../pages/auth/signin';
import Signup from '../pages/auth/signup';
import AdminTymberBlocksPage from '../pages/blocks/index.page';
import ShiftAttendanceModal from '../pages/calendar/components/ShiftAttendanceModal';
import ShiftDetailModal from '../pages/calendar/components/ShiftDetailModal';
import CalendarPage from '../pages/calendar/index.page';
import ShiftAttendancePage from '../pages/calendar/shiftAttendance.page';
import ShiftDetailPage from '../pages/calendar/shiftDetail.page';
import EditBranchoffice from '../pages/company/@companyId/branchoffice/@branchofficeId/index.page';
import CreateBranchoffice from '../pages/company/@companyId/branchoffice/create/index.page';
import BranchofficeList from '../pages/company/@companyId/branchoffice/index.pages';
import EditUser from '../pages/company/@companyId/create-company-user/@loginId/index.page';
import CreateUser from '../pages/company/@companyId/create-company-user/index.page';
import EditDepartment from '../pages/company/@companyId/department/@departmentId/index.page';
import CreateDepartment from '../pages/company/@companyId/department/create/index.page';
import DepartmentList from '../pages/company/@companyId/department/index.page';
import EditDocumentTypePage from '../pages/company/@companyId/document-type/@documentTypeId/editor/index.page';
import DocumentTypeFormPage from '../pages/company/@companyId/document-type/@documentTypeId/index.page';
import DocumentTypesList from '../pages/company/@companyId/document-type/index.page';
import EditCompany from '../pages/company/@companyId/edit/index.page';
import CompanyPage from '../pages/company/@companyId/index.page';
import ShiftTemplate from '../pages/company/@companyId/shift-template/@shiftTemplateId/index.page';
import ShiftTemplateList from '../pages/company/@companyId/shift-template/index.page';
import CompanyUserList from '../pages/company/@companyId/user/index.page';
import CreateCompany from '../pages/company/create/index.page';
import CompanyListPage from '../pages/company/index.page';
import ForgotPassword from '../pages/forgot-password/index.page';
import AdminTymberMonthlyWage from '../pages/monthly-wage/index.page';
import CompanyNameReport from '../pages/name-report/index.page';
import ResetPassword from '../pages/reset-password/index.page';
import SelectedShiftsReport from '../pages/selected-shifts-report/index.page';
import ShiftDetail from '../pages/shift/@shiftId/index.page';
import Shift from '../pages/shift/create/index.page';
import ShiftPage from '../pages/shift/index.page';
import TymberListPage from '../pages/tymber/index.page';
import UserBanList from '../pages/user/@personId/bans/index.page';
import UserBlocks from '../pages/user/@personId/blocks/index.page';
import UserContactList from '../pages/user/@personId/contacts/index.page';
import CreatePersonBan from '../pages/user/@personId/create-ban/index.page';
import UserCredits from '../pages/user/@personId/credits/index.page';
import UserDocumentsList from '../pages/user/@personId/documents/index.page';
import EditUserDetail from '../pages/user/@personId/edit/index.page';
import EditPersonBan from '../pages/user/@personId/edit-ban/@banId/index.page';
import UserPage from '../pages/user/@personId/index.page';
import NotesContainer from '../pages/user/@personId/notes/index.page';
import ShiftsContainer from '../pages/user/@personId/shifts/index.page';
import Utility from '../pages/user/@personId/utility/index.page';
import UserWallet from '../pages/user/@personId/wallet/index.page';
import ActivateAccount from '../pages/verify/activate-account/index.page';
import ActivateCompanyAccount from '../pages/verify/activate-company-account/index.page';

const Paths = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  if (background) {
    background.state = { ...background.state, isBackground: true };
  }

  return (
    <>
      <Routes location={background || location}>
        {/* PUBLIC */}
        <Route exact path="/" element={<Signin />} />
        {/* <Route exact path="/signup" element={<Signup />} /> */}
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/verify/email-sent" element={<SignUpConfirmation />} />
        {/* NEW ROUTE STRUCTURE NOT YET IN USE */}
        {/* <Route exact path="/auth/signup" element={<Signup />} />
    <Route exact path="/auth/signin" element={<Signin />} />
    <Route exact path="/auth/finish-registration" element={<Signin />} />
    <Route exact path="/auth/email-sent" element={<SignUpConfirmation />} /> */}
        {/* COMPANY */}
        <Route exact path="/account" element={<CompanyRoute><Profile /></CompanyRoute>} />
        <Route exact path="/shift" element={<CompanyRoute><ShiftPage /></CompanyRoute>} />
        <Route exact path="/shift/create" element={<CompanyRoute><Shift /></CompanyRoute>} />
        <Route exact path="/shift/:shiftId" element={<CompanyRoute><ShiftDetail /></CompanyRoute>} />
        <Route exact path="/attendance" element={<CompanyRoute><AttendancePage /></CompanyRoute>} />
        <Route
          exact
          path="/company/:companyId/branchoffice/:branchofficeId"
          element={<CompanyRoute><EditBranchoffice /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/department/:departmentId"
          element={<CompanyRoute><EditDepartment /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/create-company-user/:loginId"
          element={<CompanyRoute><EditUser /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/document-type/create"
          element={<AdminRoute><DocumentTypeFormPage /></AdminRoute>}
        />
        <Route
          path="/company/:companyId/document-type/:documentTypeId"
          element={<AdminRoute><DocumentTypeFormPage /></AdminRoute>}
        />
        <Route
          path="/company/:companyId/document-type/:documentTypeId/editor"
          element={<AdminRoute><EditDocumentTypePage /></AdminRoute>}
        />
        <Route
          exact
          path="/company/:companyId/branchoffice/create"
          element={<CompanyRoute><CreateBranchoffice /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/department/create"
          element={<CompanyRoute><CreateDepartment /></CompanyRoute>}
        />
        <Route path="/company/:companyId/create-company-user" element={<CompanyRoute><CreateUser /></CompanyRoute>} />
        <Route
          path="/company/:companyId/shift-template/create"
          exact
          element={<CompanyRoute><ShiftTemplate /></CompanyRoute>}
        />
        <Route
          path="/company/:companyId/shift-template/:shiftTemplateId"
          element={<CompanyRoute><ShiftTemplate /></CompanyRoute>}
        />
        <Route exact path="/company/:companyId" element={<CompanyRoute><CompanyPage /></CompanyRoute>}>
          <Route index element={<Navigate to="branchoffice" replace />} />
          <Route path="branchoffice" element={<BranchofficeList />} />
          <Route path="department" element={<DepartmentList />} />
          <Route path="/company/:companyId/user" element={<CompanyUserList />} />
          <Route path="shift-template" element={<ShiftTemplateList />} />
          <Route path="document-type" element={<AdminRoute><DocumentTypesList /></AdminRoute>} />
          <Route path="temporary-allocation-list" element={<TemporaryAllocationList />} />
        </Route>
        <Route exact path="/user/:personId" element={<CompanyRoute><UserPage /></CompanyRoute>}>
          <Route index element={<Navigate to="shifts" replace />} />
          <Route path="shifts" element={<ShiftsContainer />} />
          {/* Maybe in the future */}
          {/* <Route path="payouts" element={<div>Vyplaty</div>} /> */}
          {/* <Route path="attendances" element={<div>Potvrzeni</div>} /> */}
          {/* <Route path="logs" element={<div>Log</div>} /> */}
          <Route path="bans" element={<AdminRoute><UserBanList /></AdminRoute>} />
          <Route path="documents" element={<UserDocumentsList />} />
          <Route path="contacts" element={<UserContactList />} />
          <Route path="notes" element={<NotesContainer />} />
          <Route path="wallet" element={<AdminRoute><UserWallet /></AdminRoute>} />
          <Route path="credits" element={<AdminRoute><UserCredits /></AdminRoute>} />
          <Route path="blocks" element={<CompanyAdminRoute><UserBlocks /></CompanyAdminRoute>} />
          <Route path="utility" element={<Utility />} />
        </Route>
        <Route path="/user/:personId/create-ban" element={<CompanyRoute><CreatePersonBan /></CompanyRoute>} />
        <Route path="/user/:personId/edit-ban/:banId" element={<CompanyRoute><EditPersonBan /></CompanyRoute>} />
        <Route path="/user/:personId/edit" element={<CompanyRoute><EditUserDetail /></CompanyRoute>} />
        <Route exact path="/name-report" element={<CompanyRoute><CompanyNameReport /></CompanyRoute>} />
        <Route exact path="/selected-shifts-report" element={<CompanyRoute><SelectedShiftsReport /></CompanyRoute>} />
        <Route exact path="/tymber" element={<CompanyRoute><TymberListPage /></CompanyRoute>} />
        {/* ADMIN */}
        {/* TYMBER */}
        <Route exact path="/monthly-wage" element={<AdminRoute><AdminTymberMonthlyWage /></AdminRoute>} />
        <Route exact path="/blocks" element={<CompanyRoute><AdminTymberBlocksPage /></CompanyRoute>} />
        {/* SMENY */}
        <Route exact path="/admin-shift" element={<AdminRoute><AdminShiftsPage /></AdminRoute>} />
        {/* EXEKUCE */}
        <Route exact path="/administration/foreclosures" element={<AdminRoute><AdminForeclosuresPage /></AdminRoute>} />
        {/* ADMINISTRACE */}
        <Route
          exact
          path="/administration/email"
          element={<AdminRoute><MailPage /></AdminRoute>}
        >
          <Route index element={<Navigate to="queued" replace />} />
          <Route path="queued" element={<QueuedEmails />} />
          <Route path="sent" element={<SentEmails />} />
          <Route path="failed" element={<FailedEmails />} />
        </Route>
        <Route
          exact
          path="/administration/email/:mailId"
          element={<Protect auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]}><EmailDetail /></Protect>}
        />
        <Route
          exact
          path="/administration/shortages"
          element={<AdminRoute><AdminAdministrationMankaPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/payment"
          element={<AdminRoute><AdminAdministrationPaymentConfirmationPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/position"
          element={<AdminRoute><AdminAdministrationPositionsPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/reporting"
          element={<AdminRoute><AdminAdministrationReportingPage /></AdminRoute>}
        />
        <Route
          exact
          path="/administration/utility"
          element={<AdminRoute><PageWrapper /></AdminRoute>}
        >
          <Route index element={<UtilityPage />} />
        </Route>
        <Route
          path="/administration/mpsv/*"
          element={<AdminRoute><PageWrapper /></AdminRoute>}
        >
          <Route path="*" element={<MpsvTablePage />} />
        </Route>
        <Route exact path="/admin-administration-documents" element={<AdminRoute><DocumentsPage /></AdminRoute>} />
        <Route
          exact
          path="/administration/internal-users"
          element={<AdminRoute><PageWrapper /></AdminRoute>}
        >
          <Route index element={<InternalUsersPage />} />
        </Route>
        <Route
          path="/administration/internal-users/:userId"
          element={<CompanyRoute><EditInternalUser /></CompanyRoute>}
        />
        <Route
          path="/administration/internal-users/create"
          element={<CompanyRoute><CreateInternalUser /></CompanyRoute>}
        />
        {/* ABSENCE */}
        <Route
          exact
          path="/administration/absence"
          element={<AdminRoute><AdminAdministrationAbsencePage /></AdminRoute>}
        >
          <Route index element={<Navigate to="processing" replace />} />
          <Route path="processing" element={<ProcessingAbsencesTab />} />
          <Route path="requestedClarifications" element={<RequestedClarificationsTab />} />
          <Route path="unexcusedAbsences" element={<UnexcusedAbsencesTab />} />
          <Route path="canceledLate" element={<CanceledLateTab />} />
          <Route path="resolved" element={<ResolvedAbsencesTab />} />
        </Route>
        {/* FIRMA */}
        <Route exact path="/company" element={<AdminRoute><CompanyListPage /></AdminRoute>} />
        {/* SETTING */}
        <Route exact path="/company/create" element={<AdminRoute><CreateCompany /></AdminRoute>} />
        <Route exact path="/company/:companyId/edit" element={<AdminRoute><EditCompany /></AdminRoute>} />
        {/* Public routes */}
        <Route exact path="/verify/activate-account" element={<ActivateAccount />} />
        <Route exact path="/verify/activate-company-account" element={<ActivateCompanyAccount />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        {/* EXAMPLES / SANDBOX */}
        <Route exact path="/not-found" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} />

        {/* CALENDAR */}
        <Route exact path="/calendar" element={<AdminRoute><PageWrapper /></AdminRoute>}>
          <Route index element={<CalendarPage />} />
          {/* <Route path="dev/*" element={<CalendarDevPage />} /> */}
        </Route>
        <Route element={<AdminRoute><PageWrapper /></AdminRoute>}>
          <Route path="/shiftDetail/:shiftId" element={<ShiftDetailPage />} />
          <Route path="/shiftAttendance/:shiftId" element={<ShiftAttendancePage />} />
        </Route>
      </Routes>
      {background && (
        <Routes>
          <Route path="/shiftDetail/:shiftId" element={<ShiftDetailModal />} />
          <Route path="/shiftAttendace/:shiftId" element={<ShiftAttendanceModal />} />
        </Routes>
      )}
    </>
  );
};

export default Paths;
