import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert } from '../../../../../components/alerts';
import { SecondaryButton } from '../../../../../components/buttons';
import AlertModal from '../../../../../components/modals/AlertModal';
import { RequestBody } from '../../../../../types/ReactQuery';
import { ApplicationData, ApplicationState } from '../../../../../types/TymbeApi';
import { DeepPartial } from '../../../../../types/utils';

export interface EditApplicationFormData {
  state:{ label: string, value: ApplicationState } | null;
}

export interface EditApplicationStateProps {
  applicationId: number;
  manShiftId: number;
  onSuccess?: () => void;
}

const EditApplicationState = ({ applicationId, manShiftId, onSuccess }: EditApplicationStateProps) => {
  const [showFreeUpManShiftModal, setShowFreeUpManShiftModal] = useState(false);

  const { mutateAsync: patchApplication } = useMutation(
    ['patchApplication', applicationId],
    async (req: RequestBody<DeepPartial<ApplicationData>>): Promise<void> => {
      await feathersClient.service('application').patch(req.id, req.body);
    },
    {
      onSuccess,
    },
  );

  const cancelApplication = async () => {
    try {
      await patchApplication({ id: applicationId, body: { state: ApplicationState.SYSTEM_CANCELED } });
    } catch {
      ErrorAlert('Při úpravě přihlášky došlo k chybě');
    }

    setShowFreeUpManShiftModal(false);
  };

  const cancelApplicationAndRemoveManshift = async () => {
    try {
      await patchApplication({ id: applicationId, body: { state: ApplicationState.SYSTEM_CANCELED } });
      await feathersClient.service('man-shift').remove(manShiftId);
    } catch {
      ErrorAlert('Při úpravě přihlášky došlo k chybě');
    }

    setShowFreeUpManShiftModal(false);
  };

  return (
    <>
      <div className="p-2">
        <SecondaryButton onClick={() => setShowFreeUpManShiftModal(true)}>
          Zrušit přihlášku na směnu
        </SecondaryButton>
      </div>
      <AlertModal
        title="Nahradit směny"
        message="Chcete nahradit uvolněná místa volnou kapacitou?"
        showModal={showFreeUpManShiftModal}
        yesButtonText="Nahradit"
        noButtonText="Zavřít bez nahrazení"
        showCancel={false}
        onClose={() => setShowFreeUpManShiftModal(false)}
        onYes={cancelApplication}
        onNo={cancelApplicationAndRemoveManshift}
      />
    </>
  );
};

export default EditApplicationState;
