// ignore 'any' for this file until refactor
/* eslint-disable @typescript-eslint/no-explicit-any */
import DislikeTwoTone from '@ant-design/icons/DislikeTwoTone';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import LikeTwoTone from '@ant-design/icons/LikeTwoTone';
import { Query } from '@feathersjs/feathers';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { AttendanceResolution, BlockReasons } from '@tymbe/schema/enums';
import { Affix, Card, Col, InputNumber, Row, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import objection from 'objection';
import Tooltip from 'rc-tooltip';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './index.module.css';
import {
  removePaymentRequestThunk,
  removeSurveyAnswerThunk,
} from './utils/applicationSlice';
import {
  actions,
  loadAttendancesThunk,
  removeAttendanceThunk,
  selectAttendanceLoading as selectApprovedAttendanceLoading,
  selectData,
} from './utils/attendanceSlice';
import { useUser } from '../../apiClient/ApiContext';
import Protect from '../../apiClient/Protect';
import AdminTable from '../../components/AdminTable';
import { ErrorAlert, SuccessAlert } from '../../components/alerts';
import { SecondaryButton } from '../../components/buttons';
import { ExclamationCircleIcon } from '../../components/icons';
import { TableRangePicker } from '../../components/inputs';
import SimpleModal from '../../components/modals';
import SearchBox from '../../components/search/SearchBox';
import { ApprovedAttendanceExpandedRow } from '../../components/tableExtendedRows';
import { ThumbsRating } from '../../components/tableRenders';
import HoverRowEditDeleteButtons from '../../components/tableRenders/HoverRowEditDeleteButtons';
import UnapprovedAttendanceTable from '../../components/tables/UnapprovedAttendanceTable';
import { PageTitle } from '../../components/texts';
import TyAdvancedSearchApplication from '../../components/TyAdvancedSearch/Application';
import apiTransformationsApplication from '../../components/TyAdvancedSearch/Application/apiTransformationsApplication';
import TyAdvancedSearchAttendance from '../../components/TyAdvancedSearch/Attendance';
import apiTransformationsAttendance from '../../components/TyAdvancedSearch/Attendance/apiTransformationsAttendance';
import TyInteractiveLabels from '../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../components/TyAdvancedSearch/Libs/enums';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import { createAttendanceThunk, selectAdminError, selectAdminLoading } from '../../data/store/slices/admin';
import {
  loadApplicationThunk,
  loadAttendanceForExportThunk,
  selectAttendanceLoading,
  selectAttendancePagination,
  selectAttendances,
} from './utils/slice';
import { getRolesWhichCanDeleteAttendance } from '../../utils/attendance';
import { blockUserInCompany } from '../../utils/bans';
import { alert, dangerColor, darkBlue, primaryColor } from '../../utils/colors';
import {
  BAD_JOB_EVALUATION_SCORE,
  DEFAULT_JOB_EVALUATION_SCORE,
  GOOD_JOB_EVALUATION_SCORE,
} from '../../utils/constants';
import { ApplicationState, AttendanceResolutionDisplay, Roles } from '../../utils/enums';
import exportDataToXLSX, { mapConfirmedAttendanceForExport } from '../../utils/exports/xlsx/xlsx';
import Spinner from '../../utils/spinner';
import { calculateWorkTime } from '../../utils/time';
import AlertModal from '@tymbe/ty-components/modals/AlertModal';

const DEFAULT_UNAPPROVED_ATTENDANCE_SORT = { 'shift.start_time': 1, 'person.last_name': 1 };
const DEFAULT_APPROVED_ATTENDANCE_SORT = { 'attendance.created_at': -1 };
const TAB_KEYS = {
  UNAPPROVED_ATTENDANCE: 'unapproved',
  APPROVED_ATTENDANCE: 'approved',
};

const limitApprovedColumnsForNonAdmin = (cols: { key?: string }[], isAdmin: boolean) => {
  if (isAdmin) return cols;
  return cols.filter((column) => column?.key !== 'company_name');
};

const AttendancePage = () => {
  const dispatch = useDispatch();
  const adminError = useSelector(selectAdminError);
  const user = useUser();
  const isSuperAdmin = useUser().hasRoles([Roles.SUPER_ADMIN]);
  const isAdmin = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);
  const canSeeUnapprovedAttendance = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
    Roles.COMPANY,
    Roles.BRANCHOFFICE_MANAGER,
  ]);
  const canConfirmOnlyAbsence = !isAdmin;
  const [showSearchApprovedAttendance, setShowSearchApprovedAttendance] = useState(false);
  const [showSearchAttendance, setShowSearchAttendance] = useState(false);
  const [showFreeUpManShiftModal, setShowFreeUpManShiftModal] = useState(false);

  const loading = useSelector(selectAdminLoading);

  const selectedAttendanceData = useSelector(
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ views }) => selectAttendances(views.companyAttendances),
  );

  const selectedUnapprovedAttendancePagination = useSelector(
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ views }) => selectAttendancePagination(views.companyAttendances),
  );

  const attendanceLoading = useSelector(
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ views }) => selectAttendanceLoading(views.companyAttendances),
  );

  const [dateArray] = useState(null);
  const [timeArray] = useState(null);
  const [searchParamsApprovedAttendance, setSearchParamsApprovedAttendance] = useState<any>();
  const [searchParamsAttendance, setSearchParamsAttendance] = useState<any>();

  const [showConfirmPanel, setShowConfirmPanel] = useState(false);

  const [updateComponent, setUpdateComponent] = useState(false);

  const [unapprovedCurrentPage, setCurentUnapprovedPage] = useState(1);
  const [unapprovedPageSize, setUnapprovedPageSize] = useState(10);
  const [unapprovedAttendancedSort, setUnapprovedAttendanceSort] = useState<Query>(DEFAULT_UNAPPROVED_ATTENDANCE_SORT);
  // wrong infer from js file, redux
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const approvedAttendanceData = useSelector((state) => selectData(state.views.attendances)) || [];

  const approvedAttendanceLoading = useSelector(
  // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (state) => selectApprovedAttendanceLoading(state.views.attendances),
  ) || false;
  const [approvedCurrentPage, setApprovedCurrentPage] = useState(1);
  const [approvedPageSize, setApprovedPageSize] = useState(10);
  const [approvedAttendancedSort, setApprovedAttendancedSort] = useState<Query>(DEFAULT_APPROVED_ATTENDANCE_SORT);
  const [approvedDateRange, setApprovedDateRange] = useState<Moment[]>();

  // New ban implementation
  const [blockData, setBlockData] = useState<any>({});
  const [mankoData, setMankoData] = useState<any>({});
  const [absenceData, setAbsenceData] = useState<any>({});
  const [confirmationTimes, setConfirmationTimes] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowsForApi, setSelectedRowsForApi] = useState<any>([]);
  const [openTabKey, setOpenTabKey] = useState(
    canSeeUnapprovedAttendance ? TAB_KEYS.UNAPPROVED_ATTENDANCE : TAB_KEYS.APPROVED_ATTENDANCE,
  );
  const [formDisabled, setFormDisabled] = useState(true);
  const [advanceSearchState, setAdvanceSearchState] = useState<any>({});
  const [advanceSearchApplication, setAdvanceSearchApplication] = useState<any>();

  const onShowFreeUpManShiftModal = () => {
    setShowFreeUpManShiftModal(true);
  };

  const generateBlockRequestData = (block: object) => Object.entries(block)
    .map(([person_id, companyData]) => Object.entries(companyData)
      .filter((s: any) => s[1].blocked)
      .map(([company_id, blockInfo]: any) => ({
        person_id: Number(person_id),
        company_id: Number(company_id),
        branchoffice_id: Number(blockInfo?.branchoffice_id),
        reason: blockInfo?.reason,
        severity: Number(blockInfo?.severity),
        note: blockInfo?.text?.trim() || null,
      })))
    .flat(2);

  const onHandleConfirmAttendance = async (final = false, freeManShift = false) => {
    if (blockData && Object.keys(blockData).length > 0 && !final) {
      const blockReqData = generateBlockRequestData(blockData);
      if (blockReqData.some((b) => !b.reason)) {
        ErrorAlert('Je potřeba vyplnit důvod blokace!');
        return;
      }
      if (blockReqData.some((b) => b.reason === BlockReasons.OTHER && !b.note)) {
        ErrorAlert('V případě důvodu "Jiné" je potřeba vyplnit poznámku blokace!');
        return;
      }
    }

    const data = selectedRowsForApi.map((row: AttendanceData) => {
      const newRow = { ...row };

      if (newRow.confirmed_time && newRow.confirmed_time !== 0) {
        newRow.confirmed_time = Number(Number(newRow.confirmed_time).toFixed(2));
      }

      newRow.resolution = newRow.resolution || AttendanceResolution.OK;
      if (newRow.confirmed_time !== 0 && newRow.resolution === AttendanceResolution.OK) {
        newRow.job_evaluation = Number(newRow.job_evaluation);
        newRow.job_evaluation = Number.isNaN(newRow.job_evaluation)
          ? DEFAULT_JOB_EVALUATION_SCORE
          : newRow.job_evaluation;
        return newRow;
      }
      newRow.job_evaluation = null;
      return newRow;
    });

    // Map liability (`paymentLiability` & `creditTransaction`) data to the query
    Object.keys(mankoData).forEach((person_id: string) => {
      const manko = mankoData[person_id];
      // iterate over already prepared attendance request query data
      data.forEach((_: any, key: number) => {
        // get application_id as it works as mapping key between those two objects
        const app_id = data[key].application_id;
        if (manko[app_id]) {
          const { mankoValue, mankoReason, setManko } = manko[app_id];
          if (!setManko) return;
          // add manko request query data to the correct attendance request
          data[key] = {
            ...data[key],
            personLiability: {
              person_id: Number(person_id),
              description: mankoReason,
              amount: Number.isNaN((Math.abs(mankoValue))) ? 0 : (Math.abs(mankoValue)),
              creditTransaction: {
                person_id: Number(person_id),
                amount: Number.isNaN(-(Math.abs(mankoValue))) ? 0 : -(Math.abs(mankoValue)),
              },
            },
          };
        }
      });
    });

    if (Object.keys(blockData).length > 0) {
      const blockRequests = generateBlockRequestData(blockData);
      await Promise.all(
        blockRequests.map(
          async (block) =>
            blockUserInCompany(
              block.person_id,
              block.company_id,
              block.reason,
              block.severity,
              block.branchoffice_id,
              block.note,
              freeManShift,
            ),
        ),
      );
    }
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await dispatch(createAttendanceThunk(data));
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!response.error) {
      SuccessAlert('Docházka potvrzena');
    }

    setSelectedRows([]);
    setSelectedRowsForApi([]);
    setAbsenceData([]);
    setBlockData({});
    setMankoData({});
    setUpdateComponent(!updateComponent);
  };

  const onUnapprovedPaginationChange = (newPage: number, newPageSize: number) => {
    setUnapprovedPageSize(newPageSize);
    setCurentUnapprovedPage(newPage);
  };

  const onApprovedPaginationChange = (newPage: number, newPageSize: number) => {
    setApprovedPageSize(newPageSize);
    setApprovedCurrentPage(newPage);
  };

  const onParamsSearch = (
    value: string,
    setFunction?: (arg0: any) => any,
  ) => {
    setApprovedCurrentPage(1);
    setCurentUnapprovedPage(1);
    if (!setFunction) return undefined;
    if (!value) return setFunction('');
    const query = !Number.isNaN(+value)
      ? { 'application.person_id': +value }
      : { $modify: { quickFilter: value.split(' ') } };

    return setFunction(query);
  };

  const loadAttendanceData = useCallback((err, params) => {
    // Load "Potvrzená docházka" API request
    dispatch(
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      loadAttendancesThunk({
        query: {
          $eager: '[creditTransaction, paymentAuthorized.paymentRequest.paymentTransaction, confirmedBy,'
            + 'application.[shift.[branchoffice.parent, company], person]]',
          'attendance.deleted_at': { $null: true },
          ...params,
        },
      }),
    );
    setFormDisabled(false);
  }, [dispatch]);

  // TODO: I think this is for future fix - the work time problem
  // const getWorkTime = (startTime, endTime) => {
  //   const hours = getDuration(startTime, endTime, 'hours', true);
  //   return calculateWorkTimeInHours(hours).toFixed(2);
  // };

  const toggleRowSelection = useCallback(
    (record, data?) => {
      let records = record;
      if (!Array.isArray(records)) {
        records = [records];
      }
      let newSelectedRows = selectedRows;
      let newSelectedRowsForApi = selectedRowsForApi;

      // Disabled for the sake of break
      // eslint-disable-next-line no-restricted-syntax
      for (const rec of records) {
        const i = selectedRows.indexOf(rec.id);
        const resolution = data?.resolution ?? absenceData[rec.id]?.resolution;

        // turning off
        if (i >= 0) {
          selectedRows.splice(i, 1);
          setSelectedRows([...selectedRows]);
          selectedRowsForApi.splice(
            selectedRowsForApi.findIndex((s: AttendanceData) => s.application_id === rec.id),
            1,
          );
          setSelectedRowsForApi([...selectedRowsForApi]);

          // turning on
        } else {
          if (
            rec.shift.company.is_readonly
            && canConfirmOnlyAbsence
            && data === true
            && resolution == null
          ) {
            ErrorAlert('U Read-Only firmy lze zadat pouze absenci!');
            break;
          }

          newSelectedRows = [...newSelectedRows, rec.id];

          // Check if custom confirmation time already set otherwise continue with default
          const minutesWorked = confirmationTimes[rec.id]
            ? confirmationTimes[rec.id] * 60
            : calculateWorkTime(rec.shift.start_time, rec.shift.end_time, false, 'minutes');

          const selectedRow = {
            application_id: rec.id,
            confirmed_time: data?.confirmed_time || minutesWorked,
            confirmed_by: user?.person_id,
            resolution: absenceData[rec.id]?.resolution,
            ...data,
          };
          newSelectedRowsForApi = [...newSelectedRowsForApi, selectedRow];
        }
        setSelectedRows([...newSelectedRows]);
        setSelectedRowsForApi([...newSelectedRowsForApi]);
      }
    },
    [selectedRows, selectedRowsForApi, user, confirmationTimes, absenceData, canConfirmOnlyAbsence],
  );

  const toggleManko = (record: any, data: { setManko: boolean; }, toggleRow = true) => {
    const personId = record.person.id;
    const applicationId = record.id;

    if (data && selectedRows.indexOf(record.id) < 0 && toggleRow) {
      toggleRowSelection(record);
    }

    const shiftMankoData = { ...mankoData[personId]?.[applicationId], ...data };
    const userMankoData = { ...mankoData[personId], [applicationId]: shiftMankoData };
    setMankoData({ ...mankoData, [personId]: userMankoData });
  };

  const toggleBlock = (
    record: {
      person: { id: any; };
      shift: { company_id: any; branchoffice: any };
      id: any; },
    data: any,
  ) => {
    const personId = record.person.id;
    const companyId = record.shift.company_id;
    if (data !== undefined) {
      if (selectedRows.indexOf(record.id) < 0) toggleRowSelection(record);
    }

    const companyBlockData = { ...blockData[personId]?.[companyId], ...data };
    const userBlockData = { ...blockData[personId], [companyId]: companyBlockData };

    setBlockData({ ...blockData, [personId]: userBlockData });
  };

  const onSetRating = useCallback(
    (record, data) => {
      // If row not selected, toggle it and assign job eval
      if (selectedRows.indexOf(record.id) < 0) {
        return toggleRowSelection(record, { job_evaluation: Number(data) });
      }
      // Else find it in selected rows and assign job eval
      let newSelectedRow = selectedRowsForApi.find((x: AttendanceData) => x.application_id === record.id);
      newSelectedRow = { ...newSelectedRow, job_evaluation: Number(data) };

      selectedRowsForApi.splice(
        selectedRowsForApi.findIndex((x: AttendanceData) => x.application_id === record.id),
        1,
        newSelectedRow,
      );
      setSelectedRowsForApi([...selectedRowsForApi]);
      return null;
    },
    [selectedRows, selectedRowsForApi, toggleRowSelection],
  );

  const resetConfirmationTime = (record: { id: any; }) => {
    setConfirmationTimes({
      ...confirmationTimes,
      [record.id]: undefined,
    });
  };

  /**
   * loop when setting value to 0 and automatically toggling on absence
   * @function toggleAbsence
   * @param {ApplicationData} record Row data
   * @param {{resolution: AttendanceResolution}} data Object containing resolution property
   * @param {boolean} setTime Default is true. Whether to call confirmed time change. Used to avoid
   */
  const toggleAbsence = (
    record: any,
    data: { resolution: any; },
    setTime = true,
  ) => {
    // Allow toggle behavior.
    // If already selected, click on the same option toggles it off and resets
    // confirmed time to default value
    if (absenceData[record.id]?.resolution === data.resolution || data.resolution === null) {
      resetConfirmationTime(record);
      const newSelectedRow: Partial<AttendanceData> = selectedRowsForApi.find(
        (x: AttendanceData) => x.application_id === record.id,
      ) || {};
      newSelectedRow.resolution = newSelectedRow.resolution === data.resolution
        ? null : data.resolution;
      newSelectedRow.confirmed_time = calculateWorkTime(
        record.shift.start_time,
        record.shift.end_time,
        false,
        'minutes',
      );

      selectedRowsForApi.splice(
        selectedRowsForApi.findIndex((x: AttendanceData) => x.application_id === record.id),
        1,
        newSelectedRow,
      );

      // Unselect the row that is no longer absence in case of read-only company.
      if (record.shift.company.is_readonly && canConfirmOnlyAbsence) toggleRowSelection(record, data);

      return setAbsenceData({ ...absenceData, [record.id]: { resolution: null } });
    }

    toggleManko(record, { setManko: false }, false);
    toggleBlock(record, { blocked: false });
    setAbsenceData({ ...absenceData, [record.id]: data });
    onSetRating(record, DEFAULT_JOB_EVALUATION_SCORE);
    if (setTime) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onConfirmedTimeChange(record, 0, false);
    }

    // Select row if not selected and set new resolution
    if (selectedRows.indexOf(record.id) < 0) {
      const toggleData: Partial<AttendanceData> = { resolution: data.resolution };
      if (setTime) {
        toggleData.confirmed_time = 0;
      }
      return toggleRowSelection(record, toggleData);
    }

    // update already selected row with new resolution
    const newSelectedRow = selectedRowsForApi.find((x: AttendanceData) => x.application_id === record.id);
    newSelectedRow.resolution = data.resolution;

    selectedRowsForApi.splice(
      selectedRowsForApi.findIndex((x: AttendanceData) => x.application_id === record.id),
      1,
      newSelectedRow,
    );
    setSelectedRowsForApi([...selectedRowsForApi]);

    return undefined;
  };

  const renderRating = (record: { id: string | number; }) => (
    <ThumbsRating
      setRating={(data) => onSetRating(record, data)}
      disabledTooltip="Není možné zadat hodnocení pro absence nebo stornované docházky."
      disabled={!!absenceData[record.id]?.resolution}
    />
  );

  function onConfirmedTimeChange(
    record: ApplicationData,
    change: number | null,
    setAbsence = true,
  ) {
    // prevent undefined getting into the resulting rowsForApi when set to 0
    let resolution;
    // Handle null input (when user selects all and deletes)
    if (change === null) {
      return onConfirmedTimeChange(record, 0);
    }
    if (change > 16) {
      return ErrorAlert('Pro potvrzení pracovní doby delší než 16 hodin kontaktujte svého koordinátora');
    }
    if (change < 0) {
      return ErrorAlert('Není možné zadat negativní docházku');
    }
    if (change !== 0 && absenceData[record.id]?.resolution) {
      // Toggle off absence and set change time as value
      toggleAbsence(record, { resolution: null });
      resolution = null;
    } else if (change === 0 && !absenceData[record.id]?.resolution && setAbsence) {
      // If changed to 0, automatically set to unexcused absence
      toggleAbsence(record, { resolution: AttendanceResolution.UNEXCUSED_ABSENCE }, false);
      resolution = AttendanceResolution.UNEXCUSED_ABSENCE;
    }

    setConfirmationTimes({ ...confirmationTimes, [record.id]: `${change}` });

    const minutesWorked = change * 60;

    const duration = calculateWorkTime(record.shift?.start_time, record.shift?.end_time, false, 'minutes');

    const confirmed_time = (minutesWorked > duration) ? duration : minutesWorked;
    const confirmed_overtime = Math.max(0, minutesWorked - duration);

    // When the row is already selected replace the old one with updated value
    if (selectedRows.indexOf(record.id) > -1) {
      const newSelectedRow = selectedRowsForApi.find((x: AttendanceData) => x.application_id === record.id);
      newSelectedRow.confirmed_time = confirmed_time;
      newSelectedRow.confirmed_overtime = confirmed_overtime;

      selectedRowsForApi.splice(
        selectedRowsForApi.findIndex((x: AttendanceData) => x.application_id === record.id),
        1,
        newSelectedRow,
      );
      return setSelectedRowsForApi([...selectedRowsForApi]);
    }

    // Add row to selected with initial values
    toggleRowSelection(
      record,
      { confirmed_time, confirmed_overtime, resolution },
    );
    return null;
  }

  const onConfirmedTimeStep = (value: number, info: { type: string; }) => {
    if (value === 16 && info?.type === 'up') {
      ErrorAlert('Pro potvrzení pracovní doby delší než 16 hodin kontaktujte svého koordinátora');
    }
    if (value === 0 && info?.type === 'down') {
      ErrorAlert('Není možné zadat negativní docházku');
    }
  };

  const renderEvaluation = (record: AttendanceData) => {
    if (record.resolution !== AttendanceResolution.OK) {
      return AttendanceResolutionDisplay[record.resolution];
    }
    if (record.job_evaluation === GOOD_JOB_EVALUATION_SCORE) {
      return (
        <LikeTwoTone
          twoToneColor={primaryColor}
          style={{ fontSize: '14px' }}
        />
      );
    }
    if (record.job_evaluation === BAD_JOB_EVALUATION_SCORE) {
      return (
        <DislikeTwoTone
          twoToneColor={dangerColor}
          style={{ fontSize: '14px' }}
        />
      );
    }
    return null;
  };

  const getApprovedAttendanceParams = useCallback(() => {
    if (openTabKey !== TAB_KEYS.APPROVED_ATTENDANCE) return {};
    let params: Query = {
      $joinRelation: '[application]',
      $skip: approvedPageSize * (approvedCurrentPage - 1),
      $limit: approvedPageSize,
    };

    if (approvedDateRange) {
      params.$joinRelation = '[application.[shift]]';
      params['application:shift.start_time'] = {
        $gte: approvedDateRange[0].toISOString(),
        $lte: approvedDateRange[1].toISOString(),
      };
    }

    params.$sort = approvedAttendancedSort;

    if (searchParamsApprovedAttendance) {
      params = { ...params, ...searchParamsApprovedAttendance };
    }
    if (advanceSearchState) {
      const advanceFilterParams = apiBuilder(advanceSearchState, apiTransformationsAttendance);
      // RelationExpression does not exist on type objection
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const relation = objection.RelationExpression.create(
        params.$joinRelation,
      );
      const mergedRelation = relation.merge(advanceFilterParams.$joinRelation);
      params = {
        ...searchParamsApprovedAttendance,
        ...advanceFilterParams,
        ...params,
        $joinRelation: mergedRelation.toString(),
      };
    }

    // eslint-disable-next-line consistent-return
    return params;
  }, [
    advanceSearchState,
    approvedAttendancedSort,
    approvedCurrentPage,
    approvedDateRange,
    approvedPageSize,
    openTabKey,
    searchParamsApprovedAttendance,
  ]);

  // Return custom confirmed work time or default calculated confirmed work time
  const getConfirmedWorkTime = (
    record: ApplicationData,
  ): number =>
    confirmationTimes[record.id]
    || calculateWorkTime(record.shift?.start_time, record.shift?.end_time, false, 'hours').toFixed(2);
  const [modalProps, setModalProps] = useState<ComponentProps<typeof AlertModal>>();
  const formatNotificationData = (record: AttendanceData) => `${record.application?.person?.first_name} ${record.application?.person?.last_name},
    ${moment(record.application?.shift?.start_time).format('DD. MM. YYYY')}
    - ${(((+record.confirmed_time) + (+record.confirmed_overtime)) / 60).toFixed(2)}h `;
  const showConfirmModal = useCallback((record) => {
    const onOkClick = () => {
      const deletedRecord = { ...record, deleted_at: moment().toISOString() };
      dispatch(actions.updateRecord({ id: record.id, record: deletedRecord }));
    };

    const prepareContent = (
      attendance: {
        application: {
          person: { first_name: any; last_name: any; };
          shift: { start_time: moment.MomentInput; };
        };
        confirmed_time: string | number;
        overtime: string | number;
        paymentRequest: { paymentTransaction: any; }; },
    ) => {
      if (attendance?.paymentRequest && !attendance?.paymentRequest?.paymentTransaction) {
        return (
          <>
            <h3> Pozor! </h3>
            <p>
              Tento Tymber má již zažádáno o vyplacení (zatím nevyplaceno).
              Přejete si i přesto smazat potvrzenou docházku:<br /> {formatNotificationData(attendance)} ?
            </p>
          </>
        );
      }
      return <>Chcete opravdu odstranit docházku:<br /> {formatNotificationData(attendance)}?</>;
    };

    return new Promise<void>((resolve, reject) => {
      setModalProps({
        title: <><ExclamationCircleOutlined /> Potvrdit smazání docházky</>,
        onConfirm: () => {
          resolve();
          onOkClick();
          setModalProps(undefined);
        },
        onCancel: () => {
          reject();
          setModalProps(undefined);
        },
        message: prepareContent(record),
        showModal: true,
      });
    });
  }, [dispatch]);

  const onCancelClick = useCallback((record) => {
    const restoreRecord = { ...record, deleted_at: null };
    dispatch(actions.updateRecord({ id: record.id, record: restoreRecord }));
  }, [dispatch]);

  const onClickRemoveRecord = useCallback(async (record) => {
    let paymentRequestPromise = null;
    if (record.attendance?.paymentRequest) {
      paymentRequestPromise = await dispatch(
        // wrong infer from js file, redux
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        removePaymentRequestThunk(record.paymentRequest.id),
      );
    }

    if (isSuperAdmin) {
      // wrong infer from js file, redux
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await dispatch(removeSurveyAnswerThunk(record.id));
    }
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!paymentRequestPromise || paymentRequestPromise?.meta?.requestStatus === 'fulfilled') {
      // wrong infer from js file, redux
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(removeAttendanceThunk(record.id)).then((attResult: { meta: { requestStatus: string; }; }) => {
        if (attResult?.meta?.requestStatus !== 'fulfilled') onCancelClick(record);
        loadAttendanceData(adminError, getApprovedAttendanceParams());
      });
    }
  }, [adminError, dispatch, getApprovedAttendanceParams, loadAttendanceData, onCancelClick, isSuperAdmin]);

  const columnsApprovedAttendanceDefault = useMemo(
    () => [
      {
        title: 'Firma',
        key: 'company_name',
        render: (record: AttendanceData) => record.application?.shift?.company?.name,
        align: 'left',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Provozovna',
        render: (record: AttendanceData) => record.application?.shift?.branchoffice?.parent?.name
          || record.application?.shift?.branchoffice?.name,
        align: 'left',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Pozice',
        render: (record: AttendanceData) => record.application?.shift?.name,
        align: 'left',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Tymber',
        align: 'left',
        render: (record: AttendanceData) => `${record.application?.person?.first_name} ${record.application?.person?.last_name}`,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'ID Tymbera',
        align: 'left',
        render: (record: AttendanceData) => (
          <Link
            target="_blank"
            to={{
              pathname: `/user/${record.application?.person?.id}`,
              // tymberProps does not exist on to
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              tymberProps: { user: { ...record.application?.person } },
            }}
            className="ty-poppins-12"
          >
            {record.application?.person?.id}
          </Link>
        ),
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },

      {
        title: 'Směna',
        align: 'left',
        render: (_: any, record: AttendanceData) => `${moment(record.application?.shift?.start_time)
          .format('DD. MM. YYYY')} ${moment(record.application?.shift?.start_time).format('HH:mm')}`,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },

      {
        title: 'Potvrzený čas',
        align: 'left',
        dataIndex: 'confirmed_time',
        sorter: true,
        render: (_: any, record: AttendanceData) => `${((record.confirmed_time / 60) + (record.confirmed_overtime / 60)).toFixed(2)}h`,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Kredity',
        align: 'left',
        sorter: true,
        dataIndex: 'confirmed_overtime',
        render: (_: any, record: AttendanceData) => `${(record.confirmed_credit_time / 60).toFixed(2)}h`,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Celkem',
        align: 'left',
        render: (_: any, record: AttendanceData) => `${((Number(record.confirmed_time) + Number(record.confirmed_overtime) + Number(record.confirmed_credit_time)) / 60).toFixed(2)}h`,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Hodnocení',
        dataIndex: 'evaluation',
        align: 'left',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        sorter: true,
        render: (_: any, record: AttendanceData) => (
          <>
            {renderEvaluation(record)}
            <Protect
              auth={[
                Roles.SUPER_ADMIN,
                Roles.TYMBE_ADMIN,
                Roles.TYMBE_COORDINATOR,
                Roles.COMPANY,
                Roles.BRANCHOFFICE_MANAGER,
                Roles.BRANCHOFFICE_SUPERVISOR,
              ]}
              redirect={false}
            >
              <HoverRowEditDeleteButtons
                editLink=""
                hideEdit
                hideDelete={!user.hasRoles(getRolesWhichCanDeleteAttendance(record))}
                removeTimeoutFunction={() => onClickRemoveRecord(record)}
                onRemoveOnClick={() => showConfirmModal(record)}
                onRemoveCancel={() => onCancelClick(record)}
                removeNotificationMessage={`Potvrzená docházka "${formatNotificationData(record)}" bude smazána`}
              />
            </Protect>
          </>
        ),
      },
    ],
    [onCancelClick, onClickRemoveRecord, showConfirmModal, user],
  );
  const decimalToTime = (num: number) => `${(Math.floor(num))}h ${(`${(num % 1) * 60}0`).slice(0, 2)}m`;

  const columnsUnapprovedAttendance = [
    {
      title: 'Jméno a příjmení',
      align: 'left',
      dataIndex: 'person_name',
      render: (_: any, record: ApplicationData) => `${record.person?.first_name} ${record.person?.last_name}`,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'ID Tymbera',
      align: 'left',
      render: (
        _: any,
        record: ApplicationData,
      ) => (
        <Link
          target="_blank"
          to={{
            pathname: `/user/${record.person?.id}`,
            // tymberProps does not exist on to
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tymberProps: { user: { ...record.person } },
          }}
          className="ty-poppins-12"
        >
          {record.person?.id}
        </Link>
      ),
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Provozovna',
      render: (
        record: ApplicationData,
      ) => record.shift?.branchoffice?.parent?.name
      || record.shift?.branchoffice?.name,
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: `${user.person?.branchoffice?.length === 1 ? 'hidden' : ''}`,
    },
    {
      title: 'Pozice',
      render: (
        record: ApplicationData,
      ) => (
        <div className="flex">
          {record.shift?.name}
          {record.shift?.company?.is_readonly ? (
            <Tooltip overlay="Read-Only firma">
              <span className="px-1"> <ExclamationCircleIcon iconcolor={alert} /> </span>
            </Tooltip>
          ) : null}
        </div>
      ),
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Odpracováno (h)',
      align: 'left',
      dataIndex: 'amount_worked',
      sorter: true,
      render: (
        _: any,
        record: ApplicationData,
      ) => (
        <Row gutter={[10, 10]} className="grid grid-cols-4">
          <Col className="col-span-2" xs={24} lg={10} xl={7} style={{ display: 'flex', alignItems: 'center' }}>
            <small style={{ whiteSpace: 'nowrap' }}>
              {`${moment(record.shift?.start_time).format('DD. MM. YYYY HH:mm')}-${moment(record.shift?.end_time)
                .format('HH:mm')}`}
            </small>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center', width: '90px' }}>
            <InputNumber
              value={getConfirmedWorkTime(record)}
              decimalSeparator=","
              min={0}
              max={16}
              step=".25"
              className="ty-input ghost"
              style={{ width: '50px' }}
              onChange={(change) => onConfirmedTimeChange(record, change)}
              onStep={onConfirmedTimeStep}
            />
            <span className="ty-poppins-14-500" style={{ color: darkBlue }}>h</span>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            {`${decimalToTime(getConfirmedWorkTime(record))}`}
          </Col>
        </Row>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Hodnocení',
      dataIndex: 'rating',
      align: 'center',
      render: (_: any, record: ApplicationData) => renderRating(record),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  useEffect(() => {
    if (!adminError) return;
    ErrorAlert(adminError);
  }, [adminError]);

  useEffect(() => {
    // tab 1 : "docházka k potvrzení"
    if (openTabKey !== TAB_KEYS.UNAPPROVED_ATTENDANCE) return;
    let params: Query = {
      $skip: unapprovedPageSize * (unapprovedCurrentPage - 1),
      $limit: unapprovedPageSize,
    };
    params.$eager = '[attendance, person.personData]';
    params.$joinEager = '[person, shift.[branchoffice.parent, company]]';

    // Date filtering
    if (dateArray) {
      params['manShift:shift.start_time'] = {
        $gte: moment(dateArray[0]).startOf('day').toISOString(),
      };
      params['manShift:shift.end_time'] = { $lte: moment(dateArray[1]).endOf('day').toISOString() };
    }
    params.$sort = unapprovedAttendancedSort;

    if (searchParamsAttendance) {
      params = { ...params, ...searchParamsAttendance };
    }

    if (advanceSearchApplication) {
      const advanceSearchForConfirmationStateParams = apiBuilder(
        advanceSearchApplication,
        apiTransformationsApplication,
      );
      params = { ...advanceSearchForConfirmationStateParams, ...params };
      if (params.$modify) {
        params.$modify.unconfirmed = true;
      }
    }

    if (
      !params['manShift:shift.start_time']
      || (params['manShift:shift.start_time']?.$lte
      && moment(params['manShift:shift.start_time'].$lte).isAfter(moment()))
    ) {
      params['manShift:shift.start_time'] = { $lte: moment().toISOString() };
    }
    // RelationExpression does not exist on type objection
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const relation = objection.RelationExpression.create(
      params.$joinRelation,
    );
    const mergedRelation = relation.merge('manShift.shift.branchoffice');
    // load "docházka k potvrzení" API Request
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadApplicationThunk({
      ...params,
      'application.state': ApplicationState.CONFIRMED,
      $joinRelation: mergedRelation.toString(),
    }));
  }, [
    openTabKey,
    adminError,
    dateArray,
    timeArray,
    updateComponent,
    unapprovedCurrentPage,
    unapprovedPageSize,
    dispatch,
    searchParamsAttendance,
    unapprovedAttendancedSort,
    advanceSearchApplication,
  ]);

  useEffect(() => {
    setShowConfirmPanel(selectedRows.length > 0);
  }, [selectedRows, setShowConfirmPanel]);

  useEffect(() => {
    // tab 2 : "Potvrzená docházka"
    if (openTabKey !== TAB_KEYS.APPROVED_ATTENDANCE) return;
    loadAttendanceData(adminError, getApprovedAttendanceParams());
  }, [openTabKey, loadAttendanceData, getApprovedAttendanceParams, adminError]);

  const downloadAttendanceExport = async (params: { $eager: string; $joinRelation: string; }) => {
    setFormDisabled(true);
    const exportResponse = await dispatch(
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      loadAttendanceForExportThunk(params),
    );
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (exportResponse?.meta?.requestStatus !== 'fulfilled') {
      setFormDisabled(false);
      // wrong infer from js file, redux
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ErrorAlert(exportResponse?.error?.message || 'Nastala chyba při exportu');
      return;
    }
    const fileName = `${moment().format('YYYYMMDD_hhmm')}_dochazka`;
    // wrong infer from js file, redux
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    exportDataToXLSX(fileName, mapConfirmedAttendanceForExport(exportResponse.payload));
    setFormDisabled(false);
  };

  const handleApprovedAttendanceExport = () => {
    let params: Query = {};

    if (approvedDateRange) {
      params['application:shift.start_time'] = {
        $gte: approvedDateRange[0].toISOString(),
        $lte: approvedDateRange[1].toISOString(),
      };
    }

    if (searchParamsApprovedAttendance) {
      params = { ...params, ...searchParamsApprovedAttendance };
    }

    if (advanceSearchState) {
      const advanceFilterParams = apiBuilder(advanceSearchState, apiTransformationsAttendance);
      params = {
        ...advanceFilterParams,
        ...params,
      };
    }

    const query = {
      $eager: '[application.[shift.[branchoffice.parent, company], person.[personData,contact]], confirmedBy]',
      $joinRelation: '[application.[shift.branchoffice, person]]',
      ...params,
    };

    downloadAttendanceExport(query);
  };

  const onUnapprovedAcceptanceTableChange = (
    _pagination: any,
    _filters: any,
    sorter: { order: string; field: any; },
  ) => {
    // 3-step sorter default - ascend - descend
    if (sorter?.order) {
      switch (sorter.field) {
        case 'person_name':
          setUnapprovedAttendanceSort({ 'person.last_name': sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'person_id':
          setUnapprovedAttendanceSort({ person_id: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'amount_worked':
          setUnapprovedAttendanceSort({ 'shift.start_time': sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setUnapprovedAttendanceSort(DEFAULT_UNAPPROVED_ATTENDANCE_SORT);
          break;
      }
    } else {
      setUnapprovedAttendanceSort(DEFAULT_UNAPPROVED_ATTENDANCE_SORT);
    }
  };

  const onCloseFreeUpManShiftModal = async () => {
    setShowFreeUpManShiftModal(false);
    await onHandleConfirmAttendance(true, false);
  };

  const onSubstituteManShifts = async () => {
    setShowFreeUpManShiftModal(false);
    await onHandleConfirmAttendance(true, true);
  };

  const onApprovedAcceptanceTableChange = (_pagination: any, _filters: any, sorter: { order: string; field: any; }) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'confirmed_time':
          setApprovedAttendancedSort({ confirmed_time: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'confirmed_overtime':
          setApprovedAttendancedSort({ confirmed_overtime: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'evaluation':
          setApprovedAttendancedSort({ job_evaluation: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setApprovedAttendancedSort(DEFAULT_APPROVED_ATTENDANCE_SORT);
          break;
      }
    } else {
      setApprovedAttendancedSort(DEFAULT_APPROVED_ATTENDANCE_SORT);
    }
  };

  const tabs = [
    {
      key: TAB_KEYS.UNAPPROVED_ATTENDANCE,
      label: 'Docházka k potvrzení',
      children:
  <>
    {
      selectedAttendanceData ? (
        <div
          className={styles.tableTitle}
        >
          <div
            className={styles.searchButtons}
          >
            <SearchBox
              name="attendance"
              showSearch={showSearchAttendance}
              setShowSearch={setShowSearchAttendance}
              onParamsSearch={onParamsSearch}
              setSearchParams={setSearchParamsAttendance}
              loading={!!attendanceLoading}
              placeholder="Jméno nebo ID Tymbera"
            />
            <TyAdvancedSearchApplication
              pageTitle="Filtrování docházek k potvrzení"
              setInitialValues={setAdvanceSearchApplication}
              initialValues={advanceSearchApplication}
            />
          </div>
          <div>
            <TyInteractiveLabels
              labelsValues={labelsValues}
              setInitialValues={setAdvanceSearchApplication}
              initialValues={advanceSearchApplication}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )
    }
    {
      selectedAttendanceData && !loading ? (
        <UnapprovedAttendanceTable
          isLoading={!!attendanceLoading}
          columns={columnsUnapprovedAttendance}
          dataSource={selectedAttendanceData}
          // wrong infer from js file, redux
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagination={selectedUnapprovedAttendancePagination}
          onPaginationChange={onUnapprovedPaginationChange}
          currentPage={unapprovedCurrentPage}
          blockData={blockData}
          mankoData={mankoData}
          absenceData={absenceData}
          onRowSelect={toggleRowSelection}
          onRowBlockChange={toggleBlock}
          onRowMankoChange={toggleManko}
          onRowAbsenceChange={toggleAbsence}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onSelectAll: (selected: any, selRows: any, changeRows: any) => {
              toggleRowSelection(changeRows, true);
            },
          }}
          onTableChange={onUnapprovedAcceptanceTableChange}
        />
      ) : (
        <Spinner />
      )
    }
  </>,
    },
    {
      key: TAB_KEYS.APPROVED_ATTENDANCE,
      label: 'Potvrzená docházka',
      children: <AdminTable
        rowKey={(record: { id: string; }) => record.id}
        columns={limitApprovedColumnsForNonAdmin(columnsApprovedAttendanceDefault, isAdmin)}
        data={{
          data: approvedAttendanceData.data?.filter((x: { deleted_at: null; }) => x?.deleted_at === null) || [],
          total: approvedAttendanceData.total,
          skip: approvedAttendanceData.skip,
          limit: approvedAttendanceData.limit,
        }}
        isLoading={approvedAttendanceLoading}
        className="ty-table"
        onPaginationChange={onApprovedPaginationChange}
        onChange={onApprovedAcceptanceTableChange}
        currentPage={approvedCurrentPage}
        pageSizeOptions={[10, 20, 50, 100]}
        expandable={{
          // TODO: This is ticking bomb extract
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (record: object) => (
            <ApprovedAttendanceExpandedRow record={record} />
          ),
        }}
        rowClassName="ty-row-hover"
        // TODO: This is ticking bomb extract
        // eslint-disable-next-line react/no-unstable-nested-components
        title={() => (
          <div style={{ textAlign: 'center' }}>
            <div className="ty-title-row">
              <TableRangePicker
                onDateChange={setApprovedDateRange}
                label="Směny od - do"
              />

              <SecondaryButton
                size="large"
                className={styles.secondaryButton}
                onClick={handleApprovedAttendanceExport}
                buttontext="Export"
                download
                disabled={formDisabled}
              />
            </div>
            <div
              className={styles.searchButtons}
            >
              <SearchBox
                name="approvedAttendance"
                showSearch={showSearchApprovedAttendance}
                setShowSearch={setShowSearchApprovedAttendance}
                onParamsSearch={onParamsSearch}
                setSearchParams={setSearchParamsApprovedAttendance}
                loading={!!loading}
                placeholder="Jméno nebo ID Tymebra"
              />
              <TyAdvancedSearchAttendance
                pageTitle="Filtrování docházek"
                setInitialValues={setAdvanceSearchState}
                initialValues={advanceSearchState}
              />
            </div>
            <div>
              <TyInteractiveLabels
                labelsValues={labelsValues}
                setInitialValues={setAdvanceSearchState}
                initialValues={advanceSearchState}
              />
            </div>
          </div>
        )}
      />,
    },
  ];

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div
          className={styles.attendanceWrapper}
        >
          <PageTitle>Docházka</PageTitle>
        </div>
        <Card bodyStyle={{ padding: 0 }}>

          <Tabs
            style={{ paddingTop: '22px' }}
            className="ty-admin-tabs"
            onChange={setOpenTabKey}
            items={canSeeUnapprovedAttendance ? tabs : [tabs[1]]}
          />

        </Card>
      </Wrapper>
      <SimpleModal
        title="Nahradit směny"
        visible={showFreeUpManShiftModal}
        okText="Nahradit"
        cancelText="Zavřít bez nahrazení"
        showCancelButton
        onOk={onSubstituteManShifts}
        onCancel={onCloseFreeUpManShiftModal}
      >
        <p>Chcete nahradit uvolněná místa volnou kapacitou?</p>
      </SimpleModal>
      {showConfirmPanel && openTabKey === TAB_KEYS.UNAPPROVED_ATTENDANCE ? (
        <Affix
          style={{ position: 'fixed', bottom: '30px', right: '30px' }}
        >
          <Row className="ty-attendance-panel">
            <Col xs={24} md={14} className="ty-attendance-panel-text-wrapper">
              <div className="ty-attendance-panel-text">
                Bylo vybráno
                {' '}
                {selectedRows.length}
                {' '}
                záznamů
              </div>
            </Col>

            <Col xs={24} md={10} className="ty-attendance-panel-buttons">
              <div className="ty-attendance-panel-button-wrapper">
                <SecondaryButton
                  id="confirm_attendance_button"
                  buttontext="Potvrdit docházku"
                  onClick={() => onHandleConfirmAttendance(true, true)}
                />
              </div>
            </Col>
          </Row>
        </Affix>
      ) : null}
      {modalProps?.showModal && <AlertModal {...modalProps} />}
    </Container>
  );
};

export default AttendancePage;
