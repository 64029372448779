import Tooltip from 'rc-tooltip';

import { PersonDataData } from '../../../../types/TymbeApi';

interface AttendanceRateProps {
  personDetail?: PersonDataData;
}
const attendanceRateCalculation = (att?: number, app?: number) => {
  if (att && app && app > 0) {
    return Math.min(100 * (att / app), 100);
  }
  return false;
};

const AttendanceRate = ({ personDetail = undefined }: AttendanceRateProps) => {
  const overlayMessage = `${personDetail?.attendanceCount} odpracovaných / ${personDetail?.applicationCount} nahlášených směn`;
  const attendanceResult = attendanceRateCalculation(personDetail?.attendanceCount, personDetail?.applicationCount);

  return (
    <Tooltip placement="right" trigger={['hover']} overlay={overlayMessage}>
      <div>
        {attendanceResult ? `${attendanceResult.toFixed(2)} %` : '-'}
      </div>
    </Tooltip>
  );
};

export default AttendanceRate;
