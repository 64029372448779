import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SecondaryButton } from '../../../../../components/buttons';
import { TrashIcon } from '../../../../../components/icons';
import AlertModal from '../../../../../components/modals/AlertModal';

export interface DeleteNoteProps {
  noteId: number;
}

const DeleteNote = ({ noteId }: DeleteNoteProps) => {
  const [showModal, setShowModal] = useState(false);
  const qClient = useQueryClient();

  const { mutateAsync: deleteBlock } = useMutation(
    ['delete-person-note'],
    async () => {
      await feathersClient.service('person-note').remove(noteId);
    },
  );

  const onSubmit = async () => {
    try {
      await deleteBlock();
      qClient.invalidateQueries(['person-note']);
      setShowModal(false);
      SuccessAlert('Poznámka smazána');
    } catch {
      ErrorAlert('Při mazání poznámky došlo k chybě');
    }
  };

  return (
    <>
      <div className="p-1">
        <SecondaryButton
          className="ty-icon-button hover:border-error/50 border-error"
          type="button"
          icon={<TrashIcon />}
          onClick={() => setShowModal(true)}
        />
      </div>
      <AlertModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        title="Pokoušíte se smazat poznámku"
        message="Opravdu chcete smazat tuto poznámku?"
        onYes={onSubmit}
      />
    </>
  );
};

export default DeleteNote;
