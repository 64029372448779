import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import adminReducer from './slices/admin';
import authReducer from './slices/auth';
import banReducer from './slices/ban';
import documentsReducer from './slices/documents';
import jobsReducer from './slices/jobs';
import userReducer from './slices/user';
import uiReducer from './slices/user-interface';
import walletReducer from './slices/wallet';
import PopupNotificationSlice from '../../components/PopupNotification/PopupNotification.slice';
import loadAttendancesReducer from '../../pages/attendance/utils/attendanceSlice';
import companyAttendancesReducer from '../../pages/attendance/utils/slice';
import loadBranchofficesReducer from '../../pages/company/@companyId/branchoffice/branchofficeSlice';
import loadDepartmentReducer from '../../pages/company/@companyId/department/utils/departmentSlice';
import loadDocumentTypesReducer from '../../pages/company/@companyId/document-type/utils/documentTypeSlice';
import loadCompanyReducer from '../../pages/company/slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error'],
};

const rootReducers = combineReducers({
  admin: adminReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  documents: documentsReducer,
  jobs: jobsReducer,
  user: userReducer,
  wallet: walletReducer,
  ban: banReducer,
  ui: uiReducer,
  views: combineReducers({
    companyAttendances: companyAttendancesReducer,
    companyList: loadCompanyReducer,
    companyBranchoffices: loadBranchofficesReducer,
    companyDepartments: loadDepartmentReducer,
    companyDocumentTypes: loadDocumentTypesReducer,
    attendances: loadAttendancesReducer,
  }),
  components: combineReducers({
    popupNotifications: PopupNotificationSlice,
  }),
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ],
});
