import { useFormApi } from 'informed';
import { useEffect, useState } from 'react';

import { checkFileSize } from './blob.utils';
import feathersClient from '../../apiClient';
import { IMAGE_ASPECT_SQUARE } from '../../utils/constants';
import getBucketServiceParams from '../../utils/getBucketServiceParams';
import { ErrorAlert } from '../alerts';
import UploadedImage from '../upload/UploadedImage/UploadedImage';
import UploadImage from '../upload/UploadImage/UploadImage';

export interface LogoFormValues {
  logoBlob: Blob | null,
}

type LogoFormProps = {
  logo?: string | null;
};

const LogoForm = ({ logo }: LogoFormProps) => {
  const formApi = useFormApi<LogoFormValues>();
  const [logoBlob, setLogoBlob] = useState<Blob | null>(null);
  const blobService = feathersClient.service('bucket');

  useEffect(() => {
    if (!logo) return undefined;
    const controller = new AbortController();
    blobService.get(logo, getBucketServiceParams(controller))
      .then(setLogoBlob);

    return () => controller.abort();
  }, [blobService, logo]);

  const onSetLogoBlob = (blob: Blob) => {
    if (checkFileSize(blob)) {
      setLogoBlob(blob);
      formApi.setValue('logoBlob', blob);
    } else {
      ErrorAlert('Soubor musí být menší než 5MB!');
    }
  };

  return (
    <div id="logo-form">
      <h4> Logo </h4>
      <UploadImage
        allowScale
        allowRotate
        name="logo"
        accept="image/*,.pdf"
        uploadLabel="Nahrajte logo"
        onChange={onSetLogoBlob}
        modalTitle="Logo"
        image=""
        // logos should be saved as squares
        aspectHeight={IMAGE_ASPECT_SQUARE.height}
        aspectWidth={IMAGE_ASPECT_SQUARE.width}
      />

      {logoBlob && (
        <UploadedImage
          alt="Logo - obrázek"
          image={logoBlob}
          removeImage={() => {
            formApi.setValue('logoBlob', null);
            setLogoBlob(null);
          }}
        />
      )}
    </div>
  );
};

export default LogoForm;
