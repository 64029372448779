import { FeathersError } from '@feathersjs/errors';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { SuccessAlert } from '../../../../components/alerts';
import Button from '../../../../components/buttons/Button';
import ErrorButton from '../../../../components/buttons/ErrorButton';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { ExclamationCircleOutlined } from '../../../../components/icons';
import MushroomCloud from '../../../../components/icons/MushroomCloud';
import ModalShell from '../../../../components/modals/ModalShell';
import { InlineSpinner } from '../../../../utils/spinner';

export interface RemoveRegistrationButtonProps {
  personId: number;
}

const RemoveRegistrationButton = ({ personId }: RemoveRegistrationButtonProps) => {
  const [modalShown, setModalShown] = useState(false);
  const { mutateAsync: requestDeletePerson, isLoading, error, isSuccess } = useMutation<any, FeathersError>(
    ['hardDeletePerson', personId],
    () => feathersClient.service('person').remove(personId, { query: { $removeRegistration: true } }),
  );

  const showModal = () => setModalShown(true);
  const hideModal = () => setModalShown(false);

  const removePerson = async () => {
    await requestDeletePerson();
    SuccessAlert('Osoba byla úspěšně smazána.');
  };

  if (isSuccess) {
    return <Navigate to="/tymber" />;
  }

  return (
    <>
      <ErrorButton
        onClick={showModal}
      >
        <MushroomCloud className="w-5" />
      </ErrorButton>
      <ModalShell
        showModal={modalShown}
        onClose={hideModal}
      >
        <div className="flex flex-col flex-grow gap-2">
          <h3><ExclamationCircleOutlined className="w-[22px] mr-1 text-danger inline" />Tato operace je nevratná!</h3>
          <p>Opravdu chcete trvale smazat tuto osobu?</p>
          {/* TODO: Extract to own component */}
          {error && <div className="text-error">Nepodařilo se smazat osobu.</div>}
          {error && <div className="text-error text-xs">{error.message}</div>}
          <div className="flex flex-row pt-8 flex-grow justify-end gap-3">
            {isLoading && <InlineSpinner />}
            {!isLoading && (
              <>
                <Button
                  type="button"
                  onClick={hideModal}
                >
                  Zrušit
                </Button>
                <PrimaryButton
                  type="button"
                  onClick={removePerson}
                >
                  Smazat
                </PrimaryButton>
              </>
            )}
          </div>
        </div>
      </ModalShell>
    </>
  );
};

export default RemoveRegistrationButton;
