import { ReleaseTymberAdvancesData } from '@tymbe/schema/release-tymber-advances.interface';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, NavLink, Outlet } from 'react-router-dom';

import AccountState from '../../../../components/AccountState/AccountState';
import AttendanceRate from './AttendanceRate';
import Blocks from './Blocks';
import RemoveRegistrationButton from './RemoveRegistrationButton';
import StarRating from './StarRating';
import feathersClient from '../../../../apiClient';
import Protect from '../../../../apiClient/Protect';
import { PrimaryButton } from '../../../../components/buttons';
import { EditIcon } from '../../../../components/icons';
import Card from '../../../../components/Layout/Card';
import NavTabs from '../../../../components/navTabs/NavTabs';
import ReleaseTymberAdvancesModal from '../../../../components/ReleaseTymberAdvances/ReleaseTymberAdvancesModal';
import { Roles } from '../../../../utils/enums';
import getGender from '../../../../utils/gender';
import Spinner from '../../../../utils/spinner';
import { getAge } from '../../../../utils/time';

interface UserDetailProps {
  personId: string;
}

const UserDetailContainer = ({ personId }: UserDetailProps) => {
  const [showReleaseAdvancesModal, setShowReleaseAdvancesModal] = useState(false);
  const [releaseAdvancesResult, setReleaseAdvancesResult] = useState<ReleaseTymberAdvancesData & { error?: string }>();

  const { data: person, isLoading: personLoading } = useQuery(['person', personId], async () =>
    feathersClient
      .service('person')
      .get(personId, { query: { $eager: '[accountState, blockedCompany]' } }));

  const { data: personData, isLoading: personDataLoading } = useQuery(
    ['personData', personId],
    async () => feathersClient
      .service('person-data').get(personId, { query: { $modify: 'attendanceRate' } }),
    { retry: false },
  );

  const { data: companyBlockedPerson } = useQuery(['company-blocked-user', personId], async () =>
    feathersClient
      .service('company-blocked-user')
      .find({
        query: {
          person_id: personId,
          $eager: '[company, branchoffice]',
          $sort: {
            created_at: -1,
          },
        },
      }));

  const { mutateAsync: releaseAdvances, isLoading: advancesAreBeingReleased } = useMutation(
    ['releaseTymberAdvances', personId],
    async (data: ReleaseTymberAdvancesData) => feathersClient.service('release-tymber-advances').create(data),
  );

  const onReleaseAdvancesClick = () => {
    setShowReleaseAdvancesModal(!advancesAreBeingReleased);
  };

  const onReleaseAdvancesModalSubmit = async () => {
    if (advancesAreBeingReleased) {
      return;
    }

    try {
      const startOfPreviousPayInterval = moment.tz().startOf('month').subtract(1, 'month');

      const result = await releaseAdvances({
        tymberId: Number(personId),
        year: startOfPreviousPayInterval.year(),
        month: startOfPreviousPayInterval.month() + 1,
      });
      setReleaseAdvancesResult(result);
    } catch {
      const result = {
        tymberId: 0,
        year: 0,
        month: 0,
        error: 'Při uvolňování záloh došlo k chybě.',
      };
      setReleaseAdvancesResult(result);
    }
  };

  const onReleaseAdvancesModalClose = () => {
    setShowReleaseAdvancesModal(false);
    setReleaseAdvancesResult(undefined);
  };

  if (personDataLoading || personLoading) return <Spinner />;

  return (
    <div className="userInfo flex flex-col gap-5">
      <div className="ty-user-detail-name flex gap-5 pt-4">
        {person?.first_name} {person?.last_name}
        {personData && (
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <Link to={`/user/${personId}/edit`}>
              <PrimaryButton
                className="ty-icon-button ty-icon-button-toggle bg-primary"
                type="link"
                icon={<EditIcon />}
              />
            </Link>
          </Protect>
        )}
        <Protect
          auth={[
            Roles.SUPER_ADMIN,
            Roles.TYMBE_ADMIN,
          ]}
          redirect={false}
        >
          <div className="grow" />
          <ReleaseTymberAdvancesModal
            advancesAreBeingReleased={advancesAreBeingReleased}
            onClose={onReleaseAdvancesModalClose}
            onSubmit={onReleaseAdvancesModalSubmit}
            releaseAdvancesResult={releaseAdvancesResult}
            showModal={showReleaseAdvancesModal}
            onReleaseAdvancesClick={onReleaseAdvancesClick}
          />
          <RemoveRegistrationButton personId={Number(personId)} />
        </Protect>
      </div>

      <div className="flex gap-3 text-left">
        <div className="ty-h4">
          {personData?.gender ? getGender(personData.gender) : 'Žádná data '} -
          {personData?.birthdate ? `${getAge(personData.birthdate)} let` : ' Žádná data'}
        </div>
        <div className="ty-h4">
          ID: {personId}
        </div>
        <AccountState accountState={person?.accountState} />
      </div>
      <Card>
        <h3 className="ty-h3">Hodnocení</h3>
        <div className="lg:flex flex-column items-end gap-[20%] ">
          <div className="pt-6">
            <div
              className="flex justify-start items-center h-35 mb-10"
            >
              <StarRating ratingValue={personData?.job_evaluation} />
            </div>
            <div className="ty-faded-small pt-5">
              Hodnocení práce
            </div>
          </div>
          <div className="pt-6">
            <div
              className="ty-primary-h3 flex justify-start items-center h-35 mb-10"
            >
              <AttendanceRate personDetail={personData} />
            </div>
            <div className="ty-faded-small">
              Docházkovost
            </div>
          </div>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <div className="pt-6">
              <div
                className="ty-primary-h3 flex justify-start items-center h-35 mb-10"
              >
                <Blocks companyBlockedUser={companyBlockedPerson?.data} />
              </div>
              <div className="ty-faded-small">
                Blokace
              </div>
            </div>
          </Protect>
        </div>
      </Card>
      <Card>
        <div className="relative border-b border-secondary-200 pb-2 pl-3">
          <NavTabs>
            <NavLink key="shifts" to={`/user/${personId}/shifts`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Směny</NavLink>
            {/* Maybe in the future */}
            {/* <NavLink key="payouts" to={`/user/${personId}/payouts`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Výplaty</NavLink> */}
            {/* <NavLink key="attendances" to={`/user/${personId}/attendances`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Potvrzení</NavLink> */}
            {/* <NavLink key="logs" to={`/user/${personId}/logs`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Log</NavLink> */}
            <Protect
              auth={[
                Roles.SUPER_ADMIN,
                Roles.TYMBE_ADMIN,
                Roles.ADMIN,
                Roles.TYMBE_COORDINATOR,
              ]}
              redirect={false}
              key="admin-only"
            >
              <NavLink key="bans" to={`/user/${personId}/bans`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Bany</NavLink>
              <NavLink key="wallet" to={`/user/${personId}/wallet`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Peněženka</NavLink>
              <NavLink key="credits" to={`/user/${personId}/credits`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Kredity</NavLink>
            </Protect>
            <NavLink key="documents" to={`/user/${personId}/documents`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Dokumenty</NavLink>
            <NavLink key="contacts" to={`/user/${personId}/contacts`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Kontakty</NavLink>
            <NavLink key="notes" to={`/user/${personId}/notes`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Poznámky</NavLink>
            <Protect
              auth={[
                Roles.SUPER_ADMIN,
                Roles.TYMBE_ADMIN,
                Roles.TYMBE_COORDINATOR,
                Roles.COMPANY,
                Roles.BRANCHOFFICE_MANAGER,
              ]}
              redirect={false}
            >
              <NavLink key="blocks" to={`/user/${personId}/blocks`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Blokace</NavLink>
              <NavLink key="utility" to={`/user/${personId}/utility`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Pomůcky</NavLink>
            </Protect>
          </NavTabs>
        </div>
      </Card>
      <Outlet />
    </div>
  );
};

export default UserDetailContainer;
