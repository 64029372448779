import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { Form } from 'informed';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import PaymentList from './components/PaymentList';
import SalaryCalculation from './components/SalaryCalculation';
import SelectEmployer from './components/SelectEmployer';
import { useUser } from '../../../../apiClient/ApiContext';
import Protect from '../../../../apiClient/Protect';
import TyInput from '../../../../components/inputs/TyInput';
import { TySelect } from '../../../../components/inputs/TySelect';
import Card from '../../../../components/Layout/Card';
import { CompanyData } from '../../../../types/TymbeApi';
import { Roles } from '../../../../utils/enums';

const getMonths = () => moment.months('MMMM').map((label, value) => ({ label, value }));

const types = [
  { label: 'všechny', value: undefined },
  { label: 'pouze DPP', value: CzechSalaryType.DPP },
  { label: 'pouze DPČ+HPP', value: CzechSalaryType.DPC_HPP },
];

const UserWallet = () => {
  const { personId } = useParams();

  const now = moment().tz('europe/prague');
  const currentMonth = getMonths().find(({ value }) => value === now.month())!;
  const currentYear = now.year();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const from = Number.isSafeInteger(year) ? moment.tz([year, month.value, 1], 'europe/prague') : undefined;
  const to = from?.clone().endOf('month');

  const { person } = useUser();
  const [company, setCompany] = useState<CompanyData>(person.company?.[0]);

  const allTypes = types.find(({ value }) => value === undefined)!;
  const [type, setType] = useState(allTypes);

  return (
    <>
      <Card>
        <Form>
          <TySelect
            required="Povinné pole"
            initialValue={currentMonth}
            name="month"
            label="Měsíc"
            options={getMonths()}
            onChange={(e) => { setMonth(e.value); }}
          />
          <TyInput
            name="year"
            label="Rok"
            type="number"
            inputMode="numeric"
            pattern="^[0-9]+$"
            initialValue={currentYear}
            step="1"
            min={0}
            max={currentYear}
            onChange={(e) => { setYear(Number(e.value)); }}
          />
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <SelectEmployer
              name="company"
              initialValue={company}
              label="Firma"
              required="Povinné pole"
              autoSelectSingle
              isClearable
              personId={Number(personId)}
              from={from}
              to={to}
              onChange={(state) => {
                setCompany(state.value as CompanyData);
              }}
            />
          </Protect>
          <TySelect
            initialValue={allTypes}
            name="type"
            label="Druh výplaty"
            options={types}
            onChange={(e) => { setType(e.value); }}
          />
        </Form>
      </Card>
      {from && to && company && personId ? (
        <>
          <SalaryCalculation personId={personId} companyId={company.id} from={from} to={to} salaryType={type.value} />
          <PaymentList personId={personId} companyId={company.id} from={from} to={to} salaryType={type.value} />
        </>
      ) : ''}
    </>
  );
};

export default UserWallet;
