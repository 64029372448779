import { message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { resetPasswordThunk } from './utils/resetPasswordSlice';
import { ErrorAlert } from '../../components/alerts';
import Container from '../../containers';
import ResetContainer from '../../containers/reset';
import {
  signOutUserThunk,
  selectIsAuthenticated,
} from '../../data/store/slices/auth';
import { removeUserAction } from '../../data/store/slices/user';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useNavigate();
  const isUserAuthenticated = useSelector(selectIsAuthenticated);

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const token = new URLSearchParams(location.search).get('token');

  const onFinish = async (credentials) => {
    const { password, confirmPassword } = credentials;

    if (password !== confirmPassword) {
      ErrorAlert('Hesla nejsou stejná. Prosím opakujte', 10);
    } else {
      if (isUserAuthenticated) {
        dispatch(removeUserAction());
        dispatch(signOutUserThunk());
      }
      setLoading(true);
      await dispatch(resetPasswordThunk({ token, password })).then(({ error }) => {
        if (!error) {
          setEmailSent(true);
        } else {
          setEmailSent(false);
          message.error('Neplatný token.');
        }
      });
      setLoading(false);
    }
  };

  const onFinishFailed = () => {
    message.error('Nastala neočekávaná chyba, opakujte akci později prosím');
  };

  if (!token) {
    history('/signin');
  }

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
      background="#FEFEFE"
    >
      <ResetContainer
        loading={loading}
        emailSent={emailSent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </Container>
  );
};

export default ResetPassword;
