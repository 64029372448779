import { useFieldState } from 'informed';
import { HelperText } from '../text/components';

type InputErrorProps = {
  name: string;
};

export const TyInputError = ({ name }: InputErrorProps) => {
  const fieldState = useFieldState(name);
  const showElement = fieldState.error && fieldState.showError;

  if (showElement)
    return (
      <HelperText className="text-system-front-error-text">
        {String(fieldState.error)}
      </HelperText>
    );

  return null;
};
