import { PersonDocumentData } from '@tymbe/schema/person-document.interface';
import { isPaginated } from '@tymbe/utils/isPaginated';
import { ComponentProps, useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import DocumentForm, { NewDocumentFormValues } from './DocumentForm';
import feathersClient from '../../../../../apiClient';
import Protect from '../../../../../apiClient/Protect';
import { DangerButton } from '../../../../../components/buttons';
import Spinner from '../../../../../components/Spinner';
import { PageTitle } from '../../../../../components/texts';
import styles from '../../../../../components/TyAdvancedSearch/Components/Layout.module.css';
import { Roles } from '../../../../../utils/enums';

type EditDocumentProps = {
  id: number;
};

const EditDocument = ({ id }: EditDocumentProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const personDocument = useQuery<PersonDocumentData>(
    ['person-document', id],
    async () => feathersClient.service('person-document').get(id, { query: { $eager: '[documentType, personDocumentFile, company]' } }),
    {
      staleTime: 1000 * 60 * 5,
    },
  );

  const { mutateAsync: updateDocument, isLoading } = useMutation(
    ['create-person-document'],
    async (body: Partial<PersonDocumentData>) => feathersClient.service('person-document').patch(id, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      query: { $eager: '[documentType, personDocumentFile, company]' },
    }),
    {
      onSuccess: async (data) => {
        setShowModal(false);
        queryClient.setQueriesData(['person-documents'], (input: unknown) => {
          if (isPaginated<PersonDocumentData>(input)) {
            return {
              ...input,
              data: input.data.map((x) => {
                if (x.id === id) {
                  return { ...x, ...data };
                }
                return x;
              }),
            };
          }
          return input;
        });
        await queryClient.invalidateQueries(['person-document']);
        await queryClient.invalidateQueries(['person-documents']);
      },
    },
  );

  type OnSubmit = ComponentProps<typeof DocumentForm>['onSubmit'];
  const onSubmit: OnSubmit = async (state) => {
    const { modified } = state;
    const { document_type, fileBlob, ...rest } = modified;
    const data: Partial<PersonDocumentData> = {
      ...rest,
    };

    if (document_type && !Number.isNaN(document_type.value)) {
      data.document_type_id = Number(document_type.value);
    }

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (key === 'uploadedFiles') {
        if (Array.isArray(value)) {
          value.forEach((x) => {
            formData.append('files', x.blob);
          });
        } else {
          formData.append('files', value);
        }
        return;
      }
      formData.append(key, value);
    });

    await updateDocument(formData as any);
  };

  const initialDocument: Partial<NewDocumentFormValues> = {
    ...personDocument.data,
    document_type: personDocument.data?.documentType && { label: personDocument.data.documentType.name, value: String(personDocument.data.documentType.id) },
  };

  return (
    <Protect
      auth={[
        Roles.SUPER_ADMIN,
        Roles.TYMBE_ADMIN,
      ]}
      redirect={false}
    >
      <DangerButton onClick={() => setShowModal(true)}>
        Editovat
      </DangerButton>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="My dialog"
        className={styles.modal}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <div className="p-4 relative">
          <PageTitle>Upravit dokument - {personDocument.data?.name}</PageTitle>
          <DocumentForm
            document={initialDocument}
            onSubmit={onSubmit}
            onCancel={() => setShowModal(false)}
            disabled={isLoading}
          />
          <Spinner show={isLoading} absolute className="size-10 top-auto left-48 m-4" />
        </div>
      </Modal>
    </Protect>
  );
};

export default EditDocument;
