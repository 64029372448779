import moment from 'moment';
import { Fragment } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import feathersClient from '../../apiClient';
import { ApplicationState, ShiftData } from '../../types/TymbeApi';
import { ContactType } from '../../utils/enums';

const SelectedShiftsReport = () => {
  const history = useLocation();
  const { ids } = history.state;

  const { data: shiftData } = useQuery(
    'selectedShiftsExport',
    async () => feathersClient.service('shift').find({
      query: {
        $eager: '[manShift.activeApplication.person.[personData, personPerks, contact], branchoffice.parent]',
        'shift.id': { $in: ids },
        $appendJuniorProp: true,
      },
    }),
  );

  return (
    <div style={{ padding: '0 50px' }}>
      <h1 style={{ textAlign: 'center' }}>Tymbe report</h1>
      <table className="ty-print-table">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Čas od</th>
            <th>Čas do</th>
            <th>Provozovna</th>
            <th>Oddělení</th>
            <th>ID Tymber</th>
            <th>Příjmení</th>
            <th>Jméno</th>
            <th>Datum narození</th>
            <th>Mobil</th>
            <th>Nováček</th>
          </tr>
        </thead>
        <tbody>
          {shiftData?.data.map((s: ShiftData) => (
            <Fragment key={s.id}>
              {s.manShift?.map((ms) => ms.activeApplication)
                ?.filter((x) => x?.state === ApplicationState.CONFIRMED).map((app) => (
                  <tr style={{ borderBottom: '1px solid grey', height: '25px' }}>
                    <td>{moment(s?.start_time).format('DD.MM.YYYY')}</td>
                    <td>{moment(s?.start_time).format('HH:mm')}</td>
                    <td>{moment(s?.end_time).format('HH:mm')}</td>
                    <td>{s.branchoffice?.parent?.name}</td>
                    <td>{s.branchoffice?.name}</td>
                    <td>{app?.person?.id}</td>
                    <td>{app?.person?.last_name}</td>
                    <td>{app?.person?.first_name}</td>
                    <td>{moment(app?.person?.personData?.birthdate).format('DD.MM.YYYY')}</td>
                    <td>
                      {app?.person?.contact
                        ?.find((x) => x.type === ContactType.MOBILE_PHONE)?.value}
                    </td>
                    <td>
                      {app?.isCompanyJunior
                        ? 'ano'
                        : 'ne'}
                    </td>
                  </tr>
                ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default SelectedShiftsReport;
