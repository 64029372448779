import { Navigate, useParams } from 'react-router-dom';

import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import UserDetailContainer from './components/UserDetailContainer';

const UserDetailPage = () => {
  const { personId } = useParams();

  if (!personId) return <Navigate to="/tymber" />;

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <UserDetailContainer personId={personId} />
      </Wrapper>
    </Container>
  );
};

export default UserDetailPage;
