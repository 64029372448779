import { Query } from '@feathersjs/feathers';
import { CompanyBlockedUserData } from '@tymbe/schema/company-blocked-user.interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import DeleteBlock from './components/DeleteBlock';
import EditBlock from './components/EditBlock';
import NewBlockButton from './components/NewBlockButton';
import feathersClient from '../../../../apiClient';
import Protect from '../../../../apiClient/Protect';
import { SuccessAlert } from '../../../../components/alerts';
import Card from '../../../../components/Layout/Card';
import { TablePagination, useTablePagination } from '../../../../components/Table';
import { BlockReasonDisplay, Roles } from '../../../../utils/enums';

const UserBlocks = () => {
  const { personId } = useParams();
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const [query, setQuery] = useState<Query>({});
  const qClient = useQueryClient();

  const { data: personBlocks } = useQuery(['company-blocked-user', query], async () =>
    feathersClient.service('company-blocked-user').find({
      query,
    }));

  useEffect(() => {
    const q = {
      person_id: personId,
      $eager: '[company, branchoffice, creator]',
      $sort: { created_at: -1 },
      $skip: paginationStart,
      $limit: paginationPageSize,
    };
    setQuery(q);
  }, [paginationPageSize, paginationStart, personId]);

  const successHandler = (successText: string) => {
    qClient.invalidateQueries(['company-blocked-user', query]);
    SuccessAlert(successText);
  };

  return (
    <Card>
      <NewBlockButton />
      <div>
        {personBlocks?.data.map((block: CompanyBlockedUserData) => (
          <div className="group pb-3">
            <div className="border-secondary-200 border rounded-lg">
              <div className="flex justify-between p-4">
                <div className="flex gap-5">
                  <div className="w-40 shrink-0">
                    <div className="text-secondary pb-1 font-semibold text-sm leading-5">
                      {block.company?.name}
                    </div>
                    <div className="text-secondary pb-1 font-semibold text-sm leading-5">
                      {block.branchoffice?.name}
                    </div>
                    <div className="text-secondary pb-1 text-sm leading-5">
                      {moment(block.created_at).format('DD.MM.YYYY hh:mm')}
                    </div>
                  </div>
                  <div>
                    <div className="ml-10 pb-1 font-semibold text-sm max-h-36 overflow-y-auto">
                      {BlockReasonDisplay[block.reason]} (-{block.severity})
                    </div>
                    <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
                      Vytvořeno uživatelem {block.creator?.first_name} {block.creator?.last_name}
                    </div>
                    <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
                      {block.note}
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-20">
                  <div className="flex hidden group-hover:flex">
                    <Protect
                      auth={[
                        Roles.SUPER_ADMIN,
                        Roles.TYMBE_ADMIN,
                      ]}
                      redirect={false}
                    >
                      <EditBlock
                        block={block}
                        onSuccess={() => successHandler('Blokace upravena')}
                      />
                    </Protect>
                    <DeleteBlock
                      blockId={block.id}
                      onSuccess={() => successHandler('Blokace smazána')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <TablePagination
        {...setPageParams()}
        rowsCount={personBlocks?.total}
      />
    </Card>
  );
};

export default UserBlocks;
