import { Paginated, Query } from '@feathersjs/feathers';
import { PaySupplementAmountType, PaySupplementRule } from '@tymbe/schema/pay-supplement.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { ShiftTemplateData } from '@tymbe/schema/shift-template.interface';
import { Affix, Col, Row } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { Form, FormState } from 'informed';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import ChoosePropertyModal from './components/ChoosePropertyModal';
import GlobalEditPropertyModal from './components/GlobalEditPropertyModal';
import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import Protect from '../../../../apiClient/Protect';
import AdminTable from '../../../../components/AdminTable';
import { SecondaryButton } from '../../../../components/buttons';
import {
  EditIcon,
  ExclamationCircleOutlined,
  PlusOutlinedIcon,
} from '../../../../components/icons';
import TyCheckbox from '../../../../components/inputs/TyCheckbox';
import Card from '../../../../components/Layout/Card/Card';
import SearchBox from '../../../../components/search/SearchBox';
import HoverRowEditDeleteButtons from '../../../../components/tableRenders/HoverRowEditDeleteButtons';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { RolesData } from '../../../../types/TymbeApi';
import { DEFAULT_SORT } from '../../../../utils/constants';
import { Roles } from '../../../../utils/enums';
import Emoji from '../../../calendar/components/Emoji';
import AlertModal from '@tymbe/ty-components/modals/AlertModal';

const ShiftTemplateList = () => {
  const { companyId } = useParams();
  const [query, setQuery] = useState<Query>({ company_id: companyId });
  const { data: shiftTemplateData, isLoading, refetch } = useQuery(
    ['fetchShiftTemplate', query],
    async () => feathersClient.service('shift-template').find({ query }),
    { enabled: !!companyId },
  );
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [paginationPageSize, setPaginationPageSize] = useState(allSearchParams.pageSize || 10);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(allSearchParams.page || 1);
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<{ quickFilter: string[] } | undefined>(
    allSearchParams.search ? { quickFilter: allSearchParams.search?.split(' ') } : undefined,
  );
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_SORT);
  const [showPublished, setShowPublished] = useState<boolean>(
    allSearchParams.showPublished === undefined ? true : allSearchParams.showPublished,
  );
  const [showUnpublished, setShowUnpublished] = useState<boolean>(
    allSearchParams.showUnpublished === undefined ? true : allSearchParams.showUnpublished,
  );
  const [selectedRows, setSelectedRows] = useState<ShiftTemplateData[]>([]);
  const [showChoosePropertyModal, setShowChoosePropertyModal] = useState(false);
  const [showGlobalEditModal, setShowGlobalEditModal] = useState(false);
  const [globalEditParam, setGlobalEditParam] = useState<PaySupplementType | undefined>(undefined);
  const user = useUser();

  useEffect(() => {
    const q = {
      company_id: companyId,
      publish_at: showPublished !== showUnpublished ? { $null: showUnpublished } : undefined,
      $skip: paginationPageSize * (paginationCurrentPage - 1),
      $limit: paginationPageSize,
      $modify: searchParams,
      $sort: sort,
    };

    setQuery(q);
  }, [companyId, paginationCurrentPage, paginationPageSize, searchParams, sort, showPublished, showUnpublished]);

  const onParamsSearch = (search: string) => {
    setPaginationCurrentPage(1);
    const quickFilter = search?.split(' ') ?? undefined;
    setSearchParams({ quickFilter });
    setUrlParamsHandler({ page: 1, search });
  };

  const { data: company } = useQuery(
    ['fetchCompany', companyId],
    async () => feathersClient.service('company')
      .get(companyId!, { query: { $eager: '[address, contactPerson]' } }),
    { enabled: !!companyId },
  );

  const [modalProps, setModalProps] = useState<ComponentProps<typeof AlertModal>>();
  const showConfirmModal = async (record: ShiftTemplateData) => new Promise<void>((resolve, reject) => {
    setModalProps({
      title: <><ExclamationCircleOutlined /> Potvrdit smazání pracovní pozice</>,
      onConfirm: () => {
        resolve();
        setModalProps(undefined);
      },
      onCancel: () => {
        reject();
        setModalProps(undefined);
      },
      message: `Chcete opravdu odstranit pracovní pozici: ${record.template_name}?`,
      showModal: true,
    });
  });

  const onClickRemoveRecord = async (record: ShiftTemplateData) => {
    await feathersClient.service('shift-template').remove(record.id)
      .then(() => refetch());
  };

  const userCanSeeBonuses = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ].includes(role.slug));

  const hideDeleteButton = (userRole: RolesData[]) => {
    if (company?.is_readonly) {
      return !userRole.some((role) => role.slug === Roles.SUPER_ADMIN);
    }
    return !userRole.some((role) =>
      [Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.COMPANY, Roles.BRANCHOFFICE_MANAGER].includes(role.slug));
  };

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    setUrlParamsHandler({ page: newPage, pageSize: newPageSize });
    setPaginationPageSize(newPageSize);
    setPaginationCurrentPage(newPage);
  };

  const shiftTemplatesColumns = [
    {
      title: 'Název vzoru',
      dataIndex: 'template_name',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      sorter: true,
      align: 'left',
      render: (_text: string, record: ShiftTemplateData) => <><Emoji className="inline-flex size-4" emoji={record.emoji ?? ''} /> {record.template_name}</>,
    },
    {
      title: 'Alias',
      dataIndex: 'name',
      render: (_text: string, record: ShiftTemplateData) => `${record.name || ''}`,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Odměna',
      dataIndex: 'payment_base',
      render: (_text: string, record: ShiftTemplateData) => <>{(+record.payment_base).toFixed(2)} Kč</>,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    ...userCanSeeBonuses ? [{
      title: 'Bonus',
      dataIndex: 'credits',
      render: (_text: string, record: ShiftTemplateData) => <>{(+record.credits).toFixed(2)} Kč</>,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Pozvánka bonus',
      align: 'left',
      dataIndex: 'invitation_credits',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_text: string, record: ShiftTemplateData) => <>{(+record.invitation_credits).toFixed(2)} Kč</>,
    }] : [],
  ];

  // we want to have edit/delete buttons in the last existing cell of the row.
  shiftTemplatesColumns[shiftTemplatesColumns.length - 1].render = (text: string, record) => (
    <div className="d-flex justify-content-between align-center">
      {userCanSeeBonuses ? `${(+record.invitation_credits).toFixed(2)} Kč` : `${(+record.payment_base).toFixed(2)} Kč`}
      <Protect
        auth={[
          Roles.SUPER_ADMIN,
          Roles.TYMBE_ADMIN,
          Roles.ADMIN,
          Roles.TYMBE_COORDINATOR,
          Roles.COMPANY,
          Roles.BRANCHOFFICE_MANAGER,
        ]}
        redirect={false}
      >
        <HoverRowEditDeleteButtons
          editLink={`/company/${companyId}/shift-template/${record.id}`}
          removeTimeoutFunction={() => onClickRemoveRecord(record)}
          hideDelete={hideDeleteButton(user.role)}
          onRemoveOnClick={() => showConfirmModal(record)}
          onRemoveCancel={() => {}}
          removeNotificationMessage={`Pracovní pozice "${record.name}" bude smazána`}
        />
      </Protect>
    </div>
  );

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters:Record<string, FilterValue>,
    sorter: SorterResult<unknown>,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'template_name':
          setSort({ template_name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'name':
          setSort({ name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'payment_base':
          setSort({ payment_base: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'credits':
          setSort({ credits: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'invitation_credits':
          setSort({ invitation_credits: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  const toggleRowSelection = useCallback(
    (selected: ShiftTemplateData | ShiftTemplateData[]) => {
      const records = Array.isArray(selected) ? selected : [selected];

      // turning off - on selecting any already selected or if no globally edited property defined
      if (selectedRows.length || !globalEditParam) {
        setSelectedRows([]);
        return;
      }

      const key = globalEditParam;
      // turning on - find others with same value of globally edited property
      const newSelectedRows = (shiftTemplateData as Paginated<ShiftTemplateData>)?.data
        .filter((s) => s.pay_supplement?.[key]?.value === records[0].pay_supplement?.[key]?.value
        && s.pay_supplement?.[key]?.type === records[0].pay_supplement?.[key]?.type);

      setSelectedRows(newSelectedRows);
    },
    [selectedRows, globalEditParam, shiftTemplateData],
  );

  const onChoosePropertyModalClose = () => {
    setShowChoosePropertyModal(false);
    setGlobalEditParam(undefined);
    setSelectedRows([]);
  };

  const onChoosePropertyModalSubmit = async ({ values }: FormState<{ editedProperty: PaySupplementType }>) => {
    setShowChoosePropertyModal(false);
    if (globalEditParam !== values.editedProperty) {
      setSelectedRows([]);
    }
    setGlobalEditParam(values.editedProperty);
  };

  const onGlobalEditModalClose = () => {
    setShowGlobalEditModal(false);
  };

  const onGlobalEditModalSubmit = async ({ values }: FormState<{
    pay_supplement: Partial<Record<PaySupplementType, PaySupplementRule>>
  }>) => {
    if (globalEditParam) {
      const promiseArr = (shiftTemplateData as Paginated<ShiftTemplateData>)?.data
        .filter((s) => selectedRows.includes(s))
        .map(async (s) => {
          const newPaySupplementItem = {
            applies_to_entire_shift: values.pay_supplement[globalEditParam]?.applies_to_entire_shift,
            value: values.pay_supplement[globalEditParam]?.value,
            type: values.pay_supplement[globalEditParam]?.type,
          };

          return feathersClient.service('shift-template')
            .patch(s.id, { pay_supplement: { ...s.pay_supplement, [globalEditParam]: newPaySupplementItem } });
        });
      await Promise.all(promiseArr);
    }

    setShowGlobalEditModal(false);
    setSelectedRows([]);
    setGlobalEditParam(undefined);
  };

  const onHandleGlobalEdit = () => {
    setShowGlobalEditModal(!!globalEditParam);
  };

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <div className="flex justify-end align-center">
          <Form
            name="shift_templates_filter"
          >
            <TyCheckbox
              className="text-sm"
              id="show_published"
              name="show_published"
              onChange={(e) => { setShowPublished(e.value); setUrlParamsHandler({ showPublished: e.value }); }}
              label="Zveřejněné"
              initialValue={showPublished}
            />
            <TyCheckbox
              className="text-sm"
              id="show_unpublished"
              name="show_unpublished"
              onChange={(e) => { setShowUnpublished(e.value); setUrlParamsHandler({ showUnpublished: e.value }); }}
              label="Nezveřejněné"
              initialValue={showUnpublished}
            />
          </Form>
          {/* TODO: Reafactor to proper HTML5 */}
          <Protect
            auth={company?.is_readonly ? [
              Roles.SUPER_ADMIN,
            ] : [
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.COMPANY,
              Roles.BRANCHOFFICE_MANAGER,
            ]}
            redirect={false}
          >
            <Link to={`/company/${companyId}/shift-template/create`}>
              <SecondaryButton className="flex flex-row justify-center align-center gap-2">
                <PlusOutlinedIcon />
                Nová pozice
              </SecondaryButton>
            </Link>
            <SecondaryButton
              className="flex flex-row justify-center align-center gap-2"
              onClick={() => setShowChoosePropertyModal(true)}
            >
              <EditIcon iconcolor="currentColor" />
              Globální editace
            </SecondaryButton>
          </Protect>
          <SearchBox
            name="companyShiftTemplateSearchBox"
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onParamsSearch}
            loading={isLoading}
          />
        </div>

        <ChoosePropertyModal
          showModal={showChoosePropertyModal}
          initialValues={{ editedProperty: PaySupplementType.Holiday }}
          onClose={onChoosePropertyModalClose}
          onSubmit={onChoosePropertyModalSubmit}
        />

        {(!!globalEditParam && selectedRows.length > 0) && (
          <GlobalEditPropertyModal
            showModal={showGlobalEditModal}
            paySupplementType={globalEditParam!}
            initialValues={{
              paySupplement: selectedRows[0]?.pay_supplement?.[globalEditParam!]
                ?? { applies_to_entire_shift: true, type: PaySupplementAmountType.Fixed, value: 0 },
            }}
            onClose={onGlobalEditModalClose}
            onSubmit={onGlobalEditModalSubmit}
          />
        )}

        <AdminTable
          rowSelection={globalEditParam ? {
            selectedRowKeys: selectedRows.map((r) => r.id),
            onSelect: toggleRowSelection,
            hideSelectAll: true,
            getCheckboxProps: (record: ShiftTemplateData) => ({
              disabled: selectedRows.length && !selectedRows.includes(record),
            }),
          } : null}
          rowKey={(record: ShiftTemplateData) => record.id}
          columns={shiftTemplatesColumns}
          bordered
          size="small"
          className="ty-table"
          rowClassName="ty-row-hover"
          onPaginationChange={onPaginationChange}
          pageSizeOptions={[10, 20, 50, 100]}
          currentPage={paginationCurrentPage}
          onChange={onTableChange}
          data={{
            total: (shiftTemplateData as Paginated<ShiftTemplateData>)?.total || 0,
            skip: paginationPageSize * (paginationCurrentPage - 1),
            limit: paginationPageSize,
            data: (shiftTemplateData as Paginated<ShiftTemplateData>)?.data || [],
          }}
        />
      </div>

      { selectedRows.length > 0 && (
        <Affix
          className="fixed bottom-2 right-2"
          style={{ position: 'fixed', bottom: '30px', right: '30px' }}
          offsetBottom={18}
        >
          {/* TODO OD - fix BG color */}
          <Row className="rounded-xl px-8 py-2 max-w-2xl bg-white">
            <Col xs={24} md={14} className="flex items-center justify-center">
              <div className="font-sans text-sm not-italic font-medium">
                Bylo vybráno
                {' '}
                {selectedRows.length}
                {' '}
                záznamů
              </div>
            </Col>

            <Col xs={24} md={10} className="flex flex-row">
              <div className="flex justify-end items-center p-1">
                <SecondaryButton
                  buttontext="Upravit záznamy"
                  onClick={() => onHandleGlobalEdit()}
                />
              </div>
            </Col>
          </Row>
        </Affix>
      )}
      {modalProps?.showModal && <AlertModal {...modalProps} />}
    </Card>
  );
};

export default ShiftTemplateList;
