import { Paginated, Query } from '@feathersjs/feathers';
import { PersonDocumentFileData } from '@tymbe/schema/person-document-file.interface';
import ImageModal from '@tymbe/ty-components/modals/ImageModal';
import { Form } from 'informed';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import DocumentInfo from './components/DocumentInfo';
import NewDocument from './components/NewDocument';
import feathersClient from '../../../../apiClient';
import TyAsyncSelect, { DefaultAsyncSelectOption } from '../../../../components/inputs/TyAsyncSelect';
import TyCheckbox from '../../../../components/inputs/TyCheckbox';
import Card from '../../../../components/Layout/Card';
import { TablePagination, useTablePagination } from '../../../../components/Table';
import factoryLoadOptions from '../../../../components/TyAdvancedSearch/Libs/factoryLoadOptions';
import { Option } from '../../../../components/TyAdvancedSearch/Libs/transform';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { PersonDocumentData } from '../../../../types/TymbeApi';

const UserDocumentsList = () => {
  const { personId } = useParams();
  const {
    setPageParams,
    pageStart: paginationStart,
    pageSize: paginationPageSize,
  } = useTablePagination();
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();
  const [documentTypes, setDocumentTypes] = useState(
    allSearchParams.documentTypes || undefined,
  );
  const [documentContent, setDocumentContent] = useState<Blob | undefined>(
    undefined,
  );
  const [qry, setQry] = useState<Query>({
    'person_document.person_id': personId,
    $eager: '[documentType, personDocumentFile, company]',
    $joinRelation: '[documentType]',
    $sort: { updated_at: -1 },
    $skip: paginationStart,
    $limit: paginationPageSize,
    'documentType.name': {
      $in: documentTypes?.map((doc: DefaultAsyncSelectOption) => doc.value),
    },
  });
  const queryClient = useQueryClient();

  const [validValue, setValidValue] = useState(
    allSearchParams.onlyValidDocuments || false,
  );

  const now = moment();

  const { data: personDocuments, isLoading } = useQuery(
    ['person-documents', qry],
    async () =>
      feathersClient.service('person-document').find({
        query: qry,
      }) as Promise<Paginated<PersonDocumentData>>,
    {
      enabled: !!personId,
      onSuccess: (data) => {
        data.data.forEach((doc: PersonDocumentData) => {
          queryClient.setQueryData(['person-document', doc.id], doc);
        });
      },
    },
  );

  const { mutateAsync: getDocument } = useMutation(
    ['load-document'],
    async (id: string) =>
      feathersClient.service('bucket').get(id, {
        query: {
          $download: true,
        },
        connection: {
          responseType: 'blob',
        },
      }),
  );

  useEffect(() => {
    const q = {
      'person_document.person_id': personId,
      $eager: '[documentType, personDocumentFile, company]',
      $joinRelation: '[documentType]',
      $sort: { updated_at: -1 },
      $skip: paginationStart,
      $limit: paginationPageSize,
      'documentType.name': {
        $in: documentTypes?.map((doc: DefaultAsyncSelectOption) => doc.value),
      },
    };

    if (validValue) {
      setQry({
        ...q,
        'person_document.valid_to': { $gte: now.startOf('day').toISOString() },
        'person_document.valid_from': { $lte: now.endOf('day').toISOString() },
        'person_document.approved': true,
      });
    } else {
      setQry(q);
    }
  }, [
    paginationPageSize,
    paginationStart,
    personId,
    documentTypes,
    validValue,
  ]);

  const loadDocumentOptions = factoryLoadOptions(
    'document-type',
    ({ search, options }) => {
      const query: Query = {
        'personDocument.person_id': personId,
        $joinRelation: '[personDocument]',
        $select: ['document_type.name'],
        $distinct: ['document_type.name'],
        'document_type.name': { $ilike: `%${search}%` },
        $skip: options.length,
      };
      return { query };
    },
    (v: { name: string }) => ({ label: `${v.name}`, value: v.name }),
  );

  const onValidDocumentsChange = (value: boolean) => {
    setValidValue(value);
    setUrlParamsHandler({ onlyValidDocuments: value, page: 1 });
  };

  const onDocumentDetailClick = async (doc: PersonDocumentFileData) => {
    const docContent: Blob = await getDocument(doc.file);

    if (docContent.type === 'application/pdf') {
      window.open(URL.createObjectURL(docContent), '_blank', 'noopener,noreferrer');

      return;
    }

    if (docContent.type.startsWith('image/')) {
      setDocumentContent(docContent);
    }
  };

  return (
    <Card>
      <ImageModal
        document={documentContent}
        isOpen={!!documentContent}
        onRequestClose={() => setDocumentContent(undefined)}
      />
      <div className="flex justify-between content-center">
        <Form className="flex justify-end pb-3">
          <TyAsyncSelect
            loadOptions={loadDocumentOptions}
            name="documents"
            label="Hledat typ dokumentu"
            isClearable
            isMulti
            initialValue={documentTypes}
            onChange={(e) => {
              setDocumentTypes(e.value as Option[]);
              setUrlParamsHandler({ documentTypes: e.value as Option[] });
            }}
          />
          <TyCheckbox
            className="pl-5 self-center"
            name="valid_documents"
            label="Pouze platné dokumenty"
            initialValue={validValue}
            onChange={(e) => onValidDocumentsChange(e.value)}
          />
        </Form>
        <NewDocument />
      </div>
      <div>
        {personDocuments?.data.map((document: PersonDocumentData) => (
          <div key={document.id} className="pb-3">
            <DocumentInfo
              document={document}
              onDocumentClick={onDocumentDetailClick}
            />
          </div>
        ))}
      </div>
      {!isLoading && !personDocuments?.data.length && documentTypes?.length ? (
        <div className="text-center font-bold p-5">
          Nelze zobrazit Dohody ani jiné dokumenty, které nejsou podepsané mezi
          Vaší firmou a Tymberem.
        </div>
      ) : null}
      <TablePagination
        {...setPageParams()}
        rowsCount={personDocuments?.total}
      />
    </Card>
  );
};

export default UserDocumentsList;
