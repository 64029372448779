import { ApplicationData } from '@tymbe/schema/application.interface';
import { ApplicationState } from '@tymbe/schema/enums';
import { ManShiftData } from '@tymbe/schema/man-shift.interface';
import moment from 'moment';

import { ShiftState } from '../../../types/utils';
import shiftStyleSettings from '../../user/@personId/shifts/utils/ShiftStyleSettings';

export interface OrdersTableCategory {
  title: string;
  titleColor: string;
  data?: ApplicationData[];
}

type AppCategories = ApplicationState | 'Invitations' | 'Expired';

export type OrderTableCategoryTuple = {
  [key in AppCategories]?: OrdersTableCategory;
};

const createBlankCategory = (config: OrdersTableCategory) => ({
  ...config,
  data: [],
});

export const mapOrderTableExpandedCategories = (manShifts: ManShiftData[]) => {
  const orderTableCategories: OrderTableCategoryTuple = {
    [ApplicationState.CONFIRMED]: createBlankCategory({
      title: 'Přihlášení',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CONFIRMED].textColour,
    }),
    Invitations: createBlankCategory({
      title: 'Zatím nepřijaté pozvánky',
      titleColor: shiftStyleSettings[ShiftState.INVITATION].textColour,
    }),
    [ApplicationState.CANCELED_EARLY]: createBlankCategory({
      title: 'Zrušeno v termínu',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CANCELED_EARLY].textColour,
    }),
    [ApplicationState.REJECTED]: createBlankCategory({
      title: 'Odmítnuté pozvánky',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_REJECTED].textColour,
    }),
    [ApplicationState.CANCELED_LATE]: createBlankCategory({
      title: 'Zrušeno po termínu',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CANCELED_LATE].textColour,
    }),
    [ApplicationState.SYSTEM_CANCELED]: createBlankCategory({
      title: 'Zrušeno systémem',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_SYSTEM_CANCELED].textColour,
    }),
    Expired: createBlankCategory({
      title: 'Pozvánka expirovala',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_SYSTEM_CANCELED].textColour,
    }),
  };

  const shiftApplications: ApplicationData[] = manShifts.flatMap((manShift) => manShift.application || []);

  // get latest shift application based on created_at and grouped by person.id
  const latestShiftApplicationsByPersonId = shiftApplications.reduce((acc: ApplicationData[], currentApplication) => {
    const existingApplication = acc.find((item) => item.person_id === currentApplication.person_id);
    if (!existingApplication) {
      acc.push(currentApplication);
    } else if (moment(currentApplication.created_at).isAfter(moment(existingApplication.created_at))) {
      acc.splice(acc.indexOf(existingApplication), 1);
      acc.push(currentApplication);
    }
    return acc;
  }, []);

  // sort and display latest shift applications in correct category
  latestShiftApplicationsByPersonId.forEach((currentApplication) => {
    const isExpired = moment(currentApplication.deleted_at).isBefore(moment()) && currentApplication.state === null;
    const targetCategory = isExpired ? orderTableCategories.Expired : orderTableCategories[currentApplication.state || 'Invitations'];
    targetCategory?.data?.push(currentApplication);
  });

  return orderTableCategories;
};
