import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import feathersClient from '../../../../../apiClient';

export const loadDocumentTypesThunk = createAsyncThunk(
  'company/companyDocumentType',
  async ({ company_id, query }) => feathersClient.service('document-type').find({
    query: { company_id, ...query },
  }),
);

const loadDocumentTypesReducer = {
  [loadDocumentTypesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  }),
  [loadDocumentTypesThunk.rejected]: (state, action) => ({
    ...state,
    loading: true,
    error: action.error.message,
    data: null,
  }),
  [loadDocumentTypesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    data: action.payload.data,
  }),
};

export const createDocumentTypeThunk = createAsyncThunk(
  'company/createDocumentType',
  (body) => feathersClient.service('document-type').create(body),
);

const creatDocumentTypeReducer = {
  [createDocumentTypeThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),

  [createDocumentTypeThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [createDocumentTypeThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...state.data || [], ...action.payload.data || []],
  }),
};

const documentTypeSlice = createSlice({
  name: 'documentTypeContainer',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data };
    },
  },

  extraReducers: {
    ...loadDocumentTypesReducer,
    ...creatDocumentTypeReducer,
  },
});

export const selectDocumentTypesForCompany = (state) => state.data;

export const selectDocumentTypesForCompanyLoading = createSelector(
  (state) => state.views.companyDocumentTypes.loading,
  (loading) => {
    if (loading !== null) return loading;
    return undefined;
  },
);

export default documentTypeSlice.reducer;
export const { actions } = documentTypeSlice;
