import { useFormApi } from 'informed';
import { useEffect, useState } from 'react';

import { checkFileSize } from './blob.utils';
import feathersClient from '../../apiClient';
import getBucketServiceParams from '../../utils/getBucketServiceParams';
import { ErrorAlert } from '../alerts';
import UploadedImage from '../upload/UploadedImage/UploadedImage';
import UploadImage from '../upload/UploadImage/UploadImage';

export interface StampFormValues {
  stampBlob: Blob | null,
}

type StampFormProps = {
  stamp?: string | null;
};

const StampForm = ({ stamp }: StampFormProps) => {
  const formApi = useFormApi<StampFormValues>();
  const [stampBlob, setStampBlob] = useState<Blob | null>(null);
  const blobService = feathersClient.service('bucket');

  useEffect(() => {
    if (!stamp) return undefined;
    const controller = new AbortController();
    blobService.get(stamp, getBucketServiceParams(controller))
      .then(setStampBlob);

    return () => controller.abort();
  }, [blobService, stamp]);

  const onSetStampBlob = (blob: Blob) => {
    if (checkFileSize(blob)) {
      setStampBlob(blob);
      formApi.setValue('stampBlob', blob);
    } else {
      ErrorAlert('Soubor musí být menší než 5MB!');
    }
  };

  return (
    <div id="stamp-form">
      <h4>Razítko</h4>
      <UploadImage
        allowScale
        allowRotate
        name="stamp"
        accept="image/*,.pdf"
        uploadLabel="Nahrajte razítko"
        onChange={onSetStampBlob}
        modalTitle=""
        image=""
      />

      {stampBlob && (
        <UploadedImage
          alt="Razítko - obrázek"
          image={stampBlob}
          removeImage={() => {
            formApi.setValue('stampBlob', null);
            setStampBlob(null);
          }}
        />
      )}
    </div>
  );
};

export default StampForm;
