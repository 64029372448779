import { nanoid } from '@reduxjs/toolkit';
import { useFormApi } from 'informed';

import { UPLOAD_MAX_FILE_SIZE } from '../../../utils/constants';
import { ErrorAlert } from '../../alerts';
import { IconButton } from '../../buttons';
import { TrashIcon } from '../../icons';
import { BlobWithMetadata, UploadDocumentMetadata } from '../types';
import UploadedImage from '../UploadedImage/UploadedImage';
import UploadImage from '../UploadImage/UploadImage';

const checkFileSize = (blob: Blob) => blob.size < UPLOAD_MAX_FILE_SIZE;

interface DocumentBlobFormValues {
  fileBlob: Blob[] | null,
}

interface UploadDocumentFileProps {
  setUploadedFiles: (files: BlobWithMetadata[]) => void;
  uploadedFiles: BlobWithMetadata[];
}

const UploadDocumentFile = ({ setUploadedFiles, uploadedFiles }: UploadDocumentFileProps) => {
  const formApi = useFormApi<DocumentBlobFormValues>();
  const onSetFileBlob = (blob: Blob, metadata: UploadDocumentMetadata) => {
    if (checkFileSize(blob)) {
      const file: BlobWithMetadata = { blob, ...metadata };
      if (!file.blob) return;
      const newFiles = [...uploadedFiles, file];
      setUploadedFiles(newFiles);
      formApi.setValue('fileBlob', uploadedFiles.map((x) => x.blob));
    } else {
      ErrorAlert('Soubor musí být menší než 5MB!');
    }
  };

  const onFileRemove = (field: number) => () => {
    uploadedFiles.splice(field, 1);
    setUploadedFiles([...uploadedFiles]);
    formApi.setValue('fileBlob', uploadedFiles.map((x) => x.blob));
  };

  return (
    <div>
      {uploadedFiles.map((file, index) => (
        <div className="ml-2">
          {file.type === 'application/pdf'
            ? (
              <div className="d-flex align-center">
                <h4>
                  {file.name}
                </h4>
                <IconButton
                  onClick={onFileRemove(index)}
                >
                  <TrashIcon />
                </IconButton>
              </div>
            )
            : (
              <div>
                <UploadedImage
                  key={nanoid()}
                  alt={file.name}
                  image={file.blob}
                  removeImage={onFileRemove(index)}
                  name={file.name}
                />
              </div>
            )}
          {uploadedFiles.length > 1 && index < uploadedFiles.length - 1 && <hr className="mb-3" />}
        </div>
      ))}
      {uploadedFiles.length < 3 && (
        <div
          className="mt-4"
        >
          <UploadImage
            name="files"
            accept="image/*,.pdf"
            isPreviewHidden={false}
            crop={false}
            uploadLabel="Nahrát dokument"
            onChange={onSetFileBlob}
            modalTitle=""
            image=""
          />
        </div>
      )}
    </div>
  );
};

export default UploadDocumentFile;
