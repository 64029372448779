import React from 'react';

import { PaymentAuthorizedData } from '../../../../../types/TymbeApi';

export interface PaymentDetailProps {
  payment: PaymentAuthorizedData;
}

const PaymentDetail = ({ payment }: PaymentDetailProps) => {
  const minutesToHours = (minutes: number) => Math.round(minutes / 6) / 10;

  return (
    <div className="p-4">
      <div className="flex justify-between p-4">
        <div>
          <div className="flex pl-10 pt-2">
            {payment.attendance && payment.attendance.application ? (
              <table className="w-auto border-spacing-y-1.5 border-spacing-x-5 border-separate [&_*]:text-right">
                <tbody>
                  <tr>
                    <th>Potvrzený čas:</th>
                    <td>{minutesToHours(payment.attendance.confirmed_time)} + {minutesToHours(payment.attendance.confirmed_overtime)} h</td>
                  </tr>
                  <tr>
                    <th>Základní odměna:</th>
                    <td>{Number(payment.attendance?.application?.payment_base).toFixed(2)} Kč</td>
                  </tr>
                  <tr>
                    <th>Hrubá odměna:</th>
                    <td>{minutesToHours(payment.attendance.confirmed_time) * payment.attendance.application.payment_base} Kč</td>
                  </tr>
                </tbody>
              </table>
            )
              : null}
          </div>
          <div className="flex pl-10 pt-2">
            {payment.paymentRequest?.paymentTransaction ? (
              <table className="w-auto border-spacing-y-1.5 border-spacing-x-5 border-separate [&_*]:text-right">
                <tbody>
                  <tr>
                    <th>Poznámka k platbě:</th>
                    <td>{payment.paymentRequest?.paymentTransaction?.payment_note}</td>
                  </tr>
                  <tr>
                    <th>Konstantní symbol:</th>
                    <td>{payment.paymentRequest?.paymentTransaction?.payment_constant}</td>
                  </tr>
                  <tr>
                    <th>Variabilní symbol:</th>
                    <td>{payment.paymentRequest?.paymentTransaction?.payment_variable}</td>
                  </tr>
                  <tr>
                    <th>Specifický symbol:</th>
                    <td>{payment.paymentRequest?.paymentTransaction?.payment_specific}</td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    </div>

  );
};

export default PaymentDetail;
