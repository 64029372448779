import { useMutation, useQueryClient } from 'react-query';

import apiClient from '../../../../../apiClient';
import { defaultOnError } from '../../../../../apiClient/utils';
import Button from '../../../../../components/buttons/Button';
import { RestoreIcon } from '../../../../../components/icons';
import Spinner from '../../../../../components/Spinner';

type RestorePersonUtilityProps = {
  id: number;
};

const RestorePersonUtility = ({ id }: RestorePersonUtilityProps) => {
  const queryClient = useQueryClient();
  const resoreUtility = useMutation({
    mutationFn: (utilityId: number) => apiClient.service('person-utility').patch(utilityId, { deleted_at: null }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['person-utility', data.person_id], { exact: false });
    },
    onError: defaultOnError,
  });

  return (
    <Button className="border-danger" onClick={() => resoreUtility.mutate(id)} disabled={resoreUtility.isLoading}>
      {!resoreUtility.isLoading
        ? <RestoreIcon className="w-4 h-4 fill-danger stroke-2 stroke-danger" />
        : <Spinner show className="w-4 h-4 text-danger" />}
    </Button>
  );
};

export default RestorePersonUtility;
