import classNames from 'classnames';
import { Button } from '@tymbe/components/button';
import { ButtonWithLoading } from '../button/ButtonWithLoading';
import { textStyles } from '../style/text-styles';
import { Paragraph } from '../text';
import { ModalShell } from './ModalShell';
import { ReactNode } from 'react';

interface AlertModalProps {
  showModal?: boolean;
  title: ReactNode;
  message?: ReactNode;
  children?: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  loading?: boolean;
}

const AlertModal = ({
  title,
  showModal = false,
  message,
  children,
  confirmButtonText = 'OK',
  cancelButtonText = 'Zrušit',
  onConfirm,
  onCancel,
  loading = false,
}: AlertModalProps) => {
  const confirmButtonRadius = classNames(
    'rounded-none rounded-br-xl',
    !onCancel && 'rounded-bl-xl'
  );

  return (
    <ModalShell showModal={showModal} onClose={onCancel} className="pt-4">
      <div className="flex flex-col flex-grow gap-1 items-center">
        <Paragraph variant="body2Sb" className="px-4 text-center">
          {title}
        </Paragraph>
        <Paragraph variant="captionRg" className="px-4 text-center">
          {message}
        </Paragraph>
        {children}
        <div
          className={classNames(
            'flex flex-row mt-4 justify-stretch',
            'border-t border-special-outline-divider w-full'
          )}
        >
          {onCancel && (
            <Button
              type="button"
              onClick={onCancel}
              className={classNames(
                textStyles.pCaptionSb,
                'text-base-front-1',
                'hover:bg-base-back-2',
                'w-full',
                'justify-center',
                'rounded-none rounded-bl-xl'
              )}
            >
              {cancelButtonText}
            </Button>
          )}
          <ButtonWithLoading
            type="button"
            onClick={onConfirm}
            className={classNames(
              textStyles.pCaptionSb,
              'text-system-front-error-text',
              'hover:bg-system-back-error',
              'w-full',
              'justify-center',
              onCancel && 'border-l border-special-outline-divider',
              loading && 'relative',
              confirmButtonRadius
            )}
            loading={loading}
            spinnerClassName={confirmButtonRadius}
          >
            {confirmButtonText}
          </ButtonWithLoading>
        </div>
      </div>
    </ModalShell>
  );
};

export default AlertModal;
