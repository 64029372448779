import { message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../containers';
import ForgotContainer from '../../containers/forgot';
import { selectAuthError } from '../../data/store/slices/auth';
import { forgotPasswordThunk } from '../account/accountSlice';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const onFinish = async (credentials) => {
    const { email } = credentials;
    setLoading(true);
    await dispatch(forgotPasswordThunk(email));
    if (error) return;
    setEmailSent(true);
    setLoading(false);
  };

  const onFinishFailed = () => {
    message.error('Nastala neočekávaná chyba, opakujte akci prosím později');
  };

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
      background="#FEFEFE"
      footer={false}
    >
      <ForgotContainer
        loading={loading}
        emailSent={emailSent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </Container>
  );
};

export default ForgotPasswordPage;
