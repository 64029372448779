import { PersonNoteData } from '@tymbe/schema/person-note.interface';
import Markdown from 'markdown-to-jsx';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

import DeleteNote from './DeleteNote';
import EditNote from './EditNote';
import feathersClient from '../../../../../apiClient';
import { useUser } from '../../../../../apiClient/ApiContext';
import { ErrorAlert } from '../../../../../components/alerts';
import PinIcon from '../../../../../components/icons/PinIcon';
import { RequestBody } from '../../../../../types/ReactQuery';
import { alert } from '../../../../../utils/colors';
import { Roles } from '../../../../../utils/enums';

export interface NoteInfoProps {
  note: PersonNoteData;
}

const NoteInfo = ({ note }: NoteInfoProps) => {
  const user = useUser();
  const qClient = useQueryClient();

  const { mutateAsync: patchNotePin } = useMutation(
    ['pinNote'],
    async (req: RequestBody<Partial<PersonNoteData>>): Promise<PersonNoteData> =>
      feathersClient.service('person-note').patch(req.id, req.body),
  );

  const canUserEditDeleteNote = (createdById: number) => {
    let canEdit = false;
    if (user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.COMPANY])) {
      canEdit = true;
    }
    if (user.person_id === createdById) {
      canEdit = true;
    }
    return canEdit;
  };

  const onPinClick = async (noteId: number, pinned: boolean) => {
    try {
      await patchNotePin({ id: noteId, body: { pinned: !pinned } });
      qClient.invalidateQueries(['person-note']);
    } catch {
      ErrorAlert('Při připínání poznámky došlo k chybě');
    }
  };

  return (
    <div className={`group ${note.pinned ? 'border-danger-200 border-2' : 'border-secondary-200'} border rounded-lg max-h-40 p-3`}>
      <div className="flex justify-between">
        <div className="flex">
          <div className="w-10 shrink-0 h-11">
            <div className={`${note.pinned ? '' : 'hidden group-hover:flex'}`}>
              <PinIcon
                onClick={() => onPinClick(note.id, note.pinned)}
                className="w-7 p-1 hover:w-8"
                fill={alert}
              />
            </div>
          </div>
          <div className="w-40 shrink-0">
            <div className="text-secondary font-semibold text-sm leading-5">
              {note.createdBy?.first_name} {note.createdBy?.last_name}
            </div>
            <div className="text-secondary font-semibold text-sm leading-5">
              {moment(note.created_at).format('DD.MM.YYYY HH:mm')}
            </div>
          </div>
          <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
            <Markdown>
              {note.note}
            </Markdown>
          </div>
        </div>
        {canUserEditDeleteNote(note.created_by)
       && (
         <div className="shrink-0 w-20">
           <div className="hidden group-hover:flex">
             <EditNote
               personNote={note}
             />
             <DeleteNote noteId={note.id} />
           </div>
         </div>
       )}
      </div>
    </div>
  );
};

export default NoteInfo;
