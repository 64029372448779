import { useQuery } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { PersonDocumentFileData } from '../../../../../types/TymbeApi';

export interface DocumentListProps {
  documentId: number;
  onDocumentClick?: (document: PersonDocumentFileData) => void;
}

const DocumentList = ({ documentId, onDocumentClick }: DocumentListProps) => {
  const { data: personDocuments } = useQuery(['person-document-list', documentId], async () =>
    feathersClient.service('person-document').get(documentId, {
      query: {
        $eager: '[personDocumentFile]',
      },
    }));

  // creates unique file name
  const splitBySlash = (str: string) => {
    const lastIndex = str.lastIndexOf('/');
    return str.slice(lastIndex + 1);
  };

  return (
    <>
      <div>
        {personDocuments?.personDocumentFile.length === 0 ? (
          <div className="p-4">Žádné soubory ke stažení</div>
        ) : (
          personDocuments?.personDocumentFile.map(
            (documentFile: PersonDocumentFileData) => (
              <button
                className="hover:underline"
                key={documentFile.file}
                onClick={() => onDocumentClick?.(documentFile)}
                type="button"
              >
                {splitBySlash(documentFile.file)}
              </button>
            ),
          )
        )}
      </div>
    </>
  );
};

export default DocumentList;
